<section class="funfacts-style-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-6">
                <div class="single-funfact-card mb-30">
                    <div class="icon">
                        <i class='bx bxs-group'></i>
                    </div>
                    <h3>
                        <span [countUp]="150">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Expert Instructors</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6">
                <div class="single-funfact-card mb-30">
                    <div class="icon bg-0B767A">
                        <i class='bx bx-certification'></i>
                    </div>
                    <h3>
                        <span [countUp]="7">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Institutions</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6">
                <div class="single-funfact-card mb-30">
                    <div class="icon bg-9879B6">
                        <i class='bx bxs-group'></i>
                    </div>
                    <h3>
                        <span [countUp]="8190">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Happy Students</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6">
                <div class="single-funfact-card mb-30">
                    <div class="icon bg-FF1949">
                        <i class='bx bx-book-reader'></i>
                    </div>
                    <h3 [countUp]="1654">00</h3>
                    <p>Creative Events</p>
                </div>
            </div>
        </div>
    </div>
</section>