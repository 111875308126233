<app-header-style-one></app-header-style-one>
<div class="container">
<section class="register-area">
    <div class="row m-0">



        <div class="col-lg-6 col-md-12 p-0">
            <div class="register-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="register-form">
                            <div class="logo">
                               
                            </div>
                        <h3>RTI ACT </h3>
                            <p>Right to Information Act 2005 mandates timely response to citizen requests for government information. The basic objective of the Right to Information Act is to empower the citizens, promote transparency and accountability in the working of the organizations defined as public authorities. All Universities and Colleges established by law made by Parliament or by State Legislature or by notification by the appropriate Government or owned, controlled or substantially financed directly or indirectly by funds provided by the Government shall come within the meaning of a Public Authority under this Act.</p>
                            <br><br>
                            <p>In pursuance of the Right to Information Act 2005, the Principal has been designated as Public Information Officer (PIO) and will perform the duties and responsibilities as envisaged under the act without fail.</p>
                           <br><br><br><br>
                            <h5 >Public Information Officer (PIO): </h5>
                            <p>Name : Dr. P. Nelson Raja, Principal.</p>
                            <p>Email : principal&#64;fmcet.ac.in</p>
                            <p>Phone No: +91 9942982321</p>
                            
                            
                            
                            
                            <!-- <form>
                                <div class="form-group">
                                    <input type="email" name="email" id="email" placeholder="Your email address" class="form-control">
                                </div>
                                <div class="form-group">
                                    <input type="password" name="password" id="password" placeholder="Create a password" class="form-control">
                                </div>
                                <button type="submit">Sign Up</button>
                                <div class="connect-with-social">
                                    <span>Or</span>
                                    <button type="submit" class="facebook"><i class='bx bxl-facebook'></i> Connect with Facebook</button>
                                    <button type="submit" class="twitter"><i class='bx bxl-twitter'></i> Connect with Twitter</button>
                                </div>
                            </form>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>







        <div class="col-lg-6 col-md-12 p-0">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="register-form">
                        <div class="logo">
                            <img src="assets/img/pdf/RTI.jpg" alt="image">
                        </div>
                  
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</section>
</div>