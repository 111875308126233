<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
             
            </ul>
            <h2>IQAC- Minutes of Meeting</h2>
        </div>
    </div>
</div>

<div
    class="product-details-area pt-100 pb-70"
    [class.dark-product-details-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row ">
        
          
            <div class="col-lg-12 ">
                <div class="products-details-tab ">
                    <ul class="nav-tabset ">
                        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                            <span class="active" (click)="switchTab($event, 'tab1')">2022-2023</span>
                        </li>
                        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                            <span (click)="switchTab($event, 'tab2')">2021-2022</span>
                        </li>
                        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                            <span (click)="switchTab($event, 'tab3')">2020-2021</span>
                        </li>
                        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                            <span (click)="switchTab($event, 'tab4')">2019-2020</span>
                        </li>
                        <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                            <span (click)="switchTab($event, 'tab5')">2018-2019</span>
                        </li>
                        
                    </ul>
                    <div class="tabs-container">
                        <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                            <div class="products-details-tab-content">
                                <a href="http://fmcet.ac.in/assets/img/pdf/2022_2023.pdf" target="_blank">Click here</a><br>
                            </div>
                        </div>
                        <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                            <div class="products-details-tab-content">
                                <a href="http://fmcet.ac.in/assets/img/pdf/2021_2022.pdf" target="_blank">Click here</a><br>
                            </div>
                        </div>
                        <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                            <div class="products-details-tab-content">
                                <a href="http://fmcet.ac.in/assets/img/pdf/2020_2021.pdf" target="_blank">Click here</a><br>
                            </div>
                        </div>
                        <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                            <div class="products-details-tab-content">
                                <a href="http://fmcet.ac.in/assets/img/pdf/2019_2020.pdf" target="_blank">Click here</a><br>
                            </div>
                        </div>
                        <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                            <div class="products-details-tab-content">
                                <a href="http://fmcet.ac.in/assets/img/pdf/2018_2019.pdf" target="_blank">Click here</a><br>
                            </div>
                        </div>

                    
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
 
</div>