<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">Madurai Sivagangai Main Road,
                                <br>Madurai - 625 020.
                                <br>Tamil Nadu.
                                <br>India</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="">+91 99429 82311</a><br>
                            <a href="">+91 99429 82321</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            
                            <a href="">principal&#64;fmcet.ac.in</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/FatimaMichaelEngineeringCollegeMadurai" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                       
                        <li><a href="https://www.instagram.com/fmcet_mdu/?igshid=MzNlNGNkZWQ4Mg%3D%3D" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                       
                       
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Contact Us</a></li>
                        <li><a routerLink="/"></a></li>
                        <li><a routerLink="/"></a></li>
                        <li><a routerLink="/"></a></li>
                        <li><a routerLink="/"></a></li>
                        <li><a routerLink="/"></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                    <ul class="useful-link">
                        <li><a href="https://www.aicte-india.org/" target="_blank">AICTE, New Delhi</a></li>
                        <li><a href="https://annauniv.edu/" target="_blank">Anna University, Chennai</a></li>
                        <li><a href="https://dte.tn.gov.in/" target="_blank">DOTE, Tamilnadu</a></li>
                        <li><a href="https://upsc.gov.in/" target="_blank">UPSC</a></li>
                     </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>e-Newsletter</h3>
                    <div class="newsletter-box">
                       <a href="https://fmcet.in/eletter.php" target="_blank"> <p>To get the latest e-news</p></a>
                   
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/black-logo3.png" alt="image"></a>
            </div>
            <p>© Fatima Michael College of Engineering & Technolgoy, Madurai <a href="https://fmcet.ac.in" target="_blank">| All rights reserverd</a></p>
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>