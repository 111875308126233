<section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
           
            <h2><span>Placement Record as on 18-04-2023</span></h2>
            
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2018-2019</h3>
                   <p>59.87% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2019-2020</h3>
                    <p>72.86% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2020-2021</h3>
                    <p>84.07% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2021-2022</h3>
                    <p>72.53% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>2022-2023</h3>
                    <p>76.47% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="services-box">
                    <div class="icon">
                        <i class="flaticon-analytics"></i>
                    </div>
                    <h3>Last Five Years Record</h3>
                    <p>72.837% of Students Placed</p>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="services-btn">
                    <a routerLink="/placement" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Placement</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
    <!--<div class="divider bg-e4feff"></div>-->
</section>