<app-header-style-one></app-header-style-one>
<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 

    <mat-tab label="Home"><app-our-mission></app-our-mission></mat-tab>
    <mat-tab label="Recruiters"></mat-tab>
    </mat-tab-group>
    


<app-features-stye-three></app-features-stye-three>
<div class="testimonials-wrap-area bg-E7F0FD pt-100">
    <app-happy-students-feedback></app-happy-students-feedback>
</div>
