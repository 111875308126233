<app-header-style-one></app-header-style-one>


<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg11 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Mechanical Engineering</h2>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="About the Department">
        <div class="page-title-area item-bg11 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Mechanical Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
               <div class="row">
                    <div class="col-lg-6">
                        <div class="courses-sidebar-syllabus">
                        <h3>About the Department</h3>                   
                            <p>Department of Mechanical Engineering, at Fatima Michael College of Engineering and Technology, was established along with the institute in 2009 with the intake of 60 students. Post graduate Programme in Thermal Engineering was started with the intake of 18 students during the academic year 2011-2012. The department is affiliated to Anna University, Chennai and approved by AICTE, New Delhi. This department is one of the approved Research Centre from Anna University – Chennai. The department consistently maintains good academic records and produces quality Mechanical Engineers.     
                                
                                </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <br><br><br>
                        <div class="courses-details-image text-center">
                            <img src="assets/img/courses/courses27.jpg" alt="image">
                        </div>  
                    </div>
                </div>
        
             </div>
        </section>  
    </mat-tab>
    <mat-tab label="Vision & Mission">
        <div class="page-title-area item-bg11 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Mechanical Engineering</h2>
                </div>
            </div>
        </div>
        <section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
            <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>To provide world class engineering education and scientific research to build up mechanical engineers for globalized social development.</p> 
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
                        To impart quality technical education through eminent faculty to create efficient Mechanical Engineers.
	<br>To promote effective collaboration with esteemed institutes / industries for technical knowledge transfer and  research activities.
    <br>To develop skills with recent technological movements and promote co and extra - curricular activities to enhance employability and entrepreneurship.
	<br>To develop responsible citizenship through awareness and acceptance of ethical values. 


                        </p>
                    
                </div>
            </div>
            
      
        </div>
    </div>
    
</section>
    </mat-tab>
    <mat-tab label="PEOs">
        <div class="page-title-area item-bg11 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Mechanical Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span><p>Graduates can</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>Effectuating success in careers by exploring with the design, digital and
                            computational analysis of engineering systems, experimentation and teCsting, smart
                            manufacturing, technical services, and research.</li><br>
                        <li>Amalgamating effectively with stakeholders to update and improve their core
                            competencies and abilities to ethically compete in the ever-changing multicultural
                            global enterprise.</li><br>
                        <li>To encourage multi-disciplinary research and development to foster advanced
                            technology,and to nurture innovation and entrepreneurship in order to compete
                            successfully in the global economy.</li><br>
                        <li>To globally share and apply technical knowledge to create new opportunities that
                            proactively advances our society through team efforts and to solve various
                            challenging technical, environmental and societal problems</li><br>
                        <li>To create world class mechanical engineers capable of practice engineering ethically
                            with a solid vision to become great leaders in academia, industries and society.</li><br>
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="POs & PSOs">
        <div class="page-title-area item-bg11 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Mechanical Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM OUTCOMES (POs)</span><p>Graduates will be able to </p>
                    <ul style="text-align: justify;">
                        <br>
                        <li><span>Engineering knowledge:</span> Apply the knowledge of mathematics, science, engineering
                            fundamentals, and an engineering specialization to the solution of complex engineering
                            problems.</li><br>
                        <li><span>Problem analysis:</span> Identify, formulate, review research literature, and analyze complex
                            Engineering problems reaching substantiated conclusions using first principles of
                            mathematics, natural sciences, and engineering sciences.</li><br>
                        <li><span>Design/development of solutions:</span> Design solutions for complex engineering problems
                            and design system components or processes that meet the specified needs with appropriate
                            consideration for the public health and safety, and the cultural, societal, and
                            Environmental considerations.</li><br>
                        <li><span>Conduct investigations of complex problems:</span> Use research-based knowledge
                            and research methods including design of experiments, analysis and interpretation of
                            data, and synthesis of the information to provide valid conclusions.</li><br>
                        <li><span>Modern tool usage:</span> Create, select, and apply appropriate techniques, resources, and
                            modern engineering and IT tools including prediction and modeling to complex
                            Engineering activities with an understanding of the limitations.</li><br>
                        <li><span>The engineer and society:</span> Apply reasoning in formed by the contextual knowledge
                            To assess societal, health, safety, legal and cultural issues and the consequent
                            
                            responsibilities relevant to the professional engineering practice.</li><br>
                        <li><span>Environment and sustainability:</span> Understand the impact of the professional engineering
                            solutions in societal and environmental contexts, and demonstrate the knowledge of, and
                            need for sustainable development.</li><br>
                        <li><span>Ethics:</span> Apply ethical principles and commit to professional ethics and responsibilities and
                            norms of the engineering practice.</li><br>
                        <li><span>Individual and teamwork:</span> Function effectively as an individual, and as a member
                            Or leader in diverse teams, and in multidisciplinary settings.</li><br>
                        <li><span>Communication:</span> Communicate effectively on complex engineering activities with the
                            engineering community and with society at large, such as, being able to comprehend and
                            write effective reports and design documentation, make effective presentations, and give
                            And receive clear instructions.</li><br>
                        <li><span>Project management and finance:</span> Demonstrate knowledge and understanding of the
                            engineering and management principles and apply these to one’s own work, as a member
                            And leader in a team, to manage projects and in multi disciplinary environments.</li><br>
                        <li><span>Life-long learning:</span> Recognize the need for, and have the preparation and ability to
                            engage in independent and life-long learning in the broadest context of technological
                            change.</li><br>
                       
                    </ul>
                   <span> PROGRAM SPECIFIC OUTCOMES (PSOs)</span><p>On successful completion of the Mechanical Engineering Degree programme, the
                        Graduates shall exhibit the following:</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>Apply the knowledge gained in Mechanical Engineering for design and development
                            and manufacture of engineering systems.</li><br>
                        <li>Apply the knowledge acquired to investigate research-oriented problems in
                            mechanical engineering with due consideration for environmental and social impacts.</li><br>
                        <li>Use the engineering analysis and data management tools for effective management
                            of multidisciplinary projects.</li><br>
                       
                    </ul>    
                </div>
            </div>
        </section>
    </mat-tab>

    <mat-tab label="Programme Offered">
        <div class="page-title-area item-bg11 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Mechanical Engineering</h2>
                </div>
            </div>
        </div>
        <div class="container">
            <br><br><br>
            <div class="user-actions">
                <i class='bx bx-log-in'></i>
                <span>Programme Offered </span>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">S.NO</th>
                            <th scope="col">Programme Name</th>
                            <th scope="col">Years</th>
                         
                           
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            <td class="product-name">
                                <a >1.</a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/mech">B.E. Mechanical Engineering</a>
                            </td>
                            <td class="product-total">
                                <span class="subtotal-amount">4 Years</span>
                            </td>
                            
                          </tr>
                          <tr>
                            <td class="product-name">
                                <a >2.</a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/thermal">M.E. Thermal Engineering</a>
                            </td>
                            <td class="product-total">
                                <span >2 Years</span>
                            </td>
                            
                          </tr>
                          <tr>
                            <td class="product-name">
                                <a >3.</a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/phd">Ph.D</a>
                            </td>
                            <td class="product-total">
                                <span >Full/Partime Programme</span>
                            </td>
                            
                          </tr>
        
                          </tbody>
                          </table>
                    
                </div>
            </div>
    </mat-tab>
    </mat-tab-group>









<section class="courses-details-area pt-100 pb-70">
    <div class="container">
       <div class="row">
        <div class="col-lg-6">
            <div class="courses-details-image text-center">
                <img src="assets/img/courses/courses41.jpg" alt="image">
            </div>  
        </div>
            <div class="col-lg-6">
                <div class="courses-sidebar-syllabus">
                
                    <h3>Association Activities</h3>
              
                    <p>
                        1. Technical workshops, seminars and guest lectures: Organizing workshops, seminars and guest lectures on various topics related to mechanical engineering to enhance the knowledge and skills of the students.
                        
                        <br>2. Industrial visits: Arranging visits to manufacturing plants, research facilities and other industrial sites to provide students with practical exposure to real-world engineering practices.
                        
                        <br> 3. Competitions and projects: Organizing technical competitions, design challenges, and project exhibitions to encourage creativity, innovation and teamwork among students.
                        
                        <br>4. Skill development programs: Conducting training programs, hands-on sessions to enhance the technical skills of students in areas such as CAD/CAM and 3D printing technologies.
                        
                       
                    </p>
                </div>
            </div>
         
        </div>

     </div>
</section>
<section
    class="why-choose-us-area bg-e6fbff"
    [class.dark-why-choose-us-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/test4.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose </span>
                        <h2> Mechanical Engineering</h2>
                        <p> 1.	Mechanical engineering involves the design, development, and construction of various mechanical systems, which can be appealing to students with a passion for creating and innovating. 
                            2.	 Mechanical engineering offers a wide range of career opportunities in industries such as automotive, aerospace, manufacturing, energy, robotics and drone technology providing students with diverse options for their future careers.
                            </p>
                        
                        <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/img/lang-shape2.png" alt="image"></div>
</section>


<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/courses/courses29.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div >
                    
                   
                        <h3>Laboratory Facilities</h3>
                        <p>Department of Mechanical Engineering provides special attention on recent engineering innovations and advancements. The following laboratory facilities are available to fulfil the university curriculum and to pursue research work:

                            
                            
                            
                           
                       </p>
                        <div class="courses-details-desc">
                            <ul class="description-features-list">
                                <li>Fully equipped Workshop                    </li>
                                <li>Fluid Mechanics and Machinery Laboratory                     </li>
                                <li>Strength of Materials Lab                 
                                </li>
                                <li>IC. Engine Lab</li>
                                <li>Heat Transfer Lab</li>
                                <li>Kinematics & Dynamics Lab</li>
                                <li>Metrology & Measurements Lab</li>
                                <li>Mechatronics Lab</li>
                                <li>CAD / CAM Lab</li>
                                
                            </ul>
                        </div>
                  
                </div>
            </div>
        </div>
    </div>
</section>



