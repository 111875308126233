<section class="funfacts-style-three bg-dark bg-100f1f">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-funfacts-item">
                    <h3><span [countUp]="2">00</span></h3>
                    <p>Engineering Colleges</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-funfacts-item">
                    <h3><span [countUp]="3">00</span></h3>
                    <p>Matriculation Schools</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-funfacts-item">
                    <h3><span [countUp]="1">00</span></h3>
                    <p>Polytechnic College</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-funfacts-item">
                    <h3><span [countUp]="1">00</span></h3>
                    <p>B.Ed College</p>
                </div>
            </div>
        </div>
    </div>
</section>