<app-header-style-one></app-header-style-one>


<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg16 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>M.E Computer Science & Engineering</h2>
            </div>
        </div>
    </div>
</mat-tab>
    <mat-tab label="About the Department">
        <div class="page-title-area item-bg16 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>M.E Computer Science & Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
               <div class="row">
                    <div class="col-lg-6">
                        <div class="courses-sidebar-syllabus">
                        <h3>About the Department</h3>                   
                        <p>Our Department has an excellent infrastructure for the students. It is the endeavor of the management to periodically migrate to the latest software and hardware to keep pace with ever changing needs of the industry. Hence, our students come out with the knowledge of the latest technology in software and hardware. The Department is elevated with trained faculty members, who are inspiring the students in the field of emerging trends. The department is maintaining various software concerns for the placement of our students. Most of our students are well placed in leading Software Companies. The Department faculty members are specialized in Cryptography and Network Security, Mobile Computing, Big Data Analytics, Cloud Computing, Computer Architecture, Data Mining and Warehousing, Software Engineering, Software Quality Management, Machine Learning, Artificial Intelligence etc.
                        </p>
                        <p>The Master of Engineering program in Computer Science and Engineering was started with intake of 18 in the academic year 2012-2013. The program has been well recognized among students for well-designed teaching-learning process, experienced and committed faculty, excellent infrastructure, and the success of the graduates. The program is supported by dedicated labs for curriculum-oriented courses and research labs with cutting-edge hardware for Machine Learning and Distributed Systems. The Department has a thriving Research Group, giving exciting opportunities to students for training, research and project work in emerging areas.</p>
                        
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <br><br><br>
                        <div class="courses-details-image text-center">
                            <img src="assets/img/courses/courses9.jpg" alt="image">
                        </div>  
                    </div>
                </div>
        
             </div>
        </section>  
    </mat-tab>
    <mat-tab label="Vision & Mission">
        <div class="page-title-area item-bg16 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>M.E Computer Science & Engineering</h2>
                </div>
            </div>
        </div>
        <section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
            <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>To achieve academic excellence in Computer Science & Engineering. Providing in depth knowledge to the students, inculcating research activities to meet out industrial demands and society needs. Producing quality graduates to become leaders and innovators in industry worldwide.</p> 
                   
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
                        To provide quality education to the under graduate and post graduate students and make them expert in cutting edge technology in Computer science engineering.<br>
      
                        To share and apply knowledge in interdisciplinary areas that extends the scope of computer science.<br>
                       To promote research based innovative projects and activities in the emerging technology areas.<br>
                     
                          </p>
                </div>
            </div>
            
      
        </div>
    </div>
    
</section>
    </mat-tab>
    <mat-tab label="PEOs">
        <div class="page-title-area item-bg16 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>M.E Computer Science & Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span><p>Graduates can</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>Develop proficiency as a computer science engineer with an ability to solve a
                            wide range of computational problems and have sustainable development in industry
                            or any other work environment.</li><br>
                        <li>Analyze and adapt quickly to new environments and technologies, gather
                            new information, and work on emerging technologies to solve multidisciplinary
                            engineering problems.</li><br>
                        <li>Possess the ability to think analytically and logically to understand technical
                            problems with computational systems for a lifelong learning which leads to pursuing
                            research.</li><br>
                        <li>Adopt ethical practices to collaborate with team members and team leaders to
                            build technology with cutting-edge technical solutions for computing systems</li><br>
                        <li>Strongly focus on design thinking and critical analysis to create innovative products
                            and become entrepreneurs.</li><br>
                    </ul>
                </div>
            </div>
        </section>  
    </mat-tab>
    <mat-tab label="POs">
        <div class="page-title-area item-bg16 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>M.E Computer Science & Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM OUTCOMES (POs)</span><p></p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>An ability to independently carry out research / investigation and
                            development work to solve practical problems.</li><br>
                        <li>An ability to write and present a substantial technical report/document.</li><br>
                        <li>Students should be able to demonstrate a degree of mastery over the area
                            of Computer Science and Engineering.</li><br>
                        <li>Efficiently design, build and develop system application software for
                            distributed and centralized computing environments in varying domains
                            and platforms.</li><br>
                        <li>Understand the working of current Industry trends, the new hardware
                            architectures, the software components and design solutions for real
                            world problems by Communicating and effectively working with
                            professionals in various engineering fields and pursue research
                            orientation for a lifelong professional development in computer and
                            automation arenas.</li><br>
                        <li>Model a computer based automation system and design algorithms that
                            explore the understanding of the tradeoffs involved in digital
                            transformation.</li><br>
                     <br>
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    </mat-tab-group>









<section
    class="why-choose-us-area bg-e6fbff"
    [class.dark-why-choose-us-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/test8.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose </span>
                        <h2> M.E Computer Science & Engineering</h2>
                        <p>Embark on an exciting journey in computer science & engineering at Fatima Michael College of Engineering and Technology. Our renowned program combines theoretical knowledge with hands-on experience, preparing students to tackle real-world challenges. With state-of-the-art facilities, expert faculty, and industry-aligned curriculum, students gain a comprehensive understanding of cutting-edge technologies such as artificial intelligence, cybersecurity, and data science. Engage in innovative research projects, internships with leading companies, and personalized mentorship to unlock your full potential. Join our vibrant community and become part of a legacy of excellence in computer science & engineering at Fatima Michael College of Engineering and Technology.
                            </p>
                        
                        <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/img/lang-shape2.png" alt="image"></div>
</section>


<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/courses/courses36.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div >
                    
                   
                        <h3>Laboratory Facilities</h3>
                      
                        <div class="courses-details-desc">
                            <ul class="description-features-list">
                                <li>Advanced Data Structures Lab                   </li>
                                <li>Data Analytics and Research Lab
                                </li>
                                
                                
                            </ul>
                        </div>
                  
                </div>
            </div>
        </div>
    </div>
</section>





