<app-header-style-one></app-header-style-one>

<div class="page-title-area page-title-style-two item-bg18">
    <div class="container">
        <div class="page-title-content">
                     <h2>Science & Humanities</h2>
        </div>
    </div>
</div>

<div
    class="about-area ptb-100"
    [class.dark-about-area]="themeService.isDark()"
>
<br><br>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content right-content">
                    <span class="sub-title">About Depatment</span>
                    
                    
                    <p>The Department of Science and Humanities is set up to cater the needs of budding Engineers.One of the most important aspects of Engineering is effective communication, both verbal and written. Humanities study can strengthen the ability of Engineers to work and communicate with others. The department targets at prominence in some of the latest trends and to establish consciousness and exposure in multi-disciplinary Engineering domain and to induct them to succeed with their technical skills and aptitudes.</p>
              
                </div>
            </div>
       
        </div>
    </div>
    <br><br>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content right-content">
                    <span class="sub-title">Vision</span>
                    
                    
                    <p>Our vision is to make the students realize the importance of Sciences and Humanities and also to create  an awareness for Engineering and Technology which in turn will enrich and mould the student into a proficient expert.</p>
              
                </div>
            </div>
       
        </div>
    </div>
    <br><br>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content right-content">
                    <span class="sub-title">Mission</span>
                    
                    
                    <p> <br>To inculcate extraordinary analytical, logical, soft skill and ethical values in students to make them ready for corporate world.
                        <br>To create academic excellence in fundamental sciences through advanced teaching learning process.
                        <br>To develop and sharpen students’ ability to converse sensibly and innovatively in several ways that is appropriate for their discipline.
                        <br>To encourage the practical applications of concepts through learning experience and active participation.
                        <br>To integrate human values and social concerns with technical education.
                        </p>
              
                </div>
            </div>
       
        </div>
    </div>

</div>
<div class="row align-items-center">
            
    <section class="gallery-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-gallery-item mb-30">
                        <img src="assets/img/gallery/sh/1.png" alt="Gallery Image" data-original="assets/img/gallery/xenos22/1.png">
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-gallery-item mb-30">
                        <img src="assets/img/gallery/sh/2.png" alt="Gallery Image" data-original="assets/img/gallery/xenos22/2.png">
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-gallery-item mb-30">
                        <img src="assets/img/gallery/sh/3.png" alt="Gallery Image" data-original="assets/img/gallery/xenos22/3.png">
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-gallery-item mb-30">
                        <img src="assets/img/gallery/sh/4.png" alt="Gallery Image" data-original="assets/img/gallery/xenos22/4.png">
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-gallery-item mb-30">
                        <img src="assets/img/gallery/sh/5.png" alt="Gallery Image" data-original="assets/img/gallery/xenos22/5.png">
                    </div>
                </div>
    
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-gallery-item mb-30">
                        <img src="assets/img/gallery/sh/6.png" alt="Gallery Image" data-original="assets/img/gallery/xenos22/6.png">
                    </div>
                </div>
    
    
             
    
            
            </div>
        </div>
    </section>




   
</div>
<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-lg-12 col-md-12">
            <div class="about-content right-content">
                <span class="sub-title">DEPARTMENT OF MATHEMATICS	</span>
                
                
                <p>Mathematics gave birth to the core troubleshooters in scientific and engineering courses. With dynamics of design and theory converted into precise numbers, Mathematics provides practical solutions to theoretical promises. Special emphasis on recruitment of faculty who provide fundamentals of Mathematics and thus we can say we've a top class line-up of educators who have expertise in various Mathematical fields like topology, generalized MATRIX theory, Fluid Dynamics and graph theory. Our faculty members have published research papers in a peer-reviewed journals. </p>
          
            </div>
        </div>
   
    </div>
</div>


<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-lg-12 col-md-12">
            <div class="about-content right-content">
                <span class="sub-title">DEPARTMENT OF PHYSICS	</span>
                
                
                <p>Physics is considered the building block for all disciplines of engineering. It gives Mathematical equations and theoretic, a physical shape and form. Physics is a dynamic subject whose algorithmic formulations have provided answers to contemporary problems that involve addressing glitches relating to physical sciences. Physics cannot be contained into a box as a specific subject developed for a special Engineering branch; instead it involves answering questions to all kinds of physical problems. Engineering physics provides a deep understanding to the concepts of Nanotechnology, Quantum Physics, SemiconductorPhysics, Optics, MaterialScience andElectromagnetics, Thermal Physics, Crystallography, Fiber Optics and Electronics.  </p>
          
            </div>
        </div>
   
    </div>
</div>

<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-lg-12 col-md-12">
            <div class="about-content right-content">
                <span class="sub-title">DEPARTMENT OF CHEMISTRY	</span>
                
                
                <p>The Department of Chemistry provides excellent infrastructure and sophisticated equipment to provide best possible fundamental education with training to students that develop kick starter skills for starting their career. Engineering Chemistry provides a deep understanding in the realm of Engineering Chemistry and Environmental Engineering.  </p>
          
            </div>
        </div>
   
    </div>
</div>

<div class="container-fluid">
    <div class="row align-items-center">
        <div class="col-lg-12 col-md-12">
            <div class="about-content right-content">
                <span class="sub-title">DEPARTMENT OF ENGLISH	</span>
                
                
                <p>English has very often been termed as a window of rapid progress for technological and scientific knowledge. It has been portrayed as pipe-line for the stream of acquaintance in all branches of learning. In this scenario, the contributions of the department of English in imparting communication skills (LSRW) and functional grammar in English are significant.  </p>
          
            </div>
        </div>
   
    </div>
</div>

<div class="container">
    <div class="about-inner-area">
        <div class="row">
            <div class="col-lg-12 col-md-6 col-sm-6">
                <div class="about-text">
                    <h3>INDUCTION PROGRAMME</h3>
                    <p>The Purpose of Student Induction Programme designs to make the newly joined students feel comfortable, sensitize them towards exploring their academic interests, activities and inspire them work for excellence, build relations between teachers and students.</p>
                    <ul class="features-list">
                        <li><i class='bx bx-check'></i> Physical Activities (Yoga and Meditation, Health and fitness,  Korean Karate etc)</li>
                        <li><i class='bx bx-check'></i> Industrial Visit (to reputed Industries in various places)</li>
                        <li><i class='bx bx-check'></i> Guest Lecture by Eminent Personalities </li>
                        <li><i class='bx bx-check'></i> Career Guidance by Professionals from outstanding companies</li>
                    </ul>
                </div>
            </div>
   
        </div>
    </div>

</div>
<br><br>



