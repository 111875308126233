<section
    class="funfacts-and-feedback-area bg-f8e8e9 ptb-100"
    [class.dark-funfacts-and-feedback-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-slides-content">
                <h3><span class="sub-title">Strength of FMCET</span></h3>
                   
                    <p>Our college has a good academic ambience, spread in a
                        sprawling campus of 50 acres, lush green environment having lots of
                        trees and lawns conducive for learning atmosphere.</p>
                    <div class="feedback-slides-two">
                        <owl-carousel-o [options]="feedbackSlidesTwo">
                            <ng-template carouselSlide>
                                <div class="single-feedback-slides-item">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur rr adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>John Smith</h3>
                                            <span>Python Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-slides-item">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur rr adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>Sarah Taylor</h3>
                                            <span>PHP Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="single-feedback-slides-item">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur rr adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                    <div class="client-info d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                        <div class="title">
                                            <h3>David Warner</h3>
                                            <span>QA Developer</span>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                   
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="1926">00</span></h3>
                                <p>Creative Events</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="3987">00</span></h3>
                                <p>Exemplery Alumna</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="4000">00</span></h3>
                                <p>Happy Students</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span [countUp]="12">00</span></h3>
                                <p>Courses</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>