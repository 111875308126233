<app-header-style-one></app-header-style-one>
<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="container courses-categories-area pt-100 pb-70">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>INTERNAL QUALITY ASSURANCE CELL (IQAC) </span>
      <br>
            <P>The Internal Quality Assurance Cell (IQAC) of FMCET has several functions. Its primary goal is to ensure continuous improvement in the quality of education, research and other aspects of institutions. Some of the key functions of IQAC include:</P>
         <strong>Vision</strong>
            <p>The vision of IQAC of FMCET is to make the institution a center of excellence in quality assurance, fostering a culture of continuous improvement, innovation and academic excellence within the college. We envision our institution to deliver world-class education, empower students with skills and contribute positively to society through research, industry collaboration and community engagement</p>
          <strong>Mission</strong>
            <p>The mission of IQAC of FMCET outlines the specific objectives and goals that the cell aims to achieve in its pursuit of enhancing the quality of education and institutional processes</p>
            <p>Our mission is to promote and sustain a culture of quality in all aspects of college’s functioning. We are committed to facilitating continuous improvement by implementing effective quality assurance mechanisms, fostering innovation in teaching and research, nurturing an inclusive learning environment and nurturing partnerships with industry and the community. Through transparent processes and data-driven decision-making, we strive to ensure that our institution provides the highest quality of education, produces competent engineers and contributes meaningfully to the societal development</p>
            <p>The college IQAC was established on June 3, 2018, after a comprehensive year-long evaluation.</p>
           <strong>Composition of IQAC</strong>
            <p>Following the revised NAAC framework of 2017, the structure of IQAC members was formulated. The composition of the IQAC entails the leadership of the Head of the institution, along with participation from heads of significant academic and administrative units, a number of educators, distinguished education experts, representatives from the local management, and stakeholders</p>
            <ul>
                <li>
                 
                    <Span>Developing and Implementing quality policies</Span> 
                    <p>IQAC helps in formulating and implementing policies related to quality improvements across various areas of the institution</p>
                </li>
                <li>
               
                    <Span>Planning and Evaluation</Span> 
                    <p>IQAC plans and conducts assessments to evaluate the quality of academic and administrative activities. This involves setting benchmarks, collecting data and analysing the outcome</p>
                </li>
                <li>
               
                    <Span>Academic Program Review</Span> 
                    <p>IQAC reviews and assesses the effectiveness of academic programs, curriculum and teaching-learning processes. It suggests improvements and updates as needed</p>
                </li>
                <li>
               
                    <Span>Monitoring Internal Processes</Span> 
                    <p>IQAC monitors internal processes to ensure that they align with the quality standards and regulations. This includes areas like admissions, examinations and administrative procedures</p>
                </li>
                <li>
               
                    <Span>Feedback and Continuous Improvement</Span> 
                    <p>IQAC collects feedback from stakeholders such as students, faculty and employers. It uses this feedback to identify areas that need improvement and implement necessary changes</p>
                </li>
                <li>
               
                    <Span>Faculty Development</Span> 
                    <p>IQAC promotes the professional development of faculty members, encouraging them to enhance their teaching skills and engage in research activities</p>
                </li>
                <li>
               
                    <Span>Research and Innovation</Span> 
                    <p>IQAC encourages research and innovation among faculty and students, fostering a culture of academic excellence</p>
                </li>
                <li>
               
                    <Span>Institutional Accreditation</Span> 
                    <p>IQAC plays a key role in preparing the institution for accreditation processes, ensuring that all requirements are met</p>
                </li>
                <li>
               
                    <Span>Data Management</Span> 
                    <p>IQAC maintains a repository of data related to various aspects of the institution’s functioning, which helps in decision-making and reporting</p>
                </li>
                <li>
               
                    <Span>Benchmarking</Span> 
                    <p>IQAC compares the institution’s performance with that of other similar institutions to identify best practices and areas for improvement</p>
                </li>
                <li>
               
                    <Span>Collaboration</Span> 
                    <p>IQAC facilitates collaboration with external agencies, industry partners and other institutions to promote quality enhancements.</p>
                    <p>Overall, IQAC acts as a driving force for quality improvement, fostering a culture of excellence and accountability within the institution. Its activities contribute to the overall growth and development of the institution</p>
                    <SPAN>Dr..S.N.NISHA RANI</SPAN>
                    <p>Coordinator - IQAC</p>
                </li>


            </ul>


        </div>
        </div>
    </mat-tab>
    <mat-tab label="IQAC Members">
        <section class="checkout-area ptb-100">
            <div class="container">
                <div class="user-actions">
                    <i class='bx bx-log-in'></i>
                    <span>Internal Quality Assurance Cell (IQAC) Members</span>
                    
                </div>
        
                <form>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="billing-details">
                                <h3 class="title">IQAC  MEMBERS </h3>
        
                            </div>
                        </div>
        
                        <div class="col-lg-12 col-md-12">
                            <div class="order-details">
                              <div class="order-table table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.NO</th>
                                                <th scope="col">Name of the IQAC Member</th>
                                                <th scope="col">Designation</th>
                                                <th scope="col">Position</th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            <tr>
                                                <td class="product-name">
                                                    <a >1.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Dr.P.NELSON RAJA</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Professor & Principal</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Head of the Institution / Chairperson</span>
                                                </td>
                                                <tr>
                                                    <td class="product-name">
                                                        <a >2.</a>
                                                    </td>
                                                    <td class="product-name">
                                                        <a routerLink="">Dr..S.N.NISHA RANI</a>
                                                    </td>
                                                    <td class="product-total">
                                                        <span class="subtotal-amount"> Professor / ECE
                                                        </span>
                                                    </td>
                                                    <td class="product-total">
                                                        <span class="subtotal-amount">IQAC - Coordinator</span>
                                                    </td>
                                                    
                                                </tr>
                                              
                                         
                                            
                                            <tr>
                                                <td class="product-name">
                                                    <a >3.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Dr.V. ANBUMALAR</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount"> Professor & Head / Mechanical, Vellammal college of Engg. & Tech., Madurai</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">External Academician</span>
                                                </td>
                                                
                                            </tr>
                                            <tr>
                                                <td class="product-name">
                                                    <a >4.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Mr.C.RAJA DURAI</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Associate Professor / EST</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Management </span>
                                                </td>
                                                
                                            </tr>
                                            <tr>
                                                <td class="product-name">
                                                    <a >5.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Dr.S.SARAVANAKUMAR</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Professor / Automobile</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Member</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >6.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Dr.M.VIJAYALAKSHMI</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Professor / Civil</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Member</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >7.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Dr.P.RAVIKUMARAN</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount"> Professor / CSE</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Member</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >8.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Dr.J.RAJALAKSHMI</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount"> Professor / EEE</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Member</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >9.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Dr.K.VINAYAGAR</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Professor / Mech</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Member</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >10.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Mr.A.ATHIMOOLAM</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Associate Professor / S&H</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Member</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >11.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Dr.R.NATARAJAN</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Professor / EST</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Member</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >12.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Mrs.M.SHARMILA DEVI</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">AO</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Administrative Officer</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >13.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Mr.A.VIJAYA ARUMUGAM</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Social Welfare</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Local Society / Trust</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >14.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Mr.M.GOKUL</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Mechanical Student(2019-2023)</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Student</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >15.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Mr.S.ROOPAN KUMAR</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">HCL, Madurai</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Alumni</span>
                                                </td>
                                                
                                            </tr><tr>
                                                <td class="product-name">
                                                    <a >16.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Mr.M.MOHAMMED FAIYAZ</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Tech. Head, Apptiva solutions, Madurai</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Employer</span>
                                                </td>
                                                
                                            </tr>
                                            <tr>
                                                <td class="product-name">
                                                    <a >17.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Mr.S.SAKTHIVEL</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Properiator / The Silicon Harvest</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Industrialist</span>
                                                </td>
                                                
                                            </tr>
                                            
                                            <tr>
                                                <td class="product-name">
                                                    <a >18.</a>
                                                </td>
                                                <td class="product-name">
                                                    <a routerLink="">Mr.M.JEGATHEESH KUMAR</a>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Father of J.Sethuraja, ECE Batch(2020-2024)</span>
                                                </td>
                                                <td class="product-total">
                                                    <span class="subtotal-amount">Parent</span>
                                                </td>
                                                
                                            </tr>
                                            
                                          
                                        </tbody>
                                    </table>
                                </div>
                               
                          
                            </div>
                        </div>
                    </div>
                    
                </form>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="IQAC - Minutes of Meeting ">
        <div class="container">
            <br><br>
            <div class="row">
                       
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="about-image">
                            
                            <div class="col-lg-12 col-md-4 col-sm-6">
                                <div class="single-categories-courses-box mb-30" style="background-color: #3F51B5;">
                                    <div class="icon">
                                        <i class='bx bx-layer'></i>
                                    </div>
                                   
                                 
                                    <a routerLink="/mom"><i class='bx bx-log-in'></i>  <h3>Minutes of Meeting</h3></a>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    
                </div>


            </div>
            <br><br>
        </div>
    </mat-tab>

 
  

    </mat-tab-group>

