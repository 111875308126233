<app-header-style-one></app-header-style-one>

<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg8 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Civil Engineering</h2>
            </div>
        </div>
    </div>
</mat-tab>
    <mat-tab label="About the Department">
        <div class="page-title-area item-bg8 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Civil Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
               <div class="row">
                    <div class="col-lg-6">
                        <div class="courses-sidebar-syllabus">
                        <h3>About the Department</h3>                   
                            <p>The Department of Civil Engineering was started in the academic year 2009.The program is offered under the affiliation of Anna University in Chennai. We offer bachelor's programs and also offer value-added subjects from the curriculum to prepare industry-ready professionals .The Department has well equipped laboratory facilities and a team of highly qualified faculty experienced in diversified fields of Civil Engineering. Exclusive models are set-up in the laboratories to help students understand fundamentals and advance technology. Students are encouraged to actively participate in co curricular and extracurricular activities. Department Conducts various placement activities such as training on Aptitude Skills, Technical skills and Refresher courses on subjects relevant to meet the industrial demands in addition to the various training programmes organized by the Training & Placement Cell. The Class of 2014 which was our second batch of students had secured 38th University Ranks. Our civil engineering graduates have been placed in companies like Shoba Developers, L&T, URC Constructions and Government Sectors. Some of our graduates were placed with a reputed company in Gulf Countries with a package of 8.50 Lakhs/P.A.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <br><br><br>
                        <div class="courses-details-image text-center">
                            <img src="assets/img/courses/courses23.jpg" alt="image">
                        </div>  
                    </div>
                </div>
        
             </div>
        </section>  
    </mat-tab>
    <mat-tab label="Vision & Mission">
        <div class="page-title-area item-bg8 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Civil Engineering</h2>
                </div>
            </div>
        </div>
        <section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
            <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>To stimulate and cast Civil Engineering expectant as expert and dynamic
                        infrastructure developers.</p> 
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
To Harmonize high quality civil engineering education and research
Inspire student’s logical reasoning, creativity, and communication abilities.<br>
To Cultivate understanding of emerging trends through personal exploration
Partner with industries and government organizations.<br>
To inculcate innovation and eco sustainable construction in the minds of
budding civil engineers to face ever evolving challenges.

                        </p>
                    
                </div>
            </div>
            
      
        </div>
    </div>
    
</section>
    </mat-tab>
    <mat-tab label="PEOs">
        <div class="page-title-area item-bg8 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Civil Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span><p>Graduates can</p>
                    <ul>
                        <br>
                        <li>Gain knowledge and skills in Civil engineering which will enable them to have a career
                            and professional accomplishment in the public or private sector organizations.</li><br>
                        <li>Become consultants on complex real life Civil Engineering problems related to
                            Infrastructure development especially housing, construction, water supply, sewerage,
                            transport, spatial planning.</li><br>
                        <li>Become entrepreneurs and develop processes and technologies to meet desired
                            infrastructure needs of society and formulate solutions that are technically sound,
                            Economically feasible, and socially acceptable.</li><br>
                        <li>Perform investigation for solving Civil Engineering problems by conducting research
                            using modern equipment and software tools.</li><br>
                        <li>Function in multi-disciplinary teams and advocate policies, systems, processes and
                            equipment to support civil engineering.</li><br>
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="POs & PSOs">
        <div class="page-title-area item-bg8 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Civil Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM OUTCOMES (POs)</span><p>Graduates will be able to</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li><span>Engineering knowledge:</span> Apply the knowledge of mathematics, science, engineering
                            fundamentals, and an engineering specialization to the solution of complex engineering
                            problems.</li><br>
                        <li><span>Problem analysis:</span> Identify, formulate, review research literature, and analyze complex
                            Engineering problems reaching substantiated conclusions using first principles of
                            mathematics, natural sciences, and engineering sciences.</li><br>
                        <li><span>Design/development of solutions:</span> Design solutions for complex engineering problems
                            and design system components or processes that meet the specified needs with appropriate
                            consideration for the public health and safety, and the cultural, societal, and
                            Environmental considerations.</li><br>
                        <li><span>Conduct investigations of complex problems:</span> Use research-based knowledge
                            and research methods including design of experiments, analysis and interpretation of
                            data, and synthesis of the information to provide valid conclusions.</li><br>
                        <li>Modern tool usage: Create, select, and apply appropriate techniques, resources, and
                            modern engineering and IT tools including prediction and modeling to complex
                            Engineering activities with an understanding of the limitations.</li><br>
                        <li><span>The engineer and society:</span> Apply reasoning in formed by the contextual knowledge
                            To assess societal, health, safety, legal and cultural issues and the consequent
                            responsibilities relevant to the professional engineering practice.</li><br>
                        <li><span>Environment and sustainability:</span> Understand the impact of the professional engineering
                            solutions in societal and environmental contexts, and demonstrate the knowledge of, and
                            need for sustainable development.</li><br>
                        <li><span>Ethics:</span> Apply ethical principles and commit to professional ethics and responsibilities and
                            norms of the engineering practice.</li><br>
                        <li><span>Individual and teamwork: </span>Function effectively as an individual, and as a member
                            Or leader in diverse teams, and in multidisciplinary settings.</li><br>
                        <li><span>Communication:</span> Communicate effectively on complex engineering activities with the
                            engineering community and with society at large, such as, being able to comprehend and
                            write effective reports and design documentation, make effective presentations, and give
                            And receive clear instructions.</li><br>
                        <li><span>Project management and finance:</span> Demonstrate knowledge and understanding of the
                            engineering and management principles and apply these to one’s own work, as a member
                            And leader in a team, to manage projects and in multi disciplinary environments.</li><br>
                        <li><span>Life-long learning:</span> Recognize the need for, and have the preparation and ability to
                            engage in independent and life-long learning in the broadest context of technological
                            change.</li><br>
                        
                    </ul>
                    <span>PROGRAM SPECIFIC OUTCOMES (PSOs)</span><p></p>
                    <ul>
                        <br>
                        <li>Knowledge of CivilEngineering discipline
                            Demonstrate in-depth knowledge of Civil Engineering discipline,with an ability to
                            evaluate, analyze and synthesize existing and new knowledge.</li><br>
                        <li>Critical analysis of Civil Engineering problems and innovation
                            Critically analyze complex Civil Engineering problems, apply independent judgment
                            for synthesizing information and make innovative advances in a theoretical, practical
                            and policy context.</li><br>
                        <li>Conceptualization and evaluation of engineering solutions to Civil Engineering
                            Issues Conceptualize and solve Civil Engineering problems, evaluate potential
                            solutions and arrive at technically feasible, economically viable and environmentally
                            sound solutions with due consideration of health, safety, and socio cultural factors</li><br>
                      
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="Programme Offered">
        <div class="page-title-area item-bg8 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Civil Engineering</h2>
                </div>
            </div>
        </div>
        <br><br><br>
        <div class="container">
            <div class="user-actions">
                <i class='bx bx-log-in'></i>
                <span>Programme Offered </span>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">S.NO</th>
                            <th scope="col">Programme Name</th>
                            <th scope="col">Years</th>
                         
                           
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            <td class="product-name">
                                <a >1.</a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/civil">B.E. Civil Engineering</a>
                            </td>
                            <td class="product-total">
                                <span class="subtotal-amount">4 Years</span>
                            </td>
                            
                          </tr>
                 
                    
                          </tbody>
                          </table>
                    
                </div>
            </div>
    </mat-tab>
    </mat-tab-group>



 






<section class="courses-details-area pt-100 pb-70">
    <div class="container">
       <div class="row">
        <div class="col-lg-6">
            <div class="courses-details-image text-center">
                <img src="assets/img/courses/courses40.jpg" alt="image">
            </div>  
        </div>
            <div class="col-lg-6">
                <div class="courses-sidebar-syllabus">
                    
                    <h3>Association Activities</h3>
              
                    <p>The department has been regular in hosting a number of workshops, seminars and symposiums. Students have been tagged along for regular industrial visits to various industries, updating their knowledge on current technological status in the industry sphere and to provide exposure to practical skills. value added courses such as Auto CAD, Total station, Revit architecture,Geo-spatial software applications are offered to students regularly . Students are trained and prepared for placements so that they've got professional skills before entering the industry. With our Industry-Institute Interaction Cell (IIIC) we provide guided factory floor training and internships during semester breaks. Students are motivated to do mini project to improve their practical knowledge. 
                    </p>
                </div>
            </div>
         
        </div>

     </div>
</section>
<section
    class="why-choose-us-area bg-e6fbff"
    [class.dark-why-choose-us-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/test3.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose </span>
                        <h2> Civil Engineering</h2>
                        <p> Civil engineering builds the foundations of society, shaping our world with structures that stand as testaments to human ingenuity, resilience, and progress. It's the art of turning challenges into triumphs, creating infrastructure that connects communities and paves the way for a better future.</p>
                        
                        <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/img/lang-shape2.png" alt="image"></div>
</section>


<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/courses/courses25.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div >
                    
                   
                    <p>The laboratories in the department are well-equipped with modern machineries offering opportunities to practical exposure which will be directly transferrable to real life. The laboratory has been adapted to suit the needs for the following areas.
                    </p>
                    <ul class="description-features-list">
                        <li>Strength of Materials Lab</li>
                        <li>Survey Lab                        </li>
                        <li>Concrete and Highway Lab                        </li>
                        <li>Soil Mechanics Lab</li>
                        <li>Highway Engineering Lab</li>
                        <li>Environmental Engineering Lab</li>
                        <li>Hydraulics Engineering Lab</li>
                        <li>Building Drawing & Detailing Lab</li>
                        
                    </ul>
                       
             
                  
                </div>
            </div>
        </div>
    </div>
</section>



