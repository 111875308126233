<section
    class="events-area bg-f9f9f9 pt-100 pb-70"
    [class.dark-events-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Departments</span>
            
            
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg1">
                                <img src="assets/img/business-coaching/events-img1.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span></span>
                                </div>
                                <h3><a routerLink="/single-events">AUTOMOBILE</a></h3>
                                <p></p>
                                <span class="location"><i class="bx bx-map"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg2">
                                <img src="assets/img/business-coaching/events-img2.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span></span>
                                </div>
                                <h3><a routerLink="/single-events">COMPUTER SCIENCE</a></h3>
                                <p></p>
                                <span class="location"><i class="bx bx-map"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg3">
                                <img src="assets/img/business-coaching/events-img3.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span></span>
                                </div>
                                <h3><a routerLink="/single-events">ELECTRONICS</a></h3>
                                <p></p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg4">
                                <img src="assets/img/business-coaching/events-img2.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span></span>
                                </div>
                                <h3><a routerLink="/single-events">ELECTRICAL</a></h3>
                                <p></p>
                                <span class="location"><i class="bx bx-map"></i>New York, USA</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg5">
                                <img src="assets/img/business-coaching/events-img5.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span></span>
                                </div>
                                <h3><a routerLink="/single-events">CIVIL</a></h3>
                                <p></p>
                                <span class="location"><i class="bx bx-map"></i>New York, USA</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-5 col-md-5 p-0">
                            <div class="image bg4">
                                <img src="assets/img/business-coaching/events-img4.jpg" alt="image">
                                <div class="divider-shape"></div>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <span>Read More</span>
                                </div>
                                <h3><a routerLink="/single-events">MECHANICAL</a></h3>
                                <p>Department of Mechanical Engineering, at Fatima Michael College of
                                    Engineering and Technology, was established along with the institute in 2009 with
                                    the intake of 60 students....</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    </div>
    <div class="business-shape7"><img src="assets/img/business-coaching/business-shape4.png" alt="image"></div>
</section>