import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-app-download',
    templateUrl: './app-download.component.html',
    styleUrls: ['./app-download.component.scss']
})
export class AppDownloadComponent implements OnInit {

    constructor(
    ) {
    }

    ngOnInit(): void {}

}