<app-header-style-two></app-header-style-two>

<div class="row">
    <div class="col-lg-6 col-md-6 pt-100 pb-70">
        <div class="faq-item">
            
            <div class="col-lg-12 col-md-8">
                <div class="about-content">
                    <div class="myDashboard-content">
                        <br><br>
                        <span>Instituional Distinctiveness</span>
                        <div class="recent-orders-table ">
                            
                            <table class="table" >
                               
                                <thead>
                                    <tr>
                                        <th>S No.</th>
                                        <th>Description</th>
                                        <th>Link</th>
                                      
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Instituional Distinctiveness</td>
                                        <td><a href="https://fmcet.ac.in/naac/id/id.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                      
                                        </tr>
                                  
                              
                                 
                              
                                </tbody>
                            </table>
                        </div>
                    </div>

                 </div>
     </div>
        </div>
    </div>
 </div>