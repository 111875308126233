<app-header-style-one></app-header-style-one>



<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Computer Science & Engineering</h2>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="About the Department">
        <div class="page-title-area item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Computer Science & Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
               <div class="row">
                    <div class="col-lg-6">
                        <div class="courses-sidebar-syllabus">
                        <h3>About the Department</h3>                   
                            <p>Computer Science and Engineering (CSE) is a field that combines elements of computer science and engineering to design, develop, and maintain software and hardware systems. CSE department offer programs that cover a wide range of topics, including algorithms, programming languages, computer architecture, software engineering, networking, artificial intelligence, databases, and more. 
                            </p>
                             <p>Department has adopted modern educational practices like Learning Management System – Moodle, NPTEL, Spoken Tutorials, MOOCs for 24 * 7 e-Learning.</p>
                            <p>The Department of CSE was established in the year 2010 with an intake of 60 students with the primary objective of providing 'High Quality Education' in the field of Computer Science Education. The department motivates the stakeholders of the department to contribute efficiency and innovation through research and development. The department has had elite students in the UG and PG programs in the university exams and have gotten placement offers in premier organizations. The centralized air-conditioned laboratories are powered by Windows 2013 Server and Linux Server which are connect through internal network via LAN powered by Intel Core i3 Processors. The department library has digital as well as traditional access. Network Access, Books and PCs are available alongside a various books, magazines, video library of content relevant to the subject published by several well-known authors.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <br><br><br>
                        <div class="courses-details-image text-center">
                            <img src="assets/img/courses/courses11.jpg" alt="image">
                        </div>  
                    </div>
                </div>
        
             </div>
        </section> 
    </mat-tab>
    <mat-tab label="Vision & Mission">
        <div class="page-title-area item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Computer Science & Engineering</h2>
                </div>
            </div>
        </div>

        <section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
      
            <div class="row">
         
            <div class="col-lg-2 col-md-6 col-sm-6">
        
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>To achieve academic excellence in Computer Science & Engineering. Providing in depth knowledge to the students, inculcating research activities to meet out industrial demands and society needs. Producing quality graduates to become leaders and innovators in industry worldwide.</p> 
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
                      To provide quality education to the under graduate and post graduate students and make them expert in cutting edge technology in Computer science engineering.<br>
    
                      To share and apply knowledge in interdisciplinary areas that extends the scope of computer science.<br>
                     To promote research based innovative projects and activities in the emerging technology areas.<br>
                   
                        </p>
                    
                </div>
            </div>
            
      
        </div>
    </div>
</section>
    </mat-tab>
    <mat-tab label="PEOs">
        <div class="page-title-area item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Computer Science & Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span><p>Graduates can</p>
                    <ul>
                        <br>
                        <li>Apply their technical competence in computer science to solve real world
                            problems, with technical and people leadership.</li><br>
                        <li>Conduct cutting edge research and develop solutions on problems of social
                            relevance.</li><br>
                        <li>Work in a business environment, exhibiting team skills, work ethics,
                            adaptability and lifelong learning.</li><br>
                       
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="POs & PSOs">
        <div class="page-title-area item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Computer Science & Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM OUTCOMES (POs)</span><p>Graduates will be able to</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li><span>Engineering knowledge:</span> Apply the knowledge of mathematics, science, engineering
                            fundamentals, and an engineering specialization to the solution of complex engineering
                            problems.</li><br>
                        <li><span>Problem analysis: </span>Identify, formulate, review research literature, and analyze complex
                            Engineering problems reaching substantiated conclusions using first principles of
                            mathematics, natural sciences, and engineering sciences.</li><br>
                        <li><span>Design/development of solutions:</span> Design solutions for complex engineering problems
                            and design system components or processes that meet the specified needs with appropriate
                            consideration for the public health and safety, and the cultural, societal, and
                            Environmental considerations.</li><br>
                        <li><span>Conduct investigations of complex problems:</span> Use research-based knowledge
                            and research methods including design of experiments, analysis and interpretation of
                            data, and synthesis of the information to provide valid conclusions.</li><br>
                        <li><span>Modern tool usage:</span> Create, select, and apply appropriate techniques, resources, and
                            modern engineering and IT tools including prediction and modeling to complex
                            Engineering activities with an understanding of the limitations.</li><br>
                        <li><span>The engineer and society:</span> Apply reasoning in formed by the contextual knowledge
                            To assess societal, health, safety, legal and cultural issues and the consequent
                            responsibilities relevant to the professional engineering practice.</li><br>
                        <li>Environment and sustainability: Understand the impact of the professional engineering
                            solutions in societal and environmental contexts, and demonstrate the knowledge of, and
                            need for sustainable development.</li><br>
                        <li><span>Ethics:</span> Apply ethical principles and commit to professional ethics and responsibilities and
                            norms of the engineering practice.</li><br>
                        <li><span>Individual and teamwork:</span> Function effectively as an individual, and as a member
                            Or leader in diverse teams, and in multidisciplinary settings.</li><br>
                        <li><span>Communication:</span> Communicate effectively on complex engineering activities with the
                            engineering community and with society at large, such as, being able to comprehend and
                            write effective reports and design documentation, make effective presentations, and give
                            And receive clear instructions.</li><br>
                        <li><span>Project management and finance: </span>Demonstrate knowledge and understanding of the
                            engineering and management principles and apply these to one’s own work, as a member
                            And leader in a team, to manage projects and in multi disciplinary environments.</li><br>
                        <li><span>Life-long learning:</span> Recognize the need for, and have the preparation and ability to
                            engage in independent and life-long learning in the broadest context of technological
                            change.</li><br>
                        </ul>
                        <br>
                        <span>PROGRAM SPECIFIC OUTCOMES (PSOs)</span> <p>The Students will be able to</p>
                        <br>
                        <ul>
                            <li>Exhibit design and programming skills to build and automate business
                                solutions using cutting edge technologies.</li><br>
                            <li>Strong theoretical foundation leading to excellence and excitement towards
                                research,to provide elegant solutions to complex problems.</li><br>
                            <li>Ability to work effectively with various engineering fields as a team to
                                design,build and develop system applications.</li><br>
                        </ul>

              
                </div>
            </div>
        </section>

    </mat-tab>
    <mat-tab label="Programme Offered">
        <div class="page-title-area item-bg4 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Computer Science & Engineering</h2>
                </div>
            </div>
        </div>
        <br><br><br>
        <section>
            <div class="container">
                <div class="user-actions">
                    <i class='bx bx-log-in'></i>
                    <span>Programme Offered </span>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">S.NO</th>
                                <th scope="col">Programme Name</th>
                                <th scope="col">Years</th>
                             
                               
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr>
                                <td class="product-name">
                                    <a >1.</a>
                                </td>
                                <td class="product-name">
                                    <a routerLink="/cse">B.E. Computer Science & Engineering</a>
                                </td>
                                <td class="product-total">
                                    <span class="subtotal-amount">4 Years</span>
                                </td>
                                
                              </tr>
                              <tr>
                                <td class="product-name">
                                    <a >2.</a>
                                </td>
                                <td class="product-name">
                                    <a routerLink="/mecse">M.E. Computer Science & Engineering</a>
                                </td>
                                <td class="product-total">
                                    <span >2 Years</span>
                                </td>
                                
                              </tr>
                              </tbody>
                              </table>
                        
                    </div>
                </div>
        </section>
    </mat-tab>

    </mat-tab-group>














<section class="courses-details-area pt-100 pb-70">
    <div class="container">
       <div class="row">
        <div class="col-lg-6">
            <div class="courses-details-image text-center">
                <img src="assets/img/courses/courses16.jpg" alt="image">
            </div>  
        </div>
            <div class="col-lg-6">
                <div class="courses-sidebar-syllabus">
                    <h3>Association Activities</h3>
              
                    <p>The department association  focuses on all round development of students. The association is on track with an idea to mould individuals to deliver solutions at par with international standards. Experts from various industries and leading institutions are invited every fortnight for technical lectures under the banner of association activities. The association also organizes Intra department quiz competitions and Industrial site visits under the guidance of senior faculty members. The students are also put through training to enhance their communication skills and soft skills. Motivational programmes and self-development workshops are conducted to inculcate the positive values and various ways to overcome the hurdles in this competitive world. In order to inculcate professional practices and ethical approaches among our students, the department organizes industrial visit (EPL – External Practical Learning) every year. Through this, students get awareness about the processes and practices which are followed by the industry.
                    </p>
                </div>
            </div>
         
        </div>

     </div>
</section>
<section
    class="why-choose-us-area bg-e6fbff"
    [class.dark-why-choose-us-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/test.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose </span>
                        <h2> Computer Engineering</h2>
                        <p> Embark on an exciting journey in computer science & engineering at Fatima Michael College of Engineering and Technology. Our renowned program combines theoretical knowledge with hands-on experience, preparing students to tackle real-world challenges. With state-of-the-art facilities, expert faculty, and industry-aligned curriculum, students gain a comprehensive understanding of cutting-edge technologies such as artificial intelligence, cybersecurity, and data science. Engage in innovative research projects, internships with leading companies, and personalized mentorship to unlock your full potential. Join our vibrant community and become part of a legacy of excellence in computer science & engineering at Fatima Michael College of Engineering and Technology.</p>

                        <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>


<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/courses/courses7.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div >
                    
                   
                        <h3>Laboratory Facilities</h3>
                        <p>The Department Laboratories are air-conditioned and are powered by Thin Clients, HCL, IBM, Acer and Lenovo bases systems with updated configuration and 24 hours internet connectivity and the labs are open for both faculty and students.
                        </p>
                        <div class="courses-details-desc">
                            <ul class="description-features-list">
                                <li>Cloud Computing Lab                        </li>
                                <li>Programming Language Lab                        </li>
                                <li>Software Development Lab                    
                                </li>
                                <li>Open Source Technology Lab</li>
                                
                            </ul>
                        </div>
                  
                </div>
            </div>
        </div>
    </div>
</section>



