<section
    class="courses-area pt-100 pb-70"
    [class.dark-courses-area]="themeService.isDark()"
>
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Welcome to FMCET</span>
            <h2>Our Departments</h2>
            
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/auto"><img src="assets/img/language-courses/1.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/auto">AUTOMOBILE ENGINEERING</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/cse"><img src="assets/img/language-courses/2.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/cse">COMPUTER SCIENCE & ENGINEERING</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/ai"><img src="assets/img/language-courses/3.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/ai">CSE (ARTIFICIAL INTELLIGENCE)</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/cs"><img src="assets/img/language-courses/4.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/cs">CSE (CYBER SECURITY)</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/civil">  <img src="assets/img/language-courses/5.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/civil">CIVIL ENGINEERING</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/ece"><img src="assets/img/language-courses/6.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/ece">ELECTRONICS & COMMUNICATION ENGINEERING</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/eee"><img src="assets/img/language-courses/7.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/eee">ELECTRICAL & ELECTRONICS ENGINEERING</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/mech"><img src="assets/img/language-courses/8.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/mech">MECHANICAL ENGINEERING</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/sh"><img src="assets/img/language-courses/9.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/sh">SCIENCE & HUMANITIES</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-language-courses-box">
                    <a  class="d-block">
                        <a routerLink="/mba"><img src="assets/img/language-courses/10.jpg" alt="image"></a>
                    </a>
                    <div class="content">
                        <h3><a routerLink="/mba">MASTER OF BUSINESS ADMINISTRATION (MBA)</a></h3>
                        <p></p>
                        
                    </div>
                </div>
            </div>
       </div>
    </div>
</section>