<app-header-style-one></app-header-style-one>


<section >
    <div class="container">
<div class="container-fluid">

        <div class="myDashboard-navigation">
            <br><br>
            <ul>
                <li><a ><i class='bx bxs-dashboard'></i> Women Development Cell</a></li>
               
                
            </ul>
        </div>

        <div class="myDashboard-content">
            <p>The women’s cell of FMCET serves as a dedicated platform to address the needs, concerns and well being of female students and staff. Its primary function is to create a safe and inclusive environment for women within the institution. Here is some key functioning of the Women cell. 
            </p>
           <ul>
            <li>Gender Sensitization</li>
            <p>The Women's cell conducts workshops, seminars and awareness programs to sensitize the college community gender issues, stereotypes and the importance of gender equality.</p>
            <li>Preventing Harassment</li>
            <p>The cell plays a pivotal role in preventing and addressing any form of harassment or discrimination against women within ensures the college premises. It ensures the implementation of policies and guidelines related to preventing sexual harassment</p>
            <li>Support and Counseling</li>
            <p>The Women's cell provides supportive space where female students and staff can seek counseling, guidance and assistance on personal, academic or professional matters</p>
            <li>Safety and Security</li>
            <p>The cell works to enhance the safety and security of women on campus, addressing concerns related to ligating, transportation and overall campus infrastructure</p>
            <li>Awareness Programs</li>
            <p>It organizes awareness campaigns on issues such as women's health, menstrual hygiene, self-defense and legal rights to empower women with knowledge</p>
            <li>Career Development</li>
            <p>It offers workshops and seminars focused on career development, leadership skills and opportunities in the engineering field to help women in their chosen careers</p>
            <li>Mentorship Programs</li>
            <p>It facilitates mentorship programs where senior female students or faculty members mentor and guide junior female students, offering academic and career guidance.</p>
            <li>Celebrate Achievements</li>
            <p>It acknowledges and celebrates the achievements of women in various fields, both within the college and beyond, to inspire and motivate others</p>
            <li>Liaison with Authorities</li>
            <p>It serves as a link between female students and faculty and the collage administration, ensuring that their concerns are heard and addressed appropriately</p>
            <li>Resource Center</li>
            <p>It maintains a resource center with information related to women's rights, career opportunities, scholarships and other relevant resources</p>
            <li>Work-Life Balance</li>
            <p>It organizes workshops on achieving work-like balance, managing stress and promoting overall well being</p>
            <li>Empowerment Workshops</li>
            <p>It organizes workshops to enhance self-confidence, communication skills, negotiation skills and assertiveness among female students</p>
            <li>Networking</li>
            <p>It facilitates networking events where female students can connect with alumnae, industry professionals and role models</p>
            <p>Overall, the Women's cell plays a crucial role in ensuring that women have a supportive and empowering environment, where they can thrive academically professionally and personally</p>
           
        </ul>

        </div>
        <br><br>
        <div class="myDashboard-navigation">
            <ul>
                <li><a ><i class='bx bxs-dashboard'></i> R & D Cell</a></li>
               
                
            </ul>
        </div>
        
        <div class="myDashboard-content">
            <p>R & D cell of FMCET is headed by Dr.M.Subramanian, Dean (Research). He is having 45 years of teaching experience. He guided 2 Ph.D. scholars and 6 M.Phil., scholars. He completed 1 UGC Major Research project with a funding of Rs.12 lakhs. He was the Chairman of Board of Valuation and Board of studies of Alagappa University, Karaikudi</p>
            <p>Our R&D cell is responsible for fostering research activities among faculty and students. It aims to contribute to advancements in technology, innovation and knowledge in various engineering fields. It typically performs the following functions</p>
            <ul>
                <li>Promoting Research Culture</li>
                <p>The R&D Cell encourages a culture of research by organizing seminars, workshops and conferences and by providing resources and support to faculty and students interested in research</p>
                <li>Collaborations</li>
                <p>It establishes Collaborations with industries, research institutions and other academic institutions to facilitate joint research projects, knowledge exchange and technology transfer</p>
                <li>Funding Support</li>
                <p>It helps faculty and students secure funding for their research projects by providing information about available grants, assisting with proposal writing and guiding through the funding application process</p>
                <li>Ethical Guidelines</li>
                <p>It ensures that research activities adhere to ethical standards and guidelines. This includes obtaining necessary approvals for research involving human subjects</p>
                <li>Publication and Dissemination</li>
                <p>It supports researchers in publishing their findings in reputable journals, conferences and publications. It may also maintain a repository of research outputs</p>
                <li>Infrastructure and Resources</li>
                <p>It manages research facilities, laboratories and equipments necessary for conducting experiments and studies. It may also provide access to specialized software and databases</p>
                <li>Innovation and Patenting</li>
                <p>It encourages innovation and assists researchers in patenting their innovations or novel ideas, fostering an environment of intellectual property protection</p>
                <li>Technology Transfer</li>
                <p>It facilitates the transfer of research outcomes to the industry or society by supporting the commercialization of technologies developed within the college</p>
                <li>Interdisciplinary Research</li>
                <p>It promotes interdisciplinary collaboration, encouraging researchers from different departments to work together on projects that span multiple fields</p>
                <li>Skill Enhancement</li>
                <p>It organizes training programs and workshops to enhance the research skills of faculty and students including topics like research methodology, date analysis and academic writing</p>
                <li>Monitoring and Evaluation</li>
                <p>It monitors the progress of ongoing research projects, tracks outcomes and evaluates the impact of research activities</p>
            </ul>
           
           
        
        </div>


        <br><br>
        
        <br><br>
        <div class="myDashboard-navigation">
            <ul>
                <li><a ><i class='bx bxs-dashboard'></i>Entrepreneurship Development Cell (EDC)</a></li>
               
                
            </ul>
        </div>
        
        <div class="myDashboard-content">
            <p>FMCET has built an excellent track record of implementing high-impact entrepreneurship programs. To inculcate successful leadership traits among students the EDC conducts extensive programs and workshops. It works closely with several reputed industries and corporates. This relationship facilitates joint research, funded projects and also provides opportunity to learn the latest technologies.</p>
           
         
           
        
        </div>

</div>

    </div>
    <br><br>
</section>


