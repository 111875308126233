<app-header-style-one></app-header-style-one>


<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="About NAAC">
        <div class="container">
            <br><br><br>
            <div class="row">
                       
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-12">
                        <div class="about-image">
                            
                           <img src="assets/img/about/about22.png" class="shadow" alt="image">
                        </div>
                    </div>
        
                    <div class="col-lg-8 col-md-12">
                        <div class="about-content">
                            <span class="sub-title">About NAAC</span>
                        
                            <p>The NATIONAL ASSESSMENT AND ACCREDITATION COUNCIL (NAAC) conducts assessment and accreditation of Higher Educational Institutions (HEI) such as colleges, universities or other recognised institutions to derive an understanding of the ‘Quality Status’ of the institution. NAAC evaluates the institutions for its conformance to the standards of quality in terms of its performance related to the educational processes and outcomes, curriculum coverage, teaching-learning processes, faculty, research, infrastructure, learning resources, organisation, governance, financial well being and student services.</p>
                      </div>
                    </div>
                </div>


            </div>
            <br><br><br>
        </div>
    </mat-tab>
    <mat-tab label="ACCREDITATION">
        <div class="container">
            <br><br><br>
            <div class="row">
                       
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-image">
                            
                           <img src="assets/img/about/about23.jpg" class="shadow" alt="image">
                        </div>
                    </div>
        
                    <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                            <span class="sub-title">Accreditation</span>
                        
                            <p>Accreditation in the act certifying an educational institution or program as meeting all official formal requirements of academic excellence, facilities, curriculum, etc.</p>
                            <p>India has one of the largest and diverse education systems in the world. Privatization, widespread expansion, increased autonomy and introduction of programmes in new and emerging areas have improved access to higher education. At the same time, it has also led to widespread concern on the quality and relevance of the higher education.</p>
                            <p>To address these concerns, the National Policy on Education (NPE) and the Programme of Action (POA) established an independent National Assessment and Accreditation Council (NAAC).</p>
                            <p>Now we have been preparing all the details and we are going to submit SSR (Self Study Report) to NAAC, so that our college will be accredited soon.</p>
                      </div>
                    </div>
                </div>


            </div>
            <br><br><br>
        </div>
    </mat-tab>
    
    <mat-tab label="Self Declaration">
        <div class="container">
            <br><br><br>
            <div class="row">
                       
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="about-image">
                            
                            <img src="assets/img/pdf/SelfDeclaration.jpg" class="shadow" alt="image">
                        </div>
                    </div>
        
                    
                </div>


            </div>
            <br><br>
        </div>
    </mat-tab>
    <mat-tab label="Undertaking">
        <div class="container">
            <br><br>
            <div class="row">
                       
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <div class="about-image">
                            
                            <img src="assets/img/pdf/Undertaking.jpg" class="shadow" alt="image">
                        </div>
                    </div>
        
                    
                </div>


            </div>
            <br><br>
        </div>
    </mat-tab>
    <mat-tab label="NAAC-Criterion">
        <section style="background-color: #F5F5F6;">
            <section  class="container" >
               
                <div class="row">
                  
                <div class="container col-lg-3 col-md-6">
                    
                    <div class="faq-accordion-tab" >
                        <ul class="nav-tabset " >
                            <br><br>  
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                                <span class="active" (click)="switchTab($event, 'tab1')">Extended Profile</span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                                <span (click)="switchTab($event, 'tab2')">Criterion 1</span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                                <span (click)="switchTab($event, 'tab3')">Criterion 2</span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                                <span (click)="switchTab($event, 'tab4')">Criterion 3</span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                                <span (click)="switchTab($event, 'tab5')">Criterion 4</span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                                <span (click)="switchTab($event, 'tab6')">Criterion 5</span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab7'}">
                                <span (click)="switchTab($event, 'tab7')">Criterion 6</span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab8'}">
                                <span (click)="switchTab($event, 'tab8')">Criterion 7</span>
                            </li><br>
                           
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab9'}">
                                <span (click)="switchTab($event, 'tab9')">DVV CLARIFICATION</span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab10'}">
                                <span (click)="switchTab($event, 'tab10')"><a routerLink="/bestpractices"> <p>BESTPRACTICES</p></a></span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab11'}">
                                <span (click)="switchTab($event, 'tab11')"><a routerLink="/INSTITUTIONALDISTINCTIVENESS"><p>INSTITUTIONAL DISTINCTIVENESS</p></a></span>
                            </li><br>
                           
                           
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab14'}">
                                <span (click)="switchTab($event, 'tab14')"><a routerLink="/capacitybuilding"> <p>CAPACITY BUILDING SKILL ENHANCEMENT</p></a></span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab15'}">
                                <span (click)="switchTab($event, 'tab15')"><a routerLink="/stakeholdersfeedback"> <p>STAKEHOLDERS FEEDBACK</p></a></span>
                            </li><br>
                            <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab16'}">
                                <span (click)="switchTab($event, 'tab16')">ANNUAL REPORT </span>
                            </li><br>
                         
                           
                        </ul>
                    </div>
                </div>
            
                <!--Extended Profile-->  
                <div class="container col-lg-9 col-md-6">
                        <div class="tabs-container">
                            <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                                <div class="row">
                                    <div class="col-lg-12 col-md-6">
                                        <div class="faq-item">
                                            <br><br>
                                            <span> Extended Profile</span>
                                            <div class="col-lg-12 col-md-8">
                                                <div class="about-content">
                                                    <div class="myDashboard-content">
                                                        
                                                        <div class="recent-orders-table ">
                                                            
                                                            <table class="table" >
                                                               
                                                                <thead>
                                                                    <tr>
                                                                        <th>Metric No.</th>
                                                                        <th>Description</th>
                                                                        <th>Link</th>
                                                                      
                                                                        
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1.1</td>
                                                                        <td>Number of students year wise during the last five years</td>
                                                                        <td><a href="https://fmcet.ac.in/naac/ep/1.1/1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                      
                                                                        </tr>
                                                                    <tr>
                                                                        <td>2.1 & 2.2</td>
                                                                        <td>Number of full time teachers during the last five years (Without repeat count)</td>
                                                                        <td><a href="https://fmcet.ac.in/naac/ep/2.1/2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                     
                                                                    </tr>
                                                              
                                                                    <tr>
                                                                        <td>3.1</td>
                                                                        <td>Expenditure excluding salary component year wise during the last five years (INR in lakhs)</td>
                                                                        <td><a href="https://fmcet.ac.in/naac/ep/3.1/3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                        
                                                                    </tr>
                                                              
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                
                                                 </div>
                                     </div>
            
                                        </div>
                                    </div>
                                </div>
                            </div>
            <!--Criterion 1-->
                            <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                                <div class="row">
                                    <div class="col-lg-12 col-md-6">
                                        <div class="faq-item">
                                            <br><br>
                                            <span> Criterion 1 - Curricular Aspects</span>
                                            <span>KEY INDICATOR – 1.1 CURRICULAR PLANNING AND IMPLEMENTATION</span>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="about-content">
                                                    <div class="myDashboard-content">
                                                        
                                                        <div class="recent-orders-table ">
                                                            <table class="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Metric No.</th>
                                                                        <th>Description</th>
                                                                        <th>Link</th>
                                                                      
                                                                        
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1.1.1 QlM</td>
                                                                        <td ><p>The Institution ensures effective curriculum planning and delivery through a well – planned and documented 
                                                                             Write description in a maximum of 500 words</p></td>
                                                                        <td><a href="https://fmcet.ac.in/naac/criterion1/1.1.1/1.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                      
                                                                        </tr>
                                                                  
                                                              
                                                                </tbody>
                                                            </table>
                                                        </div>
            
                                                    </div>
                
                                                 </div>
                                     </div>
                                     <span class="sub-title">Key Indicator – 1.2 Academic Flexibility</span>
                                                            
                                       
                                     <div class="col-lg-12 col-md-12">
                                         <div class="about-content">
                                             <div class="myDashboard-content">
                                                 
                                                 <div class="recent-orders-table ">
                                                     <table class="table">
                                                         <thead>
                                                             <tr>
                                                                 <th>Metric No.</th>
                                                                 <th>Description</th>
                                                                 <th>Link</th>
                                                               
                                                                 
                                                             </tr>
                                                         </thead>
                                                         <tbody>
                                                             <tr>
                                                                 <td>1.2.1 QnM
                                                                    </td>
                                                                 <td class="flex-wrap"><p>Number of Add on /Certificate/Value added programs offered during the last five years
                                                                    1.2.1.1: Number of Add on /Certificate /Value added programs offered during the last five years</p></td>
                                                                 <td><a href="https://fmcet.ac.in/naac/criterion1/1.2.1/1.2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                               
                                                                 </tr>
                                                                 <tr>
                                                                    <td>1.2.2 QnM
                                                                       </td>
                                                                    <td class="flex-wrap"><p>Percentage of students enrolled in Certificate/ Add-on/Value added programs as against the total number of students during the last five years
                                                                        1.2.2.1. Number of students enrolled in subject related Certificate/Add-on/Value added programs year wise during last five years</p></td>
                                                                    <td><a href="https://fmcet.ac.in/naac/criterion1/1.2.2/1.2.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                  
                                                                    </tr>
                                                              
                                                       
                                                         </tbody>
                                                     </table>
                                                 </div>
            
                                             </div>
            
                                          </div>
                              </div>
            
            
                              <span class="sub-title">Key Indicator – 1.3 Curriculum Enrichment </span>
                                        
                   
                              <div class="col-lg-12 col-md-12">
                                  <div class="about-content">
                                      <div class="myDashboard-content">
                                          
                                          <div class="recent-orders-table ">
                                              <table class="table">
                                                  <thead>
                                                      <tr>
                                                          <th>Metric No.</th>
                                                          <th>Description</th>
                                                          <th>Link</th>
                                                        
                                                          
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      <tr>
                                                          <td>1.3.1 QlM
                                                            </td>
                                                          <td class="flex-wrap"><p>Institution integrates crosscutting issues relevant to Professional Ethics, Gender, Human Values, Environment and Sustainability into the Curriculum Write description in a maximum of 500 words</p></td>
                                                          <td><a href="https://fmcet.ac.in/naac/criterion1/1.3.1/1.3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                        
                                                          </tr>
                                                          <tr>
                                                             <td>1.3.2 QnM
                                                                </td>
                                                             <td class="flex-wrap"><p>Percentage of students undertaking project work/field work/internships (Data for the latest completed academic year)
                                                                1.3.2.1. Number of students undertaking project work/field work /internships</p></td>
                                                             <td><a href="https://fmcet.ac.in/naac/criterion1/1.3.2/1.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                           
                                                             </tr>
                                                       
                                                
                                                  </tbody>
                                              </table>
                                          </div>
            
                                      </div>
            
                                   </div>
                       </div>
            
                       <span class="sub-title">Key Indicator – 1.4 Feedback System </span>
                                        
                   
                       <div class="col-lg-12 col-md-12">
                           <div class="about-content">
                               <div class="myDashboard-content">
                                   
                                   <div class="recent-orders-table ">
                                       <table class="table">
                                           <thead>
                                               <tr>
                                                   <th>Metric No.</th>
                                                   <th>Description</th>
                                                   <th>Link</th>
                                                 
                                                   
                                               </tr>
                                           </thead>
                                           <tbody>
                                               <tr>
                                                   <td>1.4.1 QnM
                                                    </td>
                                                   <td class="flex-wrap"><p>Institution obtains feedback on the academic performance and ambience of the institution from various stakeholders, such as Students, Teachers, Employers, Alumni etc. and action taken report on the feedback is made available on institutional website (Yes or No)</p></td>
                                                   <td><a href="https://fmcet.ac.in/naac/criterion1/1.4.1/1.4.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                 
                                                   </tr>
                                             
                                                
                                         
                                           </tbody>
                                       </table>
                                   </div>
            
                               </div>
            
                            </div>
                </div>
                <br><br>
            
            
                                        </div>
                                    </div>
                              </div>
                            </div>
            <!--criterion 2-->
                            <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                                <div class="row">
                                  
                                    <div class="col-lg-12 col-md-6">
                                        <div class="faq-item">
                                            <br><br>
                                            <span class="sub-title">Criterion 2 - Teaching- Learning and Evaluation </span>
                                            <span class="sub-title">Key Indicator – 2.1 Student Enrolment and Profile </span>
                                        
                        
                                            <div class="col-lg-12 col-md-8">
                                                <div class="about-content">
                                                    <div class="myDashboard-content">
                                                        
                                                        <div class="recent-orders-table ">
                                                            <table class="table" >
                                                                <thead>
                                                                    <tr>
                                                                        <th>Metric No.</th>
                                                                        <th>Description</th>
                                                                        <th>Link</th>
                                                                      
                                                                        
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>2.1.1 QnM
                                                                            </td>
                                                                        <td><p>Enrolment percentage</p></td>
                                                                        <td><a href="https://fmcet.ac.in/naac/criterion2/2.1.1/2.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                      
                                                                        </tr>
                                                                    <tr>
                                                                        <td>2.1.2 QnM
                                                                            </td>
                                                                        <td><p>Percentage of seats filled against seats reserved for various categories (SC, ST, OBC, Divyangjan, etc. as per applicable reservation policy during the last five years Exclusive of supernumerary seats)</p></td>
                                                                        <td><a href="https://fmcet.ac.in/naac/criterion2/2.1.2/2.1.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                     
                                                                    </tr>
                                                                  
                                                              
                                                                </tbody>
                                                            </table>
                                                        </div>
                        
                        
                                                    </div>
                        
                                                 </div>
                                     </div>
                        
                                     <span class="sub-title">Key Indicator – 2.2 Student Teacher Ratio </span>
                        
                                     <div class="col-lg-12 col-md-8">
                                        <div class="about-content">
                                            <div class="myDashboard-content">
                                                
                                                <div class="recent-orders-table ">
                                                    <table class="table" >
                                                        <thead>
                                                            <tr>
                                                                <th>Metric No.</th>
                                                                <th>Description</th>
                                                                <th>Link</th>
                                                              
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>2.2.1 QnM</td>
                                                                <td><p>Student – Full time Teacher Ratio (Data for the latest completed academic year)&nbsp;&nbsp;&nbsp;</p></td>
                                                                <td><a href="https://fmcet.ac.in/naac/criterion2/2.2.1/2.2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                              
                                                                </tr>
                                                            
                                                          
                                                      
                                                        </tbody>
                                                    </table>
                                                </div>
                        
                        
                                            </div>
                        
                                         </div>
                             </div>
                        
                             <span class="sub-title">Key Indicator – 2.3 Teaching- Learning Process </span>
                             <div class="col-lg-12 col-md-8">
                                <div class="about-content">
                                    <div class="myDashboard-content">
                                        
                                        <div class="recent-orders-table ">
                                            <table class="table" >
                                                <thead>
                                                    <tr>
                                                        <th>Metric No.</th>
                                                        <th>Description</th>
                                                        <th>Link</th>
                                                      
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>2.3.1 QlM
                                                            </td>
                                                        <td><p>Student centric methods, such as experiential learning, participative learning and problem solving methodologies are used for enhancing learning experiences using ICT tools Write description in a maximum of 500 words</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion2/2.3.1/2.3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                                 
                                                  
                                              
                                                </tbody>
                                            </table>
                                        </div>
                        
                        
                                    </div>
                        
                                 </div>
                        </div>
                        
                        <span class="sub-title">Key Indicator – 2.4 Teacher Profile and Quality</span>
                        
                        <div class="col-lg-12 col-md-8">
                            <div class="about-content">
                                <div class="myDashboard-content">
                                    
                                    <div class="recent-orders-table ">
                                        <table class="table" >
                                            <thead>
                                                <tr>
                                                    <th>Metric No.</th>
                                                    <th>Description</th>
                                                    <th>Link</th>
                                                  
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2.4.1 QnM
                                                        </td>
                                                    <td><p>Percentage of full-time teachers against sanctioned posts during the last five years
                                                        2.4.1.1 Number of Sanctioned posts / required positions for teaching staff/ full time teachers year wise during the last five years</p></td>
                                                    <td><a href="https://fmcet.ac.in/naac/criterion2/2.4.1/2.4.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                  
                                                    </tr>
                                                <tr>
                                                    <td>2.4.2 QnM
                                                        </td>
                                                    <td><p>Percentage of full time teachers with NET/SET/SLET/ Ph. D. / D.M. / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt. during the last five years (consider only highest degree for count) 2.4.2.1. Number of full time teachers with NET/SET/SLET/Ph. D. /D.M. / M.Ch. / D.N.B Superspeciality / D.Sc. / D.Litt. year wise during the last five years</p></td>
                                                    <td><a href="https://fmcet.ac.in/naac/criterion2/2.4.2/2.4.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                 
                                                </tr>
                                              
                                          
                                            </tbody>
                                        </table>
                                    </div>
                        
                        
                                </div>
                        
                             </div>
                        </div>
                        
                        <span class="sub-title">Key Indicator – 2.5 Evaluation Process and Reforms </span>
                        <div class="col-lg-12 col-md-8">
                            <div class="about-content">
                                <div class="myDashboard-content">
                                    
                                    <div class="recent-orders-table ">
                                        <table class="table" >
                                            <thead>
                                                <tr>
                                                    <th>Metric No.</th>
                                                    <th>Description</th>
                                                    <th>Link</th>
                                                  
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2.5.1 QlM
                                                        </td>
                                                    <td><p>Mechanism of internal/ external assessment is transparent and the grievance redressal system is time- bound and efficient Write description in a maximum of 500 words</p></td>
                                                    <td><a href="https://fmcet.ac.in/naac/criterion2/2.5.1/2.5.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                  
                                                    </tr>
                                       
                                              
                                          
                                            </tbody>
                                        </table>
                                    </div>
                        
                        
                                </div>
                        
                             </div>
                        </div>
                        
                        <span class="sub-title">Key Indicator – 2.6 Student Performance and Learning Outcome </span>
                        <div class="col-lg-12 col-md-8">
                            <div class="about-content">
                                <div class="myDashboard-content">
                                    
                                    <div class="recent-orders-table ">
                                        <table class="table" >
                                            <thead>
                                                <tr>
                                                    <th>Metric No.</th>
                                                    <th>Description</th>
                                                    <th>Link</th>
                                                  
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2.6.1 QlM
                                                        </td>
                                                    <td><p>Programme Outcomes (POs) and Course Outcomes (COs) for all Programmes offered by the institution are stated and displayed on website.</p></td>
                                                    <td><a href="https://fmcet.ac.in/naac/criterion2/2.6.1/2.6.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                  
                                                    </tr>
                                                <tr>
                                                    <td>2.6.2 QlM
                                                        </td>
                                                    <td><p>Attainment of POs and COs are evaluated Write description in a maximum of 500 words.</p></td>
                                                    <td><a href="https://fmcet.ac.in/naac/criterion2/2.6.2/2.6.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                 
                                                </tr>
                                                <tr>
                                                    <td>2.6.3 QnM
                                                        </td>
                                                    <td><p>Pass percentage of Students during last five years. (Excluding Backlog Students)</p></td>
                                                    <td><a href="https://fmcet.ac.in/naac/criterion2/2.6.3/2.6.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                 
                                                </tr>
                                          
                                            </tbody>
                                        </table>
                                    </div>
                        
                        
                                </div>
                        
                             </div>
                        </div>
                        
                        <span class="sub-title">Key Indicator – 2.7 Student Satisfaction Survey </span>
                        
                        <div class="col-lg-12 col-md-8">
                            <div class="about-content">
                                <div class="myDashboard-content">
                                    
                                    <div class="recent-orders-table ">
                                        <table class="table" >
                                            <thead>
                                                <tr>
                                                    <th>Metric No.</th>
                                                    <th>Description</th>
                                                    <th>Link</th>
                                                  
                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                              
                                                <tr>
                                                    <td>2.7.1 QnM
                                                        </td>
                                                    <td><p>Online student satisfaction survey regarding to teaching learning process.(Online survey to be conducted)</p></td>
                                                    <td><a href="https://fmcet.ac.in/naac/criterion2/2.7.1/2.7.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                 
                                                </tr>
                                              
                                          
                                            </tbody>
                                        </table>
                                    </div>
                        
                        
                                </div>
                        
                             </div>
                        </div>
                        <br><br>
                                        </div>
                                    </div>
                                 </div>
                            </div>
            <!--criterion 3-->
                            <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                                <div class="row">
                                    <div class="col-lg-12 col-md-6">
                                        <div class="faq-item">
                                            <br><br>
                                            <span class="sub-title">Criterion 3 – Research, Innovations and Extension </span>
                                            <span class="sub-title">Key Indicator – 3.1 Resource Mobilization for Research </span>
                        
                                            <div class="col-lg-12 col-md-8">
                                                <div class="about-content">
                                                    <div class="myDashboard-content">
                                                        
                                                        <div class="recent-orders-table ">
                                                            <table class="table" >
                                                                <thead>
                                                                    <tr>
                                                                        <th>Metric No.</th>
                                                                        <th>Description</th>
                                                                        <th>Link</th>
                                                                      
                                                                        
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>3.1.1 QnM
                                                                            </td>
                                                                        <td><p>Grants received from Government and non-governmental agencies for research projects / endowments in the institution during the last five years (INR in Lakhs) 3.1.1.1: Total Grants from Government and non-governmental agencies for research projects / endowments in the institution during the last five years (INR in Lakhs)</p></td>
                                                                        <td><a href="https://fmcet.ac.in/naac/criterion3/3.1.1/3.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                      
                                                                        </tr>
                                                                                                         
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                        
                                                 </div>
                                     </div>
                        
                        
                                     <span class="sub-title">Key Indicator – 3.2 Innovation Ecosystem </span>
                        
                                     <div class="col-lg-12 col-md-8">
                                        <div class="about-content">
                                            <div class="myDashboard-content">
                                                
                                                <div class="recent-orders-table ">
                                                    <table class="table" >
                                                        <thead>
                                                            <tr>
                                                                <th>Metric No.</th>
                                                                <th>Description</th>
                                                                <th>Link</th>
                                                              
                                                                
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>3.2.1 QlM
                                                                    </td>
                                                                <td><p>Institution has created an ecosystem for innovations and has initiatives for creation and transfer of knowledge (patents filed, published, incubation center facilities in the HEI to be considered) Write description in a maximum of 500 words</p></td>
                                                                <td><a href="https://fmcet.ac.in/naac/criterion3/3.2.1/3.2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                              
                                                                </tr>
                                                                <tr>
                                                                    <td>3.2.2 QnM
                                                                        </td>
                                                                    <td><p>Number of workshops/seminars/conferences including on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship conducted during the last five years 3.2.2.1: Total number of workshops/seminars/conferences including programs conducted on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship year wise during last five years</p></td>
                                                                    <td><a href="https://fmcet.ac.in/naac/criterion3/3.2.2/3.2.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                  
                                                                    </tr>                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                        
                                         </div>
                             </div>
                        
                             
                             <span class="sub-title">Key Indicator – 3.3 Research Publication and Awards </span>
                        
                             <div class="col-lg-12 col-md-8">
                                <div class="about-content">
                                    <div class="myDashboard-content">
                                        
                                        <div class="recent-orders-table ">
                                            <table class="table" >
                                                <thead>
                                                    <tr>
                                                        <th>Metric No.</th>
                                                        <th>Description</th>
                                                        <th>Link</th>
                                                      
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>3.3.1 QnM
                                                            </td>
                                                        <td><p>Number of research papers published per teacher in the Journals notified on UGC care list during the last five years
                                                            3.3.1.1. Number of research papers in the Journals notified on UGC CARE year wise during the last five years</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion3/3.3.1/3.3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                                        <tr>
                                                            <td>3.3.2 QnM
                                                                </td>
                                                            <td><p>Number of books and chapters in edited volumes/books published and papers published in national/ international conference proceedings per teacher during last five years
                                                                3.3.2.1. Total number of books and chapters in edited volumes/books published and papers in national/ international conference proceedings year wise during last five years</p></td>
                                                            <td><a href="https://fmcet.ac.in/naac/criterion3/3.3.2/3.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                          
                                                            </tr>                                                                 
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                        
                                 </div>
                        </div>
                        
                        <span class="sub-title">Key Indicator – 3.4 Extension Activities</span>
                        
                        <div class="col-lg-12 col-md-8">
                           <div class="about-content">
                               <div class="myDashboard-content">
                                   
                                   <div class="recent-orders-table ">
                                       <table class="table" >
                                           <thead>
                                               <tr>
                                                   <th>Metric No.</th>
                                                   <th>Description</th>
                                                   <th>Link</th>
                                                 
                                                   
                                               </tr>
                                           </thead>
                                           <tbody>
                                               <tr>
                                                   <td>3.4.1 QlM
                                                       </td>
                                                   <td><p>Extension activities are carried out in the neighborhood community, sensitizing students to social issues, for their holistic development, and impact thereof during the last five years. Write description in a maximum of 500 words</p></td>
                                                   <td><a href="https://fmcet.ac.in/naac/criterion3/3.4.1/3.4.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                 
                                                   </tr>
                                                        
                                                   <tr>
                                                    <td>3.4.2 QlM
                                                        </td>
                                                    <td><p>Awards and recognitions received for extension activities from government / government recognized bodies Write description in a maximum of 500 words</p>></td>
                                                    <td><a href="https://fmcet.ac.in/naac/criterion3/3.4.2/3.4.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                  
                                                    </tr>
                                                    <tr>
                                                        <td>3.4.3 QnM
                                                            </td>
                                                        <td><p>Number of extension and outreach programs conducted by the institution through NSS/NCC/Red cross/YRC etc., (including the programmes such as Swachh Bharat, AIDS awareness, Gender issues etc. and/or those organized in collaboration with industry, community and NGOs) during the last five years 3.4.3.1. Number of extension and outreach Programs conducted in collaboration with industry, community, and Non- Government Organizations through NSS/ NCC/ Red Cross/ YRC etc., year wise during the last five years</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion3/3.4.3/3.4.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                           </tbody>
                                       </table>
                                   </div>
                               </div>
                        
                            </div>
                        </div>
                        
                        <span class="sub-title">Key Indicator – 3.5 Collaboration </span>
                        
                        <div class="col-lg-12 col-md-8">
                           <div class="about-content">
                               <div class="myDashboard-content">
                                   
                                   <div class="recent-orders-table ">
                                       <table class="table" >
                                           <thead>
                                               <tr>
                                                   <th>Metric No.</th>
                                                   <th>Description</th>
                                                   <th>Link</th>
                                                 
                                                   
                                               </tr>
                                           </thead>
                                           <tbody>
                                               <tr>
                                                   <td>3.5.1 QnM
                                                       </td>
                                                   <td><p>The number of MoUs, collaborations/linkages for Faculty exchange, Student exchange, Internship, Field trip, On-the- job training, research and other academic activities during the last five years 3.5.1.1 Number of MoUs, collaborations/linkages for Faculty exchange, Student exchange, Internship, Field trip, On-the- job training, research and other academic activities during the last five years</p></td>
                                                   <td><a href="https://fmcet.ac.in/naac/criterion3/3.5.1/3.5.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                 
                                                   </tr>
                                                                                    
                                           </tbody>
                                       </table>
                                   </div>
                               </div>
                        
                            </div>
                        </div>
                        <br><br>
                                        </div>
                                    </div>
                                 </div>
                            </div>
            
            <!--criterion 4-->
            <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="faq-item">
                            <br><br>
                            <span class="sub-title">Criterion 4 – Infrastructure and Learning Resources</span>
                            
                            <span class="sub-title">Key Indicator – 4.1 Physical Facilities </span>
                            <div class="col-lg-12 col-md-8">
                                <div class="about-content">
                                    <div class="myDashboard-content">
                                        
                                        <div class="recent-orders-table ">
                                            <table class="table" >
                                                <thead>
                                                    <tr>
                                                        <th>Metric No.</th>
                                                        <th>Description</th>
                                                        <th>Link</th>
                                                      
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>4.1.1 QlM
                                                            </td>
                                                        <td><p>Availability of adequate infrastructure and physical facilities viz., classrooms, laboratories, ICT facilities, cultural activities, gymnasium, yoga centre etc. in the institution Write description in a maximum of 500 words</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion4/4.1.1/4.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                                    <tr>
                                                        <td>4.1.2 QnM</td>
                                                        <td><p>Percentage of expenditure, excluding salary for infrastructure augmentation during last five years (INR in Lakhs)
                                                            4.1.2.1 Expenditure for infrastructure augmentation, excluding salary year wise during last five years (INR in lakhs)</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion4/4.1.2/4.1.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                     
                                                    </tr>
                                                   
                                              
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
            
                                 </div>
                     </div>
            
                     <span class="sub-title">Key Indicator – 4.2 Library as a learning Resource </span>
                     <div class="col-lg-12 col-md-8">
                         <div class="about-content">
                             <div class="myDashboard-content">
                                 
                                 <div class="recent-orders-table ">
                                     <table class="table" >
                                         <thead>
                                             <tr>
                                                 <th>Metric No.</th>
                                                 <th>Description</th>
                                                 <th>Link</th>
                                               
                                                 
                                             </tr>
                                         </thead>
                                         <tbody>
                                             <tr>
                                                 <td>4.2.1 QlM
                                                    </td>
                                                 <td><p>Library is automated using Integrated Library Management System (ILMS), subscription to e-resources, amount spent on purchase of books, journals and per day usage of library Write description in a maximum of 500 words</p></td>
                                                 <td><a href="https://fmcet.ac.in/naac/criterion4/4.2.1/4.2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                               
                                                 </tr>
                                           
                                            
                                       
                                         </tbody>
                                     </table>
                                 </div>
                             </div>
            
                          </div>
              </div>                   <span class="sub-title">Key Indicator – 4.3 IT Infrastructure 
            </span>
              <div class="col-lg-12 col-md-8">
                  <div class="about-content">
                      <div class="myDashboard-content">
                          
                          <div class="recent-orders-table ">
                              <table class="table" >
                                  <thead>
                                      <tr>
                                          <th>Metric No.</th>
                                          <th>Description</th>
                                          <th>Link</th>
                                        
                                          
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>4.3.1 QlM
                                            </td>
                                          <td><p>Institution frequently updates its IT facilities and provides sufficient bandwidth for internet connection Write description in a maximum of 500 words</p></td>
                                          <td><a href="https://fmcet.ac.in/naac/criterion4/4.3.1/4.3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                        
                                          </tr>
                                      <tr>
                                          <td>4.3.2 QnM
                                            </td>
                                          <td><p>Student–Computer ratio (Data for the latest completed academic year) 4.3.2.1. Number of computers available for students usage during the latest completed academic year</p></td>
                                          <td><a href="https://fmcet.ac.in/naac/criterion4/4.3.2/4.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                       
                                      </tr>
                                     
                                
                                  </tbody>
                              </table>
                          </div>
                      </div>
            
                   </div>
            </div>                  
            
            
            <span class="sub-title">Key Indicator – 4.4 Maintenance of Campus Infrastructure </span>
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>4.4.1 QnM
                                        </td>
                                    <td><p>Percentage of expenditure incurred on maintenance of infrastructure (physical and academic support facilities) excluding salary component during the last five years (INR in Lakhs)
                                        4.4.1.1. Expenditure incurred on maintenance of infrastructure (physical facilities and academic support facilities) excluding salary component year wise during the last five years (INR in lakhs)</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/criterion4/4.4.1/4.4.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                               
                               
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <br><br>
                        </div>
                    </div>
                 </div>
            </div>
            <!--criterion 5-->
            <div class="pane" id="tab6" *ngIf="currentTab === 'tab6'">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="faq-item">
                            <br><br>
                            <span class="sub-title">Criterion 5 – Student Support and Progression </span>
                            <span class="sub-title">Key Indicator – 5.1 Student Support </span>
            
                            <div class="col-lg-12 col-md-8">
                                <div class="about-content">
                                    <div class="myDashboard-content">
                                        
                                        <div class="recent-orders-table ">
                                            <table class="table" >
                                                <thead>
                                                    <tr>
                                                        <th>Metric No.</th>
                                                        <th>Description</th>
                                                        <th>Link</th>
                                                      
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>5.1.1 QnM
                                                            </td>
                                                        <td><P>Percentage of students benefited by scholarships and freeships provided by the Government and Non-Government agencies during last five years 5.1.1.1. Number of students benefited by scholarships and freeships provided by the Government and Non-Government agencies year wise during last five years</P></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion5/5.1.1/5.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                                    <tr>
                                                        <td>5.1.2 QnM
                                                            </td>
                                                        <td><P>Capacity building and skills enhancement initiatives taken by the institution include the following 1.Soft skills 2. Language and communication skills 3. Life skills (Yoga, physical fitness, health and hygiene) 4. ICT/computing skills</P></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion5/5.1.2/5.1.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                     
                                                    </tr>
                                                    <tr>
                                                        <td>5.1.3 QnM
                                                            </td>
                                                        <td><P>Percentage of students benefitted by guidance for competitive examinations and career counseling offered by the Institution during the last five years 5.1.3.1. Number of students benefitted by guidance for competitive examinations and career counseling offered by the institution year wise during last five years</P></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion5/5.1.3/5.1.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>5.1.4 QnM
                                                            </td>
                                                        <td><P>The Institution has a transparent mechanism for timely redressal of student grievances including sexual harassment and ragging cases 1. Implementation of guidelines of statutory/regulatory bodies 2. Organization wide awareness and undertakings on policies with zero tolerance 3. Mechanisms for submission of online/offline students’ grievances 4. Timely redressal of the grievances through appropriate committees</P></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion5/5.1.4/5.1.4_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                        
                                                    </tr>
                                              
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
            
                                 </div>
                     </div>
            
                     <span class="sub-title">Key Indicator – 5.2 Student Progression </span>
            
                     <div class="col-lg-12 col-md-8">
                         <div class="about-content">
                             <div class="myDashboard-content">
                                 
                                 <div class="recent-orders-table ">
                                     <table class="table" >
                                         <thead>
                                             <tr>
                                                 <th>Metric No.</th>
                                                 <th>Description</th>
                                                 <th>Link</th>
                                               
                                                 
                                             </tr>
                                         </thead>
                                         <tbody>
                                             <tr>
                                                 <td>5.2.1 QnM
                                                    </td>
                                                 <td><P>Percentage of placement of outgoing students and students progressing to higher education during the last five years 5.2.1.1. Number of outgoing students placed and / or progressed to higher education year wise during the last five years 5.2.1.2. Number of outgoing students year wise during the last five years</P></td>
                                                 <td><a href="https://fmcet.ac.in/naac/criterion5/5.2.1/5.2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                               
                                                 </tr>
                                             <tr>
                                                 <td>5.2.2 QnM
                                                    </td>
                                                 <td><P>Percentage of students qualifying in state/national/ international level examinations during the last five years (eg: JAM/CLAT/GATE/ GMAT/ CAT/ GRE/ TOEFL/ Civil Services/State government examinations) 5.2.2.1. Number of students qualifying in state/ national/ international level examinations (eg: JAM/CLAT/NET/ SLET/ GATE/ GMAT/CAT/GRE/ TOEFL/ Civil Services/ State government examinations) year wise during last five years</P></td>
                                                 <td><a href="https://fmcet.ac.in/naac/criterion5/5.2.2/5.2.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                              
                                             </tr>
                                        
                                       
                                         </tbody>
                                     </table>
                                 </div>
                             </div>
            
                          </div>
              </div>                   
              
              
              <span class="sub-title">Key Indicator – 5.3 Student Participation and Activities </span>
            
              <div class="col-lg-12 col-md-8">
                  <div class="about-content">
                      <div class="myDashboard-content">
                          
                          <div class="recent-orders-table ">
                              <table class="table" >
                                  <thead>
                                      <tr>
                                          <th>Metric No.</th>
                                          <th>Description</th>
                                          <th>Link</th>
                                        
                                          
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>5.3.1 QnM
                                            </td>
                                          <td><P>Number of awards/medals for outstanding performance in sports/ cultural activities at University / state/ national / international level (award for a team event should be counted as one) during the last five years5.3.1.1: Number of awards/medals for outstanding performance in sports/cultural activities at University/state/ national / international level (award for a team event should be counted as one) year wise during the last five years</P></td>
                                          <td><a href="https://fmcet.ac.in/naac/criterion5/5.3.1/5.3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                        
                                          </tr>
                                      <tr>
                                          <td>5.3.2 QnM
                                            </td>
                                          <td><P>Average number of sports and cultural programs in which students of the Institution participated during last five years (organized by the institution/other institutions) 5.3.2.1. Number of sports and cultural programs in which students of the Institution participated year wise during last five years</P></td>
                                          <td><a href="https://fmcet.ac.in/naac/criterion5/5.3.2/5.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                       
                                      </tr>
                                      
                                
                                  </tbody>
                              </table>
                          </div>
                      </div>
            
                   </div>
            </div>                    
            
            
            <span class="sub-title">Key Indicator – 5.4 Alumni Engagement </span>
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>5.4.1 QlM
                                        </td>
                                    <td><P>There is a registered Alumni Association that contributes significantly to the development of the institution through financial and/or other support services Write description in a maximum of 500 words</P></td>
                                    <td><a href="https://fmcet.ac.in/naac/criterion5/5.4.1/5.4.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                           
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <br><br>
                        </div>
                    </div>
                 </div>
            </div>
            <!--criterion 6-->
            <div class="pane" id="tab7" *ngIf="currentTab === 'tab7'">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="faq-item">
                            <br><br>
                            <span class="sub-title">Criterion 6 – Governance, Leadership and Management</span>
                            <span class="sub-title">Key Indicator – 6.1 Institutional Vision and Leadership</span>
                        
            
                            <div class="col-lg-12 col-md-8">
                                <div class="about-content">
                                    <div class="myDashboard-content">
                                        
                                        <div class="recent-orders-table ">
                                            <table class="table" >
                                                <thead>
                                                    <tr>
                                                        <th>Metric No.</th>
                                                        <th>Description</th>
                                                        <th>Link</th>
                                                      
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>6.1.1 QlM
                                                            </td>
                                                        <td><P>The governance and leadership is in accordance with vision and mission of the institution and it is visible in various institutional practices such as decentralization and participation in the institutional governance Write description in a maximum of 500 words</P></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion6/6.1.1/6.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                                 
                                                 
                                              
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
            
                                 </div>
                     </div>
            
                     <span class="sub-title">Key Indicator – 6.2 Strategy Development and Deployment </span>
                        
            
                     <div class="col-lg-12 col-md-8">
                         <div class="about-content">
                             <div class="myDashboard-content">
                                 
                                 <div class="recent-orders-table ">
                                     <table class="table" >
                                         <thead>
                                             <tr>
                                                 <th>Metric No.</th>
                                                 <th>Description</th>
                                                 <th>Link</th>
                                               
                                                 
                                             </tr>
                                         </thead>
                                         <tbody>
                                             <tr>
                                                 <td>6.2.1 QlM
                                                    </td>
                                                 <td><P>The functioning of the institutional bodies is effective and efficient as visible from policies, administrative setup, appointment and service rules, procedures, deployment of institutional Strategic/ perspective/development plan etc Write description in a maximum of 500 words</P></td>
                                                 <td><a href="https://fmcet.ac.in/naac/criterion6/6.2.1/6.2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                               
                                                 </tr>
                                                 <tr>
                                                    <td>6.2.2 QnM
                                                       </td>
                                                    <td><P>Implementation of e-governance in areas of operation 1. Administration 2. Finance and Accounts 3. Student Admission and Support 4. Examination</P></td>
                                                    <td><a href="https://fmcet.ac.in/naac/criterion6/6.2.2/6.2.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                  
                                                    </tr>
                                             
                                          
                                       
                                         </tbody>
                                     </table>
                                 </div>
                             </div>
            
                          </div>
              </div>                   
              
              
              <span class="sub-title">Key Indicator – 6.3 Faculty Empowerment Strategies</span>
                        
            
              <div class="col-lg-12 col-md-8">
                  <div class="about-content">
                      <div class="myDashboard-content">
                          
                          <div class="recent-orders-table ">
                              <table class="table" >
                                  <thead>
                                      <tr>
                                          <th>Metric No.</th>
                                          <th>Description</th>
                                          <th>Link</th>
                                        
                                          
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr>
                                          <td>6.3.1 QlM
                                            </td>
                                          <td><P>The institution has effective welfare measures and Performance Appraisal System for teaching and non-teaching staff Write description in a maximum of 500 words</P></td>
                                          <td><a href="https://fmcet.ac.in/naac/criterion6/6.3.1/6.3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                        
                                          </tr>
                                          <tr>
                                            <td>6.3.2 QnM
                                                </td>
                                            <td><P>Percentage of teachers provided with financial support to attend conferences/workshops and towards membership fee of professional bodies during the last five years 6.3.2.1. Number of teachers provided with financial support to attend conferences/workshops and towards membership fee of professional bodies year wise during the last five years</P></td>
                                            <td><a href="https://fmcet.ac.in/naac/criterion6/6.3.2/6.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                          
                                            </tr>
                                     
                                            <tr>
                                                <td>6.3.3 QnM
                                                    </td>
                                                <td><P>Percentage of teaching and non-teaching staff participating in Faculty development Programmes (FDP), professional development /administrative training programs during the last five years 6.3.3.1.Total number of teaching and non-teaching staff participating in Faculty development Programmes (FDP), professional development /administrative training programs during the last five years 6.3.3.2 Number of non-teaching staff year wise during the last five years</P></td>
                                                <td><a href="https://fmcet.ac.in/naac/criterion6/6.3.3/6.3.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                              
                                                </tr>
                                
                                  </tbody>
                              </table>
                          </div>
                      </div>
            
                   </div>
            </div>                    
            
            <span class="sub-title">Key Indicator – 6.4 Financial Management and Resource Mobilization</span>
                        
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>6.4.1 QlM
                                        </td>
                                    <td><P>Institution has strategies for mobilization and optimal utilization of resources and funds from various sources (government/ nongovernment organizations) and it conducts financial audits regularly (internal and external) Write description in a maximum of 500 words</P></td>
                                    <td><a href="https://fmcet.ac.in/naac/criterion6/6.4.1/6.4.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                             
                             
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            
            <span class="sub-title">Key Indicator – 6.5 Internal Quality Assurance System</span>
                        
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>6.5.1 QlM
                                        </td>
                                    <td><P>Internal Quality Assurance Cell (IQAC) has contributed significantly for institutionalizing the quality assurance strategies and processes. It reviews teaching learning process, structures & methodologies of operations and learning outcomes at periodic intervals and records the incremental improvement in various activities Write description in a maximum of 500 words</P></td>
                                    <td><a href="https://fmcet.ac.in/naac/criterion6/6.5.1/6.5.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                    <tr>
                                        <td>6.5.2 QnM
                                            </td>
                                        <td><P>Quality assurance initiatives of the institution include: 1. Regular meeting of Internal Quality Assurance Cell (IQAC); Feedback collected, analyzed and used for Improvements 2. Collaborative quality initiatives with other institution(s)/membership of international networks 3. Participation in NIRF 4. any other quality audit/accreditation recognized by state, national or international agencies such as NAAC, NBA,ISO Certification etc</P></td>
                                        <td><a href="https://fmcet.ac.in/naac/criterion6/6.5.2/6.5.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                      
                                        </tr>
                                 
                             
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <br><br>
                        </div>
                    </div>
                 </div>
            </div>
            <!--criterion 7-->
            <div class="pane" id="tab8" *ngIf="currentTab === 'tab8'">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="faq-item">
                            <br><br>
                            <span class="sub-title">Criterion 7 – Institutional Values and Best Practices </span>
                            <span class="sub-title">Key Indicator – 7.1 Institutional Values and Social Responsibilities</span>
                        
            
                            <div class="col-lg-12 col-md-8">
                                <div class="about-content">
                                    <div class="myDashboard-content">
                                        
                                        <div class="recent-orders-table ">
                                            <table class="table" >
                                                <thead>
                                                    <tr>
                                                        <th>Metric No.</th>
                                                        <th>Description</th>
                                                        <th>Link</th>
                                                      
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>7.1.1 QlM</td>
                                                        <td><p>Institution has initiated the Gender Audit and measures for the promotion of gender equity during the last five years. Describe the gender equity & sensitization in curricular and co-curricular activities, facilities for women on campus etc. (Within 500 words)</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion7/7.1.1/7.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                                    <tr>
                                                        <td>7.1.2 QnM</td>
                                                        <td><p>The Institution has facilities and initiatives for 1. Alternate sources of energy and energy conservation Measures 2. Management of the various types of degradable and non-degradable waste 3. Water conservation 4. Green campus initiatives 5. Disabled-friendly, barrier free environment</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion7/7.1.2/7.1.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                     
                                                    </tr>
                                                    <tr>
                                                        <td>7.1.3 QnM</td>
                                                        <td><p>Quality audits on environment and energy regularly undertaken by the Institution. The institutional environment and energy initiatives are confirmed through the following 1. Green audit / Environment audit 2. Energy audit 3. Clean and green campus initiatives 4. Beyond the campus environmental promotion activities</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion7/7.1.3/7.1.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>7.1.4 QlM</td>
                                                        <td><p>Describe the Institutional efforts/initiatives in providing an inclusive environment i.e., tolerance and harmony towards cultural, regional, linguistic, communal socioeconomic and Sensitization of students and employees to the constitutional obligations: values, rights, duties and responsibilities of citizens (Within 500 words) Write description in a maximum of 500 words</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/criterion7/7.1.4/7.1.4_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                        
                                                    </tr>
                                              
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
            
                                 </div>
                     </div>
         <br><br>
                        </div>
                    </div>
                 </div>
            </div>
            <!--DVV-->
            <div class="pane" id="tab9" *ngIf="currentTab === 'tab9'">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="faq-item">
                            <br><br>
                            <span class="sub-title">NAAC - DVV Clarification</span>
                            <span class="sub-title">Extended profile
                            </span>
                        
            
                            <div class="col-lg-12 col-md-8">
                                <div class="about-content">
                                    <div class="myDashboard-content">
                                        
                                        <div class="recent-orders-table ">
                                            <table class="table" >
                                                <thead>
                                                    <tr>
                                                        <th>Metric No.</th>
                                                        <th>Description</th>
                                                        <th>Link</th>
                                                      
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1.1</td>
                                                        <td><p>Number of students year wise during the last five years</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/dvv/1.1/1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                                    <tr>
                                                        <td>2.1</td>
                                                        <td><p>Number of full time teachers during the last five years (Without repeat count)</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/dvv/2.1/2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                     
                                                    </tr>
                                                    <tr>
                                                        <td>2.2</td>
                                                        <td><p>Number of teaching staff / full time teachers year wise during the last five years</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/dvv/2.2/2.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <td>3.1</td>
                                                        <td><p>Expenditure excluding salary component year wise during the last five years (INR in lakhs)</p></td>
                                                        <td><a href="https://fmcet.ac.in/naac/dvv/3.1/3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                        
                                                    </tr>
                                              
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
            
                                 </div>
                     </div>
            
            
                     <span class="sub-title">Criterion 1 - Curricular Aspects            </span>
            
                <span class="sub-title">Key Indicator – 1.2 Academic Flexibility</span>
            
                    <div class="col-lg-12 col-md-8">
                        <div class="about-content">
                            <div class="myDashboard-content">
                                
                                <div class="recent-orders-table ">
                                    <table class="table" >
                                        <thead>
                                            <tr>
                                                <th>Metric No.</th>
                                                <th>Description</th>
                                                <th>Link</th>
                                              
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1.2.1</td>
                                                <td><p>Number of Certificate/Value added courses offered and online courses of MOOCs, SWAYAM, NPTEL etc. (where the students of the institution have enrolled and successfully completed during the last five years)<p></p></td>
                                                <td><a href="https://fmcet.ac.in/naac/dvv/1.2.1/1.2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                              
                                                </tr>
                                            <tr>
                                                <td>1.2.2</td>
                                                <td><p>Percentage of students enrolled in Certificate/ Value added courses and also completed online courses of MOOCs, SWAYAM, NPTEL etc. as against the total number of students during the last five years.
                                                    1.2.2.1. Number of students enrolled in Certificate/ Value added courses and also completed online courses of MOOCs, SWAYAM, NPTEL etc. as against the total number of students during the last five years.</p></td>
                                                <td><a href="https://fmcet.ac.in/naac/dvv/1.2.2/1.2.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                             
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
            
                         </div>
             </div>
             <span class="sub-title">Key Indicator – 1.3 Curriculum Enrichment
            </span>
            
            
            <div class="col-lg-12 col-md-8">
                <div class="about-content">
                    <div class="myDashboard-content">
                        
                        <div class="recent-orders-table ">
                            <table class="table" >
                                <thead>
                                    <tr>
                                        <th>Metric No.</th>
                                        <th>Description</th>
                                        <th>Link</th>
                                      
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1.3.2</td>
                                        <td><p>Percentage of students undertaking project work/field work/ internships (Data for the latest completed academic year)
                                            1.3.2.1. Number of students undertaking project work/field work / internships.</p></td>
                                        <td><a href="https://fmcet.ac.in/naac/dvv/1.3.2/1.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                      
                                        </tr>
                                   
                              
                                </tbody>
                            </table>
                        </div>
                    </div>
            
                 </div>
            </div>
            <span class="sub-title">Key Indicator – 1.4 Feedback System
            </span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.4.1</td>
                                    <td><p>Institution obtains feedback on the academic performance and ambience of the institution from various stakeholders, such as Students, Teachers, Employers, Alumni etc. and action taken report on the feedback is made available on institutional website.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/1.4.1/1.4.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Criterion 2 - Teaching- Learning and Evaluation
            </span>
            <span class="sub-title">Key Indicator – 2.1 Student Enrolment and Profile</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2.1.1</td>
                                    <td><p>Enrolment percentage
                                        2.1.1.1. Number of seats filled year wise during last five years (Only first year admissions to be considered)
                                        2.1.1.2. Number of sanctioned seats year wise during last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/2.1.1/2.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                <tr>
                                    <td>2.1.2</td>
                                    <td><p>Percentage of seats filled against reserved categories (SC, ST, OBC etc.) as per applicable reservation policy for the first year admission during the last five years.
                                        2.1.2.1. Number of actual students admitted from the reserved categories year wise during last five years (Exclusive of supernumerary seats)
                                        2.1.2.2. Number of seats earmarked for reserved category as per GOI/ State Govt rule year wise during the last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/2.1.2/2.1.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                 
                                </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 2.2 Student Teacher Ratio
            </span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2.2.1</td>
                                    <td><p>Student – Full time Teacher Ratio (Data for the latest completed academic year)</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/2.2.1/2.2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 2.4 Teacher Profile and Quality
            </span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2.4.1</td>
                                    <td><p>Percentage of full-time teachers against sanctioned posts during the last five years.
                                        2.4.1.1. Number of sanctioned posts year wise during the last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/2.4.1/2.4.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                <tr>
                                    <td>2.4.2</td>
                                    <td><p>Percentage of full time teachers with NET/SET/SLET/ Ph. D./D.Sc. / D.Litt./L.L.D. during the last five years (consider only highest degree for count)
                                        2.4.2.1. Number of full time teachers with NET/SET/SLET/Ph. D./ D.Sc. / D.Litt./L.L.D year wise during the last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/2.4.2/2.4.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                 
                                </tr>
                              
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 2.6 Student Performance and Learning Outcome
            </span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>2.6.3</td>
                                    <td><p>Pass percentage of Students during last five years (excluding backlog students)
                                        2.6.3.1. Number of final year students who passed the university examination year wise during the last five years.
                                        2.6.3.2. Number of final year students who appeared for the university examination year-wise during the last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/2.6.3/2.6.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Criterion 3 – Research, Innovations and Extension
            </span>
            <span class="sub-title">Key Indicator – 3.1 Resource Mobilization for Research</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>3.1.1</td>
                                    <td><p>Grants received from Government and nongovernmental agencies for research projects / endowments in the institution during the last five years (INR in Lakhs)
                                        3.1.1.1. Total Grants from Government and non-governmental agencies for research projects / endowments in the institution during the last five years (INR in Lakhs)</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/3.1.1/3.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 3.2 Innovation Ecosystem
            </span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>3.2.2</td>
                                    <td><p>Number of workshops/seminars/conferences including on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship conducted during the last five years.
                                        3.2.2 Total number of workshops/seminars/conferences including programs conducted on Research Methodology, Intellectual Property Rights (IPR) and entrepreneurship year wise during last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/3.2.2/3.2.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 3.3 Research Publication and Awards
            </span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>3.3.1</td>
                                    <td><p>Number of research papers published per teacher in the Journals notified on UGC care list during the last five years.
                                        3.3.1.1. Number of research papers in the Journals notified on UGC CARE year wise during the last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/3.3.1/3.3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                <tr>
                                    <td>3.3.2</td>
                                    <td><p>Number of books and chapters in edited volumes/books published and papers published in national/ international conference proceedings per teacher during last five years.
                                        3.3.2.1. Total number of books and chapters in edited volumes/books published and papers in national/ international conference proceedings year wise during last five years.	</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/3.3.2/3.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                 
                                </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 3.4 Extension Activities
            </span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>3.4.3</td>
                                    <td><p>Number of extension and outreach programs conducted by the institution through NSS/NCC/Red cross/YRC etc., (including the programmes such as Swachh Bharat, AIDS awareness, Gender issues etc. and/or those organized in collaboration with industry, community and NGOs) during the last five years.
                                        3.4.3.1. Number of extension and outreach Programs conducted in collaboration with industry, community, and Non- Government Organizations through NSS/ NCC/ Red Cross/ YRC etc., year wise during the last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/3.4.3/3.4.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 3.5 Collaboration
            </span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>3.5.1</td>
                                    <td><p>Number of functional MoUs/linkages with institutions/ industries in India and abroad for internship, on-the-job training, project work, student / faculty exchange and collaborative research during the last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/3.5.1/3.5.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                               
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Criterion 4 – Infrastructure and Learning Resources
            </span>
            <span class="sub-title">Key Indicator – 4.1 Physical Facilities</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>4.1.2</td>
                                    <td><p>Percentage of expenditure, excluding salary for infrastructure augmentation during last five years (INR in Lakhs)
                                        4.1.2.1 Expenditure for infrastructure augmentation, excluding salary year wise during last five years (INR in lakhs)</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/4.1.2/4.1.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            
            <span class="sub-title">Key Indicator – Key Indicator – 4.3 IT Infrastructure</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>4.3.2</td>
                                    <td><p>Student–Computer ratio (Data for the latest completed academic year)
                                        4.3.2.1. Number of computers available for students usage during the latest completed academic year.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/4.3.2/4.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 4.4 Maintenance of Campus Infrastructure</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>4.4.1</td>
                                    <td><p>Percentage of expenditure incurred on maintenance of infrastructure (physical and academic support facilities) excluding salary component during the last five years (INR in Lakhs)
                                        4.4.1.1. Expenditure incurred on maintenance of infrastructure (physical facilities and academic support facilities) excluding salary component year wise during the last five years (INR in lakhs)</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/4.4.1/4.4.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Criterion 5 – Student Support and Progression</span>
            <span class="sub-title">Key Indicator – 5.1 Student Support</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>5.1.1</td>
                                    <td><p>Percentage of students benefited by scholarships and freeships provided by the Government and Non-Government agencies during last five years.
                                        5.1.1.1. Number of students benefited by scholarships and freeships provided by the Government and Non-Government agencies year wise during last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/5.1.1/5.1.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                    <tr>
                                    <td>5.1.2</td>
                                    <td><p>Following capacity development and skills enhancement activities are organised for improving students’ capability 1. Soft skills 2. Language and communication skills 3. Life skills (Yoga, physical fitness, health and hygiene) 4. ICT/computing skills</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/5.1.2/5.1.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                    <tr>
                                    <td>5.1.3</td>
                                    <td><p>PePercentage of students benefitted by guidance for competitive examinations and career counseling offered by the Institution during the last five years.
                                        5.1.3.1. Number of students benefitted by guidance for competitive examinations and career counseling offered by the institution year wise during last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/5.1.3/5.1.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                    <tr>
                                    <td>5.1.4</td>
                                    <td><p>The Institution has a transparent mechanism for timely redressal of student grievances including sexual harassment and ragging cases 1. Implementation of guidelines of statutory/regulatory bodies 2. Organization wide awareness and undertakings on policies with zero tolerance 3. Mechanisms for submission of online/offline students’ grievances 4. Timely redressal of the grievances through appropriate committees</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/5.1.4/5.1.4_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 5.2 Student Progression</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>5.2.1</td>
                                    <td><p>Percentage of placement of outgoing students and students progressing to higher education during the last five years.
                                        5.2.1.1. Number of outgoing students placed and / or progressed to higher education year wise during the last five years.
                                        5.2.1.2. Number of outgoing students year wise during the last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/5.2.1/5.2.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                    <tr>
                                        <td>5.2.2</td>
                                        <td><p>Percentage of students qualifying in state/national/ international level examinations during the last five years (eg: JAM/CLAT/GATE/ GMAT/ CAT/ GRE/ TOEFL/ Civil Services/State government examinations)
                                            5.2.2.1. Number of students qualifying in state/ national/ international level examinations (eg: JAM/CLAT/NET/ SLET/ GATE/ GMAT/CAT/GRE/ TOEFL/ Civil Services/ State government examinations) year wise during last five years.</p></td>
                                        <td><a href="https://fmcet.ac.in/naac/dvv/5.2.2/5.2.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                      
                                        </tr>
                                    
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 5.3 Student Participation and Activities</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>5.3.1</td>
                                    <td><p>Number of awards/medals for outstanding performance in sports/ cultural activities at University / state/ national / international level (award for a team event should be counted as one) during the last five years.
                                        5.3.1.1: Number of awards/medals for outstanding performance in sports/cultural activities at University/state/ national / international level (award for a team event should be counted as one) year wise during the last five years</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/5.3.1/5.3.1_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                                    <tr>
                                        <td>5.3.2</td>
                                        <td><p>Average number of sports and cultural programs in which students of the Institution participated during last five years (organized by the institution/other institutions)
                                            5.3.2.1. Number of sports and cultural programs in which students of the Institution participated year wise during last five years.</p></td>
                                        <td><a href="https://fmcet.ac.in/naac/dvv/5.3.2/5.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                      
                                        </tr>
                                    
                              
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Criterion 6 – Governance, Leadership and Management</span>
            <span class="sub-title">Key Indicator – 6.2 Strategy Development and Deployment</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>6.2.2</td>
                                    <td><p>Implementation of e-governance in areas of operation 1. Administration 2. Finance and Accounts 3. Student Admission and Suppor 4. Examination</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/6.2.2/6.2.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 6.3 Faculty Empowerment Strategies</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>6.3.2</td>
                                    <td><p>Percentage of teachers provided with financial support to attend conferences/workshops and towards membership fee of professional bodies during the last five years.
                                        6.3.2.1. Number of teachers provided with financial support to attend conferences/workshops and towards membership fee of professional bodies year wise during the last five years.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/6.3.2/6.3.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                                    <tr>
                                        <td>6.3.3</td>
                                        <td><p>Percentage of teaching and non-teaching staff participating in Faculty development Programmes (FDP), professional development /administrative training programs during the last five years.
                                            6.3.3.1.Total number of teaching and non-teaching staff participating in Faculty development Programmes (FDP), professional development /administrative training programs during the last five years.
                                            6.3.3.2 Number of non-teaching staff year wise during the last five years.</p></td>
                                        <td><a href="https://fmcet.ac.in/naac/dvv/6.3.3/6.3.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                      
                                        </tr>
                                    
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Key Indicator – 6.5 Internal Quality Assurance System</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>6.5.2</td>
                                    <td><p>Quality assurance initiatives of the institution include: 1. Regular meeting of Internal Quality Assurance Cell (IQAC); quality improvement initiatives identified and implemented. 2. Academic and Administrative Audit (AAA) and follow-up action taken. 3. Collaborative quality initiatives with other institution(s). 4. Participation in NIRF and other recognized rankings. 5. Any other quality audit/accreditation recognized by state, national or international agencies such as NAAC, NBA etc.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/6.5.2/6.5.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
            <span class="sub-title">Criterion 7 – Institutional Values and Best Practices</span>
            <span class="sub-title">Key Indicator – 7.1 Institutional Values and Social Responsibilities</span>
            
            
            <div class="col-lg-12 col-md-8">
            <div class="about-content">
                <div class="myDashboard-content">
                    
                    <div class="recent-orders-table ">
                        <table class="table" >
                            <thead>
                                <tr>
                                    <th>Metric No.</th>
                                    <th>Description</th>
                                    <th>Link</th>
                                  
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>7.1.2</td>
                                    <td><p>The Institution has facilities and initiatives for 1. Alternate sources of energy and energy conservation Measures 2. Management of the various types of degradable and non-degradable waste 3. Water conservation 4. Green campus initiatives 5. Disabled-friendly, barrier free environment.</p></td>
                                    <td><a href="https://fmcet.ac.in/naac/dvv/7.1.2/7.1.2_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                  
                                    </tr>
                                    <tr>
                                        <td>7.1.3</td>
                                        <td><p>Quality audits on environment and energy regularly undertaken by the Institution. The institutional environment and energy initiatives are confirmed through the following 1. Green audit / Environment audit 2. Energy audit 3. Clean and green campus initiatives 4. Beyond the campus environmental promotion activities.</p></td>
                                        <td><a href="https://fmcet.ac.in/naac/dvv/7.1.3/7.1.3_main.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                      
                                        </tr>
                                    
                          
                            </tbody>
                        </table>
                    </div>
                </div>
            
             </div>
            </div>
                            <br><br>
                        </div>
                    </div>
                 </div>
            </div>
            <!--Best Practice-->
            <div class="pane" id="tab10" *ngIf="currentTab === 'tab10'">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="faq-item">
                            
                            <div class="col-lg-12 col-md-8">
                                <div class="about-content">
                                    <div class="myDashboard-content">
                                        <br><br>
                                        <span>Best Practices</span>
                                        <div class="recent-orders-table ">
                                            
                                            <table class="table" >
                                               
                                                <thead>
                                                    <tr>
                                                        <th>S No.</th>
                                                        <th>Description</th>
                                                        <th>Link</th>
                                                      
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>Best Practices - 1</td>
                                                        <td><a href="https://fmcet.ac.in/naac/bp/bp1.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Best Practices - 2</td>
                                                        <td><a href="https://fmcet.ac.in/naac/bp/bp2.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                     
                                                    </tr>
                                              
                                                 
                                              
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                 </div>
                     </div>



                        </div>
                    </div>
                 </div>
            </div>
            <!--Institutional D-->
            <div class="pane" id="tab11" *ngIf="currentTab === 'tab11'">
              
            </div>
            <!--ssr-->
       
            
            
            <!--cps -->
            <div class="pane" id="tab14" *ngIf="currentTab === 'tab14'">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="faq-item">
                           
                        </div>
                    </div>
                 </div>
            </div>
            <!--sh -->
            <div class="pane" id="tab15" *ngIf="currentTab === 'tab15'">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="faq-item">
                            <br><br>
                            <span class="sub-title"></span>

                            <br><br>
                        </div>
                    </div>
                 </div>
            </div>
            <!--Annual report -->
            <div class="pane" id="tab16" *ngIf="currentTab === 'tab16'">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="faq-item">
                           <a href="https://fmcet.ac.in/naac/ar/ar.pdf" target="_blank"><br><br><br><br><span>Annual Report</span></a>
                        </div>
                    </div>
                 </div>
            </div>
            
            
            
            
            
                        </div>
             </div>
            </div>
            </section>
            </section>
    </mat-tab>
    
    <mat-tab label="SSR">
        <div class="container">
            <embed src="assets/pdfs/ssr.pdf" width="1080px" height="2100px" />
            </div>
    </mat-tab>
    </mat-tab-group>




