<div
    class="new-comers-area ptb-100 border-top"
    [class.dark-new-comers-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
         
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    
                    <span class="sub-title">CEO's Message</span>
                    <h2>"High aims from high characters and great objectives bring out great minds"</h2>
                    <h6></h6>
                    <p>Every educational institution needs to implement effective learning. Fatima Michael College of Engineering & Technology creates an everlasting image in the mind of students and inspires them to emulate what they have just been exposed to in the classroom. Certainly, our college will unveil the dimension to touch the professional excellence in the most memorable way. In our institution we deeply integrate the social responsibilities with the need of understanding of highly developed technologies and encourage ethical reasoning into learning agenda in a holistic manner. Better understanding of students especially about their motivation which kindles their interest, aptitude, engineering principles, concepts, values and knowledge to built student centric education, process and approaches for enhanced understanding are our great objectives to bring out great minds.</p>
       
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/img/motivation-course/free-trial.png" alt="image">
                    <h3 style="text-align: right;"><span>Dr. S.Bridget Nirmala, M.E., Ph.D.,</span></h3>
                    <p style="text-align: right;">  CEO</p>
                    <div class="wrap-shape">
                        <img src="assets/img/motivation-course/new-comers/shape.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row align-items-center">
         
        
            <div class="col-lg-6 col-md-12">
                <br><br><br>
                <div class="new-comers-image">
                    <img src="assets/img/motivation-course/free-trial1.png" alt="image">
                    <h3 style="text-align: left;"><span>Ln. Mrs. M. Fatima Mary.,</span></h3>
                    <p style="text-align: left;">  SECRETARY
                    </p>
                    <div class="wrap-shape">
                        <img src="assets/img/motivation-course/new-comers/shape.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    
                    <span class="sub-title">SECRETARY'S MESSAGE</span>
                    <h2>""Thinking well is wise Planning well is wiser Doing well is the wisest""</h2>
                    <h6></h6>
                    <p>Fatima Michael College of Engineering & Technology has become a modern temple of learning where quality education is the very essence of its existence. The vision, motto and constructive activities could trigger setting up of this institution into a reputed University. Because just few years from its inception, the college has become a great source of academic strength in the present scenario for which I feel very proud. Our institution follows strategies to manage the closely interconnected corporate world that calls for better knowledge management. I believe that the courses offered in our institution is the wonderful beginning to a challenging set of new learning experience to prove individual's excellence in technical skills and soft skills. FMCET is the wisest choice for excellence in Engineering education.</p>
       
                </div>
            </div>
        </div>
    </div>
<div class="container">
        <br><br>
        <br><br>
        <div class="row ">
         
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    
                    <span class="sub-title">Principal's Message</span>
                    <p>“Education is not the learning of facts, but the training of the mind to think ~Albert 
                        Einstein.”<br>
                        Let's express our gratitude to the Almighty and express our profound gratitude to our founder 
                        Ln.Dr.M.Stalin Arockiaraj for his visionary leadership and the powerful sense of mission that 
                        has guided us in our march towards excellence. Our college aspires to work magic, turning 
                        ordinary students into extraordinary achievers through a transformative educational journey. I 
                        am proud to lead a team of committed teachers who are dedicated to helping students adapt to 
                        the latest global trends. In college, teachers and students work together for a shared goal. It's 
                        not just about academic success but also about motivating students to keep learning, think 
                        critically, and become valuable members of the global community. Engineering is not just a 
                        profession; it's a passion to solve problems, innovate, and create a better world. Keeping all 
                        these in minds, Fatima Michael College of Engineering and Technology is committed to 
                        delivering not just an outstanding academic environment but also guaranteeing an environment 
                        that supports smooth and advanced learning through a favorable infrastructure. </p>

                       <p>  I 
                        welcome students from various places to join the FMCET educational community. Spend four 
                        years acquiring the latest knowledge and evolving into skilled engineers for the future.</p>   
                        <span>Best Wishes
                        <p>Dr.P.Nelson Raja</p>   </span>   
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/img/motivation-course/training-courses.png" alt="image">
                    <h3 style="text-align: right;"><span>Dr. P. Nelson Raja, M.E., Ph.D.,</span></h3>
                    <p style="text-align: right;">  Principal</p>
                    <div class="wrap-shape">
                        <img src="assets/img/motivation-course/new-comers/shape.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
</div>