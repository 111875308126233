<div
    class="mission-area ptb-100"
    [class.dark-mission-area]="themeService.isDark()"
>
    <div class="container">
        <div class="mission-content">
            <div class="section-title text-start">
                
                <h2>About the Department</h2>
            </div>
            <div class="mission-slides">
                <owl-carousel-o [options]="missionSlides">
                    <ng-template carouselSlide>
                        <p> <h3>Training & Placemnt Cell</h3>
                    
                            <p>Training & Placement cell is the top most priority of FMCET and aims at 100% placement. Our placement cell undertakes counseling, training and value additions to ensure that students are ready to get placed in reputed organizations. The cell maintains continuous contact with the Industry's veterans and top officials to provide the best career opportunity to the students. A full day in a week is scheduled to train the students to enrich their technical skills, soft skills, aptitude knowledge, verbal reasoning and comprehensive skills. All efforts are being taken by the Cell to develop a good "Industry-Institute" relation.</p>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>Placements</h3>
                    
                        <ul class="description-features-list">
                            <li>Coordination with industry for direct recruitment of the top ranking students
                            </li>
                            <li>Identifying and inviting industries for campus recruitment
                            </li>
                           <LI>Assessing the skill set requirements
                        </LI>
                          <li>Coordination of campus recruitment activity
                        </li>
                        <li>Reviewing every recruitment process and giving recommendations for subsequent recruitments
                        </li>
                        <li>Maintaining updated students profile
                        </li>
                        </ul>
                    </ng-template>
                    <ng-template carouselSlide>
                        <h3>Training</h3>
                    
                        <ul class="description-features-list">
                            <li>Identifying organization for industrial training and placement activities
    
                            </li>
                            <li>Co-ordination of Industrial Visits, In-plant Training and Project Work
    
                            </li>
                           <LI>Promoting career counseling
    
                        </LI>
                          <li>Organizing personality development programs.
    
                        </li>
                        <li>Facilitating students who attend various symposiums, conferences, workshops, etc.
    
                        </li>
                      
                        </ul>
                        
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>