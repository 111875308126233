<app-header-style-one></app-header-style-one>



<section
    class="experience-area ptb-100 bg-f5faf8"
    [class.dark-experience-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="experience-content">
                    
                    <h2 class="playfair-display">Antiragging Committee</h2>
                    <p>All India Council for Technical Education (AICTE) has been proactive in addressing the issue of ragging in technical institutions and has provided specific guidelines and instructions to prevent and combat ragging. The AICTE has issued comprehensive regulations titled "AICTE Regulations on Curbing the Menace of Ragging in AICTE Approved Technical Institutions, 2009." These regulations provide detailed guidelines and instructions to institutions to prevent, prohibit, and eliminate ragging. Institutions will submit compliance reports regarding antiragging measures to the AICTE every academic year. This includes information about the establishment and functioning of antiragging committees and squads.
                    </p>
                    <p>Institutions are mandated to establish antiragging committees at the institution level. Antiragging squads are also set up to conduct surprise checks and patrols in vulnerable areas.
                    </p>
                       
                       
                    
                </div>
            </div>
      
        </div>
    </div>
    
</section>

<div class="container">
<div class="row align-items-center">
    <div class="col-lg-4 col-md-12">
        <div class="about-image">
            <a href="assets/img/pdf/MOM-Anti Ragging Committee.pdf" target="_blank"><img src="assets/img/pdf/MOM-AntiRagging.jpg" class="shadow" alt="image"></a>
            
        </div>
    </div>

    <div class="col-lg-8 col-md-12">
        <div class="about-content">
           
        
            <div class="myDashboard-content">
                          
               
                <h3>Antiragging Committee</h3>
                <div class="recent-orders-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Designation</th>
                                <th>Post</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Dr. P. Nelson Raja</td>
                                <td>Principal</td>
                                <td>Chairman</td>
                                </tr>
                            <tr>
                                <td>2</td>
                                <td>Dr. M. Subramanaian</td>
                                <td>Dean - Research</td>
                                <td>Member</td>
                               
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Dr. P. Ravikumaran</td>
                                <td>HoD/CSE</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Mr. A. Athimoolam</td>
                                <td>HoD/S&H</td>
                                <td>Member</td>
                                
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Mr. M. Prabhakaran</td>
                                <td>ASP/Mech</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Ms. M.Aishwarya</td>
                                <td>AP/EEE</td>
                                <td>Member</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Mr. V. Palanisamy</td>
                                <td>Parent</td>
                                <td>Member</td>
                                
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Mr. D. Sanjay Devan</td>
                                <td>Student</td>
                                <td>Member</td>
                                
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Mr. M. Sivabalan</td>
                                <td>Student</td>
                                <td>Member</td>
                                
                            </tr>
                            
                            <tr>
                                <td>10</td>
                                <td>Mr. R. Amirtharaj</td>
                                <td>Lab Technician</td>
                                <td>Member</td>
                                
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Mr. V. Saravana Rani</td>
                                <td>Inspector of Police</td>
                                <td>Member</td>
                                
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Mr. A. Vincent Xavier Raj</td>
                                <td>Thasildar</td>
                                <td>Member</td>
                                
                            </tr>
                            <tr>
                                <td>13</td>
                                <td>Mr. V. Joseph Mary</td>
                                <td>NGO</td>
                                <td>Member</td>
                                
                            </tr>
                        </tbody>
                    </table>
                    <br><br>
                </div>
            </div>
      </div>
      
    </div>
</div>
</div>
<!--Feeback Form-->
<div
    class="students-feedback-form-area ptb-100"
    [class.dark-students-feedback-form-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
       
            <div class="col-lg-12 col-md-12">
                <div class="students-feedback-form">
                    <h3>Antiragging Complaint Form</h3>
                    <form>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your name*">
                                    <span class="label-title"><i class='bx bx-user'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Department*">
                                    <span class="label-title"><i class='bx bx-home'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Gender*">
                                    <span class="label-title"><i class='bx bx-envelope'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Year of Study*">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="State your Complaint*">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Date on which Incident happened? *">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea cols="30" rows="5" class="form-control" placeholder="Write something here (Optional)"></textarea>
                                    <span class="label-title"><i class='bx bx-edit'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                              <p>I have rechecked the form and confirm that all the details are correct. I know if information is wrong I am liable for a punishable action.</p>
                                <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
