<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner item-bg1">
               
                
                  
              
              
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg4">
               
                
                  
              
              
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg2">
                      
                <div class="row align-items-center">
                           
                    <div class="col-lg-3 col-md-12">
                        <div class="business-banner-image">
                          <!--  <img src="assets/img/business-coaching/man.png" alt="image">-->
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-12">
                        <div class="business-banner-image">
                            
                        </div>
                    </div>
                </div> 
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg3">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                   
                            <!--<div class="main-banner-content text-center">
                                <span class="sub-title">Aim for Excellence</span>
                                <h1>Learn a new skill from online courses</h1>
                                <p>Ednuv supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                                <div class="btn-box">
                                    <a routerLink="/courses-2-columns-style-3" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a routerLink="/contact" class="optional-btn">Get Started Free</a>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>