<app-header-style-two></app-header-style-two>
<br><br><br><br><br><br>
<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="STAKEHOLDERS FEEDBACK">
        <div class="my-dashboard-area ptb-100">
    
       
            <div class="container">
                
                <div class="col-lg-12 col-md-8">
                   
                    <div class="about-content">
                        <div class="myDashboard-content">
                            
                            <div class="recent-orders-table ">
                                <table class="table" >
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Description</th>
                                            <th>Link</th>
                                          
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td><p>Stake Holder Feedback Policy and Process</p></td>
                                            <td><a href="https://fmcet.ac.in/naac/sf/1.4.1_feedbackpolicy.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                          
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td><p>Questionnaire</p></td>
                                                <td><a href="https://fmcet.ac.in/naac/sf/1.4.1_unfilledform.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                              
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td><p>Students Feedback</p></td>
                                                    <td><a href="https://docs.google.com/forms/d/e/1FAIpQLScFLsR15TnqLP2AsYWNNtXrJPmPigp5q5hvEGttXhI_ddIiPQ/viewform" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                  
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td><p>Teachers Feedback</p></td>
                                                        <td><a href="https://docs.google.com/forms/d/e/1FAIpQLScTD8wdoWJmC99VOmTTFOSXAiHpYJk_9RQ9zGzxbblFG7EkBQ/viewform" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                      
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td><p>Alumni Feedback</p></td>
                                                            <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSehmrmwvgOYuLbVgexioWYs5cbTupP7kRQfCp98vJGd8-9imQ/viewform" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                          
                                                            </tr>
                                                            <tr>
                                                                <td>6</td>
                                                                <td><p>Employers Feedback</p></td>
                                                                <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSee59P5Avcqq0h_OJHzo0k0zSAYpgHw4NkcosVna6YVAeekGQ/viewform" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                              
                                                                </tr>
                                                                <tr>
                                                                    <td>7</td>
                                                                    <td><p>Action Taken Report on Feedback</p></td>
                                                                    <td><a href="https://fmcet.ac.in/naac/sf/1.4.1_actiontakenreport.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                  
                                                                    </tr><tr>
                                                                        <td>8</td>
                                                                        <td><p>Evidences for Action Taken Report on Feedback</p></td>
                                                                        <td><a href="https://fmcet.ac.in/naac/sf/1.4.1_evidenceforactiontaken.pdf" target="_blank"><i class=''></i> <span>View  </span></a></td>
                                                                      
                                                                        </tr>               
                                  
                                    </tbody>
                                </table>
                            </div>
                        </div>
                
                     </div>
                </div>
        
        
            </div>
        </div>
    </mat-tab>
   
    </mat-tab-group>

        


