<!-- Header -->
<header class="header-area p-relative">

    <!-- Top Header -->
    <div class="top-header top-header-style-four">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <ul class="top-header-contact-info">
                        <li>
                            <img src="assets/img/black-logo3.png" alt="logo">
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>Follow us:</span>
                        <a href="https://www.facebook.com/FatimaMichaelEngineeringCollegeMadurai" target="_blank"><i class='bx bxl-facebook'></i></a>
                        
                        <a href="https://www.instagram.com/fmcet_mdu/?igshid=MzNlNGNkZWQ4Mg%3D%3D" target="_blank"><i class='bx bxl-instagram'></i></a>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul class="top-header-login-register">
                        
                        <li><a routerLink="/contact"><i class='bx bx-log-in'></i> Contact</a></li>
                        <li><a routerLink="/gallery"><i class='bx bx-log-in-circle'></i> Gallery</a></li>
                        <li><a href="https://fmcetsaas.in" target="_blank"><i class='bx bx-log-in-circle'></i> ERP Login</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Navbar -->
    <div
        class="navbar-area navbar-style-three"
        [ngClass]="{'sticky': isSticky}"
        [class.dark-navbar-area]="themeService.isDark()"
    >
        <div class="container-fluid">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a
                    class="navbar-brand"
                    routerLink="/"
                    [class.d-none]="themeService.isDark()"
                >
                
                </a>
                <a
                    class="navbar-brand d-none"
                    routerLink="/"
                    [class.d-block]="themeService.isDark()"
                >
              
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a routerLink="/aboutus" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Academics <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                              <li class="nav-item"><a routerLink="/programme" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Programme Offered</a></li>
                                
                              <li class="nav-item"><a href="#" class="nav-link">Faculty <i class='bx bx-chevron-right'></i></a>
                                       
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/teachingfaculty" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Teaching</a></li>   
                                    <li class="nav-item"><a routerLink="/nonteachingfaculty" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Non-Teaching</a></li>   
                                      
                                    
                                    </ul>
                                </li>
                                
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Committees <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                              <li class="nav-item"><a routerLink="/antiragging" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Antiragging</a></li>
                                <li class="nav-item"><a routerLink="/grc" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Grievance Redressal Committee</a></li>
                                <li class="nav-item"><a routerLink="/icc" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Internal Complaint Committee</a></li>
                                <li class="nav-item"><a routerLink="/scst" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SC / ST Committee</a></li>
                                
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Departments <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                              
                                <li class="nav-item"><a routerLink="/auto" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Automobile Engineering</a></li>
                                <li class="nav-item"><a routerLink="/civil" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Civil Engineering</a></li>
                                <li class="nav-item"><a routerLink="/cse" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Computer Science & Engineering</a></li>
                                <li class="nav-item"><a routerLink="/ai" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">CSE (Artificial Intelligence & Machine Learning)</a></li>
                                <li class="nav-item"><a routerLink="/cs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">CSE (Cyber Security)</a></li>
                                <li class="nav-item"><a routerLink="/ece" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Electronics & Communication Engineering</a></li>
                                <li class="nav-item"><a routerLink="/eee" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Electrical & Electronics Engineering</a></li>
                                <li class="nav-item"><a routerLink="/mech" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Mechanical Engineering</a></li>
                                <li class="nav-item"><a routerLink="/sh" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Science & Humanities Department</a></li>
                                <li class="nav-item"><a routerLink="/mba" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Business Administration</a></li>
                                
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Facilities <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                              <li class="nav-item"><a routerLink="/campuslife" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Campus Life</a></li>
                                <li class="nav-item"><a routerLink="/cell" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cell</a></li>
                                <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Payment</a></li>
                                
                            </ul>
                        </li>
                         <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Mandatory <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                              
                                <li class="nav-item"><a href="assets/img/pdf/AICTE.pdf" target="_blank" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">AICTE Approvals</a></li>
                                <li class="nav-item"><a href="https://www.aicte-india.org/feedback/index.php" target="_blank" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">AICTE Feedback</a></li>
                                <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">AICTE Grievance Cell</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">NAAC <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/IQAC" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">IQAC</a></li>
                                <li class="nav-item"><a routerLink="/naac" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">NAAC</a></li>
                                <li class="nav-item"><a routerLink="/naac-criterion" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">NAAC Criterion</a></li>
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">IIQA</a></li>
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SSR</a></li>
                                <li class="nav-item"><a routerLink="/RTI" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">RTI</a></li>
                            </ul>
                        </li>
                       
                        
                        <li class="nav-item"><a routerLink="/placement" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Placement</a></li>
                        
                        <li class="nav-item"><a routerLink="/IIC" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">IIC</a></li>
                       
                    </ul>
                </div>
                <div class="others-option">
                    <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <img src="assets/img/cclogo.png" alt="image">
                                 </div>
               </div>
            </nav>
        </div>
    </div>
    
</header>

<!-- Search Overlay -->
<div
    class="search-overlay"
    [class.active]="classApplied3"
    [class.dark-search-overlay]="themeService.isDark()"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>