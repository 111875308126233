<app-header-style-one></app-header-style-one>



<section class="courses-area ptb-100">
     <div class="user-actions">
        <i class='bx bx-log-in'></i>
        <span>Undergraduate Programme  </span>
        
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/auto" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
                       
                 <p>  <a routerLink="/auto" class="d-inline-block">B.E Automobile Engineering</a></p>
                       
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 30 Intake
                            </li>
                          
                        </ul>
                    </div>

                 
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/civil" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
                 
                  <p><a routerLink="/civil" class="d-inline-block">B.E Civil Engineering</a></p>
                      
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 60 Intake
                            </li>
                          
                        </ul>
                    </div>

                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/cse" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
               
                        <p><a routerLink="/cse" class="d-inline-block">B.E Computer Science and Engineering</a></p>
                        
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 60 Intake
                            </li>
                          
                        </ul>
                    </div>
                
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/ece" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
                    
                       <p> <a routerLink="/ece" class="d-inline-block">B.E Electronics and Communication Engineering</a></p>
                        
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 60 Intake
                            </li>
                          
                        </ul>
                    </div>

                   
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/eee" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
                    
                       <p><a routerLink="/eee" class="d-inline-block">B.E Electrical and Electronics Engineering</a></p>
                        
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 30  Intake
                            </li>
                          
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/ai" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
       
                     <p>   <a routerLink="/ai" class="d-inline-block">B.E. Computer Science and Engineering (Artificial Intelligence & Machine Learning)</a></p>
                        
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 30 Intake
                            </li>
                          
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/cs" class="d-block"><img src="assets/img/courses/courses7.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
      
                        <p><a routerLink="/cs" class="d-inline-block">B.E. Computer Science and Engineering (Cyber Security)</a></p>
                      
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 30 Intake
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-courses-item mb-30">
                    <div class="courses-image">
                        <a routerLink="/mech" class="d-block"><img src="assets/img/courses/courses8.jpg" alt="image"></a>
                    </div>

                    <div class="courses-content">
          
                        <p><a routerLink="/mech" class="d-inline-block">B.E Mechanical Engineering</a></p>
                        
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 30 Intake
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>
         
         
        </div>
        
    </div>
    
    
</section>
<div class="user-actions">
    <i class='bx bx-log-in'></i>
    <span>Postgraduate Programme  </span>
    
</div>
<section >
    
   <div class="container-fluid">
       <div class="row">
           <div class="col-lg-3 col-md-6">
               <div class="single-courses-item mb-30">
                   <div class="courses-image">
                       <a routerLink="/thermal" class="d-block"><img src="assets/img/courses/courses10.jpg" alt="image"></a>
                   </div>

                   <div class="courses-content">
                      
                <p>  <a routerLink="/thermal" class="d-inline-block">M.E Thermal Engineering</a></p>
                      
                   </div>
                   <div class="courses-box-footer">
                       <ul>
                           <li class="students-number">
                               <i class='bx bx-user'></i> 18 Intake
                           </li>
                         
                       </ul>
                   </div>

                
               </div>
           </div>

           <div class="col-lg-3 col-md-6">
               <div class="single-courses-item mb-30">
                   <div class="courses-image">
                       <a routerLink="/mecse" class="d-block"><img src="assets/img/courses/courses9.jpg" alt="image"></a>
                   </div>

                   <div class="courses-content">
                
                 <p><a routerLink="/mecse" class="d-inline-block">M.E Computer Science Engineering</a></p>
                     
                   </div>
                   <div class="courses-box-footer">
                       <ul>
                           <li class="students-number">
                               <i class='bx bx-user'></i> 18 Intake
                           </li>
                         
                       </ul>
                   </div>

               </div>
           </div>

           <div class="col-lg-3 col-md-6">
               <div class="single-courses-item mb-30">
                   <div class="courses-image">
                       <a routerLink="/embedded" class="d-block"><img src="assets/img/courses/courses11.jpg" alt="image"></a>
                   </div>

                   <div class="courses-content">
              
                       <p><a routerLink="/" class="d-inline-block">M.E Embedded System Technologies</a></p>
                       
                   </div>
                   <div class="courses-box-footer">
                       <ul>
                           <li class="students-number">
                               <i class='bx bx-user'></i> 18 Intake
                           </li>
                         
                       </ul>
                   </div>
               
               </div>
           </div>

           <div class="col-lg-3 col-md-6">
               <div class="single-courses-item mb-30">
                   <div class="courses-image">
                       <a routerLink="/mba" class="d-block"><img src="assets/img/courses/courses12.jpg" alt="image"></a>
                   </div>

                   <div class="courses-content">
                   
                      <p> <a routerLink="/mba" class="d-inline-block">MBA Master of Business Administration</a></p>
                       
                   </div>
                   <div class="courses-box-footer">
                       <ul>
                           <li class="students-number">
                               <i class='bx bx-user'></i> 60 Intake
                           </li>
                         
                       </ul>
                   </div>

                  
               </div>
           </div>

         

        
       </div>
       
   </div>
   
   
</section>
<div class="user-actions">
    <i class='bx bx-log-in'></i>
    <span>Ph.D Programme  </span>
    
</div>
<section >
    
   <div class="container-fluid">
       <div class="row">
           <div class="col-lg-3 col-md-6">
               <div class="single-courses-item mb-30">
                   <div class="courses-image">
                       <a routerLink="/phd" class="d-block"><img src="assets/img/courses/courses13.jpg" alt="image"></a>
                   </div>

                   <div class="courses-content">
                      
                <p>  <a routerLink="/" class="d-inline-block">Ph.D - Mechanical Engineering</a></p>
                      
                   </div>
                 

                
               </div>
           </div>




        
       </div>
       
   </div>
   
   
</section>