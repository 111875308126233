<app-header-style-one></app-header-style-one>


  
 <mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Electrical & Electronics Engineering</h2>
                </div>
            </div>
        </div>
        
    </mat-tab>
   <mat-tab label="About the Department">
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>
    <section class="courses-details-area pt-100 pb-70">
        <div class="container">
           <div class="row">
                <div class="col-lg-6">
                    <div class="courses-sidebar-syllabus">
                    <h3>About the Department</h3>                   
                        <p>The department of EEE was established in the year 2011 with an intake of 60 students
                            approved by AICTE. The department accommodate Outcome Based Education which help
                            the students to learn, develop and serve to the society. <br>The department has experienced and
                            dedicated faculty with a wide range of specialization Power System, Power Electronics,
                            Embedded Systems. The department has well established laboratory facilities to conduct
                            research work on different specialized areas like Renewable Energy, Power Quality and
                            Power Electronics. <br>The students of the department have received external research funding
                            from Tamil Nadu State Council for Science and Technology in recent years. The Students of
                            the departments have joined in reputed industries through placements and some of them
                            turned to be entrepreneur. The department has a good network of alumni.</p>
                            <p>Training and Placement Cell is an integral part of our department. The cell is
                                sensitized to function all through the year towards generating training and placement
                                opportunities for the students. Training activities are organized throughout the year in an
                                effort towards preparing the prospective students for the campus selection programmes. It
                                also maintains a constant contact with its students through the coordinators and sends regular
                                mails/notification about the companies that would be visiting thereby encouraging them to
                                apply for the recruitment procedure.</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <br><br><br>
                    <div class="courses-details-image text-center">
                        <img src="assets/img/courses/courses5.jpg" alt="image">
                    </div>  
                </div>
            </div>
    
         </div>
    </section>  

   </mat-tab> 
   <mat-tab label="Vision & Mission">
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>
    <section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
            <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>The Department of Electrical and Elecrtonics Engineering is providing programs of the highest
                        quality to produce globally competent technocrats who can address challenges of the
                        millennium to achieve sustainable socio-economic development.</p> 
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
                        To create graduates possessing sound fundamental knowledge of Electrical
                        Engineering <br>
                        To provide technocrats for industry<br>
                        To pursue research in Electrical & Electronics Engineering and across disciplines<br>
                       
                        </p>
                    
                </div>
            </div>
            
      
        </div>
    </div>
  
</section>

   </mat-tab> 
   
   <mat-tab label="PEOs">
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>

    <section class="courses-details-area pt-100 pb-70">
        <div class="user-actions">
    <span >PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span> <p>Graduates can</p>
<ul>
    <li>Find employment in Core Electrical and Electronics Engineering and service
    sectors.</li>
    <li>Get elevated to technical lead position and lead the organization competitively.</li>
    <li>Enter into higher studies leading to post-graduate and research
    degrees.</li>
    <li>Become consultant and provide solutions to the practical problems of core
    organization. Become an entrepreneur and be part of electrical and electronics
    product and service industries.</li>
</ul>
</div>
</section>
</mat-tab> 
   <mat-tab label="POs & PSOs">
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>
    <section class="courses-details-area pt-100 pb-70">
        <div class="user-actions">
    <span >PROGRAM OUTCOMES (POs)</span> <p>Graduates will be able to </p>
<ul style="text-align: justify;">
    <span>Engineering knowledge:</span> Apply the knowledge of mathematics, science, engineering
    fundamentals, and an engineering specialization to the solution of complex engineering
    problems.<br><br>
    <span>Problem analysis:</span> Identify, formulate, review research literature, and analyze complex
    Engineering problems reaching substantiated conclusions using first principles of
    mathematics, natural sciences, and engineering sciences.<br><br>
    <span>Design/development of solutions:</span> Design solutions for complex engineering problems
    and design system components or processes that meet the specified needs with appropriate
    consideration for the public health and safety, and the cultural, societal, and
    Environmental considerations.<br><br>
    <span>Conduct investigations of complex problems:</span> Use research-based knowledge
    and research methods including design of experiments, analysis and interpretation of
    data, and synthesis of the information to provide valid conclusions.<br><br>
    <span>Modern tool usage:</span> Create, select, and apply appropriate techniques, resources, and
    modern engineering and IT tools including prediction and modeling to complex
    Engineering activities with an understanding of the limitations.<br><br>
    <span>The engineer and society:</span> Apply reasoning in formed by the contextual knowledge
    To assess societal, health, safety, legal and cultural issues and the consequent
    responsibilities relevant to the professional engineering practice.<br><br>
    <span>Environment and sustainability:</span> Understand the impact of the professional engineering
    solutions in societal and environmental contexts, and demonstrate the knowledge of, and
    need for sustainable development.<br><br>
    <span>Ethics:</span> Apply ethical principles and commit to professional ethics and responsibilities and
    norms of the engineering practice.<br><br>
    <span>Individual and teamwork:</span> Function effectively as an individual, and as a member
    Or leader in diverse teams, and in multidisciplinary settings.<br><br>
    <span>Communication:</span> Communicate effectively on complex engineering activities with the
    engineering community and with society at large, such as, being able to comprehend and
    write effective reports and design documentation, make effective presentations, and give
    And receive clear instructions.<br><br>
    <span>Project management and finance:</span> Demonstrate knowledge and understanding of the
    engineering and management principles and apply these to one’s own work, as a member
    And leader in a team, to manage projects and in multi disciplinary environments.<br><br>
    <span>Life-long learning:</span> Recognize the need for, and have the preparation and ability to
    engage in independent and life-long learning in the broadest context of technological
    change.
</ul>
<br><br>
<span>PROGRAM SPECIFIC OUTCOMES (PSOs)</span>

<p>On completion of Electrical and Electronics Engineering program, the student will have the
following Program Specific Outcomes.</p>
<ul style="text-align: justify;">
<li><span>Foundation of Electrical Engineering:</span> Ability to understand the principles
and working of electrical components, circuits, systems and control that are
forming a part of power
generation,transmission,distribution,utilization,conservationand energy
saving. Students can assess the power management, auditing, crisis and
energy saving aspects.</li><br><br>
<li><span>Foundation of Mathematical Concepts:</span> Ability to apply mathematical
methodologies to solve problems related with electrical engineering using
appropriate engineering tools and algorithms.</li><br><br>
<li><span>Computing and Research Ability:</span>Ability to use knowledge in various
domains to identify research gaps and hence to provide solution which leads
to new ideas and innovations.</li><br><br>
</ul>
</div>
</section>

   </mat-tab> 
   <mat-tab label="Programme Offered">
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>
    <div class="page-title-area item-bg15 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Electrical & Electronics Engineering</h2>
            </div>
        </div>
    </div>
    <br><br><br>
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Programme Offered </span>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">S.NO</th>
                        <th scope="col">Programme Name</th>
                        <th scope="col">Years</th>
                     
                       
                    </tr>
                </thead>
                <tbody>
                    
                    <tr>
                        <td class="product-name">
                            <a >1.</a>
                        </td>
                        <td class="product-name">
                            <a routerLink="/eee">B.E. Electrical & Electronics Engineering</a>
                        </td>
                        <td class="product-total">
                            <span class="subtotal-amount">4 Years</span>
                        </td>
                        
                      </tr>
                      <tr>
                        <td class="product-name">
                            <a >1.</a>
                        </td>
                        <td class="product-name">
                            <a routerLink="/">M.E. Embedded System Technologies</a>
                        </td>
                        <td class="product-total">
                            <span class="subtotal-amount">2 Years</span>
                        </td>
                        
                      </tr>
                
                      </tbody>
                      </table>
                
            </div>
        </div>
     
   </mat-tab> 
 </mat-tab-group> 
 <br> 
  
   
<section class="courses-details-area pt-100 pb-70">
    <div class="container">
       <div class="row">
        <div class="col-lg-6">
            <div class="courses-details-image text-center">
                <img src="assets/img/courses/courses17.jpg" alt="image">
            </div>  
        </div>
            <div class="col-lg-6">
                <div class="courses-sidebar-syllabus">
                    <br><br><br><br><br>
                    <h3>Association Activities</h3>
              
                    <p>The students are trained to develop their communication skills and soft skills. The
                        association actively conducts various inter department programs like Paper presentations,
                        Seminars and Workshops. Motivation programs are conducted to inculcate self-confidence
                        and to groom them as a confident individual. The faculty hones their skills by participating
                        and presenting papers in various International/National Level Seminars/Symposia/ Workshop
                        organized by technical societies like ISTE.
                    </p>
                </div>
            </div>
         
        </div>

     </div>
</section>
<section
    class="why-choose-us-area bg-e6fbff"
    [class.dark-why-choose-us-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/test1.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose </span>
                        <h2> Electrical Engineering</h2>
                        <p> EEE is all about learning and making device and equipments that uses electricity and electronics. The students of EEE get an opportunity to contribute to the growth of technology and the course itself facilitates new research ideas. It helps them develop an entrepreneurial mindset. There are many reasons why many students prefer Electrical and Electronics Engineering and make it their career. </p>
                        
                        <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/img/lang-shape2.png" alt="image"></div>
</section>


<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/courses/courses18.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div >
                    
                   
                        <h3>Laboratory Facilities</h3>
                        <p>The department is well equipped curriculum laboratories like electrical machines lab,
                            power electronics lab, microprocessor and microcontrollers lab, power system simulation lab,
                            control systems lab, measurements and instruments lab, electric circuits lab, Electronics
                            circuits lab, object oriented programming lab, embedded system Lab etc. The department is
                            equipped with individual computer lab supported by higher end software like AU Power,
                            MATLAB and many other simulation softwares. In all, the laboratories in addition to the
                            experiments prescribed by the Anna University, minimum of two experiments are conducted
                            for coping up with recent developments in the field of electrical engineering field.
                       </p>
                        <div class="courses-details-desc">
                            <ul class="description-features-list">
                                <li>Engineering Practices Lab                       </li>
                                <li>Electric Circuits Lab                      </li>
                                <li>Electrical Machines Lab                  
                                </li>
                                <li>Control System Lab</li>
                                <li>Measurement and Instrumentation Lab</li>
                                <li>Power Electronics Lab</li>
                                <li>Power System Simulation Lab</li>
                                <li>Renewable Energy System Lab</li>
                            </ul>
                        </div>
                  
                </div>
            </div>
        </div>
    </div>
</section>



