<section style="background-color: #fdfdfd;">
    <div class="section-title">
           <br><br>
  <h2>Our Recruiters</h2>
        
    </div>
<div 
    class="motivation-featured-area bg-with-black-color"
    [class.dark-motivation-featured-area]="themeService.isDark()"
>
    <div class="container-fluid" >
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                   
                    <img src="../../../../../assets/img/placement/1.png" >
                    
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/2.png" >
                    
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                   
                    <img src="../../../../../assets/img/placement/3.png" > 
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/4.png" > 
                    
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/5.png" > 
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/6.png" > 
                    
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/7.png" > 
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/8.png" > 
                </div>
            </div>    
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/9.png" > 
                </div>
            </div>
      
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/10.png" > 
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/11.png" > 
                </div>
            </div>
      
            <div class="col-lg-3 col-sm-6">
                <div class="motivation-featured-item">
                    <img src="../../../../../assets/img/placement/12.png" > 
                </div>
            </div>
      
      
   
        </div>
    </div>
</div>
</section>