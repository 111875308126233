<app-header-style-one></app-header-style-one>


<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label=" 2016 - 2018 "> 
        <div class="container col-md-12 pt-100 pb-70">
            <h1>Freshers Day</h1>
            <section class="gallery-area pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/4.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/7.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/6.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/9.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/11.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/11.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/12.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/12.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday16/20.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday16/20.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday18/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday18/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday18/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday18/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday18/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday18/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday18/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday18/4.jpg.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday18/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday18/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday18/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday18/6.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday18/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday18/7.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday18/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday18/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday18/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday18/9.jpg">
                            </div>
                        </div>
            
                       
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday2017/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday2017/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday2017/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday2017/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday2017/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday2017/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday2017/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday2017/4.jpg.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday2017/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday2017/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday2017/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday2017/6.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday2017/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday2017/7.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday2017/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday2017/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/freshersday2017/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/freshersday2017/9.jpg">
                            </div>
                        </div>
            
                                                
                    </div>
                </div>
            </section>
        </div>    
        <div class="container col-md-12 pt-100 pb-70">
            <h1>Annual Day 2016</h1>
            <section class="gallery-area pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/4.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/7.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/6.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/9.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/11.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/11.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/12.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/12.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday16/20.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday16/20.jpg">
                            </div>
                        </div>
            
                    
                    </div>
                </div>
            </section>
        </div>  
        <div class="container col-md-12 pt-100 pb-70">
            <h1>Annual Day 2017</h1>
            <section class="gallery-area pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/4.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/7.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/6.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/9.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/11.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/11.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/12.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/12.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/annualday17/20.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/20.jpg">
                            </div>
                        </div>
            
                    
                    </div>
                </div>
            </section>
        </div>  
        <div class="container col-md-12 pt-100 pb-70">
            <h1>Alumni Meet</h1>
            <section class="gallery-area pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/4.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/annualday17/7.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/6.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/9.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/11.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/11.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/12.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/12.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/20.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/20.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/13.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/13.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/14.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/14.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/alumnimeet17/15.jpg" alt="Gallery Image" data-original="assets/img/gallery/alumnimeet17/15.jpg">
                            </div>
                        </div>
            
                    
                    </div>
                </div>
            </section>
        </div>  
        <div class="container col-md-12 pt-100 pb-70">
            <h1>Graduation Day</h1>
              
            <section class="gallery-area pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/4.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/7.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/6.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/9.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/11.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/11.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/12.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/12.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/graduationday18/20.jpg" alt="Gallery Image" data-original="assets/img/gallery/graduationday18/20.jpg">
                            </div>
                        </div>
            
                    
                    </div>
                </div>
            </section>
        </div>  
        <div class="container col-md-12 pt-100 pb-70">
            <h1>Siver Jubliee Day</h1>
            <section class="gallery-area pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/4.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/7.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/6.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/9.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/11.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/11.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/12.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/12.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday1/20.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday1/20.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/4.jpg.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/6.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/7.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/9.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/11.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/11.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/12.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/12.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday2/20.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday2/20.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/1.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/1.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/2.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/2.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/3.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/3.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/4.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/4.jpg.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/5.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/5.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/6.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/6.jpg">
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/7.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/7.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/8.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/8.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/9.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/9.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/11.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/11.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/12.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/12.jpg">
                            </div>
                        </div>
            
                        <div class="col-lg-4 col-md-6 col-sm-6">
                            <div class="single-gallery-item mb-30">
                                <img src="assets/img/gallery/silverday3/20.jpg" alt="Gallery Image" data-original="assets/img/gallery/silverday3/20.jpg">
                            </div>
                        </div>
            
                    </div>
                </div>
            </section>  
         
        </div>  

        
    </mat-tab>
   
    
        </mat-tab-group>

