<app-header-style-one></app-header-style-one>


<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home"><div class="page-title-area item-bg14 jarallax" data-jarallax='{"speed": 0.3}'>
        <div class="container">
            <div class="page-title-content">
                          <h2>Department of Thermal Engineering</h2>
            </div>
        </div>
    </div></mat-tab>
    <mat-tab label="About the Department">
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
               <div class="row">
                    <div class="col-lg-6">
                        <div class="courses-sidebar-syllabus">
                        <h3>About the Department</h3>                   
                            <p>The Thermal engineering department is dedicated to advancing knowledge and technology in the field of thermal sciences and energy systems. It offers a comprehensive curriculum that covers topics such asthermodynamics, heat transfer, combustion, fluid mechanics, and energy conversion. The department emphasizes hands-on learning experiences through laboratory work, design projects and industry internships to prepare students for their careers in areas such as power generation, HVAC systems and renewable energy.
                                The department's faculty members are actively engaged in research and development activities, collaborating with industry partners and conducting interdisciplinary research in areas such as solar energy, waste heat recovery, thermal storage and sustainable energy technologies. The department also provides opportunities for students to engage in research projects and participate in professional organizations to enlarge their understanding of current trends and challenges in the field of thermal engineering.
                                     
                                
                                </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <br><br><br>
                        <div class="courses-details-image text-center">
                            <img src="assets/img/courses/courses27.jpg" alt="image">
                        </div>  
                    </div>
                </div>
        
             </div>
        </section>  
    </mat-tab>
    <mat-tab label="Vision & Mission">
        <section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
            <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    To provide world class engineering education and scientific research to build up mechanical engineers for globalized social development.
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
                        To impart quality technical education through eminent faculty to create efficient Mechanical Engineers.
	<br>To promote effective collaboration with esteemed institutes / industries for technical knowledge transfer and  research activities.
    <br>To develop skills with recent technological movements and promote co and extra - curricular activities to enhance employability and entrepreneurship.
	<br>To develop responsible citizenship through awareness and acceptance of ethical values. 


                        </p>
                    
                </div>
            </div>
            
      
        </div>
    </div>
    
</section>
    </mat-tab>
    <mat-tab label="PEOs">
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span><p>Graduates can</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>Analyze, design and evaluate thermal systems using state of the art
                            engineering tools and techniques</li><br>
                        <li>Develop methods of energy conservation for sustainable growth</li><br>
                        <li>Communicate effectively and support constructively towards team work</li><br>
                        <li>Pursue lifelong learning for professional growth with ethical concern for
                            society and environment</li><br>
                   
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="POs">
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM OUTCOMES (POs)</span><p>On successful completion of the programme,</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>An ability to independently carry out research/investigation and development
                            work to solve practical problems</li><br>
                        <li>An ability to write and present a substantial technical report/document</li><br>
                        <li>Demonstrate a degree of mastery over thermal engineering at a level higher than
                            the Bachelor’s program.</li><br>
                        <li>Design, develop and analyze thermal systems for improved performance</li><br>
                        <li>Identify viable energy sources and develop effective technologies to harness them</li><br>
                        <li>Engage in lifelong learning adhering to professional, ethical, legal, safety,
                            environmental and societal aspects for career excellence</li><br>
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    </mat-tab-group>







<!--<section style="background-color: beige;"

class="services-area pt-100 pb-70"
[class.dark-services-area]="themeService.isDark()"
>

<div class="container">

    <div class="row">
     
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-services-box">
             
                <h3 class="playfair-display">Advisory Committee:</h3>
                <p>The PG Thermal Engineering Department's advisory committee plays a crucial role in providing strategic guidance and industry insights to ensure the department's curriculum, research activities, and training programs which are aligned with industry needs. The committee comprises experienced professionals, academic experts and alumni representatives from relevant industries. </p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-services-box">
              
                <h3 class="playfair-display">Training Activities:</h3>
                <p>The PG Thermal Engineering Department is committed to offering comprehensive training programs that equip students with the practical skills and knowledge required to excel in the thermal engineering field. The training activities may include workshops, seminars and hands-on practical sessions focused on areas such as advanced heat transfer techniques, energy system optimization, computational fluid dynamics (CFD) simulations and emerging technologies in thermal management. Additionally, the department may facilitate industrial visits, guest lectures by industry experts and opportunities for students to engage in real-world projects to gain valuable industry exposure.</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="single-services-box">
               
                <h3 class="playfair-display">Research Activities:</h3>
                <p>In the realm of research, the PG Thermal Engineering Department is dedicated to conducting cutting-edge research in areas such as sustainable energy systems, thermal storage technologies, waste heat recovery and advanced thermal management solutions. The department fosters a collaborative research environment where faculty members and students engage in interdisciplinary research projects, publish scholarly articles, and present their findings at national and international conferences. The research activities aim to address critical challenges in energy efficiency, renewable energy integration, and thermal system optimization while contributing to the advancement of knowledge in thermal engineering.
                    
                  <br>                        2. Thermal and Fluid Systems: Conducting R&amp;D to enhance the performance and efficiency of
                    thermal systems, such as HVAC (heating, ventilation, and air conditioning) systems, as well as
                    fluid systems like pumps, turbines, and compressors.</p>
            </div>
        </div>
    
      
    </div>
</div>

</section>-->


<section
    class="why-choose-us-area bg-e6fbff"
    [class.dark-why-choose-us-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/test4.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose </span>
                        <h2> Thermal Engineering</h2>
                        <p> 1.	Thermal engineering involves the design, development, and construction of various mechanical systems, which can be appealing to students with a passion for creating and innovating. 
                            2.	 Thermal engineering offers a wide range of career opportunities in industries such as automotive, aerospace, manufacturing, energy, robotics and drone technology providing students with diverse options for their future careers.
                            </p>
                        
                        <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/img/lang-shape2.png" alt="image"></div>
</section>


<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/courses/courses37.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div >
                    
                   
                        <h3>Laboratory Facilities</h3>
                        <p>Department of Thermal Engineering provides special attention on recent engineering innovations and advancements. The following laboratory facilities are available to fulfil the university curriculum and to pursue research work:
                  </p>
                        <div class="courses-details-desc">
                            <ul class="description-features-list">
                                <li>Thermal Engineering Lab                    </li>
                                <li>Thermal System Simulation Lab                     </li>
                                
                                
                            </ul>
                        </div>
                  
                </div>
            </div>
        </div>
    </div>
</section>



