<div
    class="new-comers-area ptb-100 border-top"
    [class.dark-new-comers-area]="themeService.isDark()"


>
<section>
<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="new-comers-content">
                <span >FOUNDER'S Message</span>
                <h2>"Learning is eternal and experience is valuable"</h2>
                                        
                                        <p>Kalvi Rathna Late Ln. Dr. V. Michael, B.E., M.I.S.T.E, Ph.D (USA) was an altruistic and committed academician, responded to the challenges of improving the educational status especially in technical skills for the younger generations who were deprived of the benefits of professional education in the rural areas. With relentless efforts and foreseen big picture, our founder emphasized on excellence in education that will enhance the creative and innovative skills, physical and intellectual well-being of the students. Having crossed long years of hurdles and obstacles he reached the zenith of determination through St. Michael Educational and Charitable Trust offering value based quality education. He had engraved a milestone in the field of education in the soil of rural areas.

                                            Taking education to the marginalized classes of society across the state, especially in Sivagangai district and with an intuition to instill world class quality education, the man of stupendous efforts our founder has established "ST. MICHAEL EDUCATIONAL AND CHARITABLE TRUST" in the year 1993.</p>
 
   
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="new-comers-image">
                <img src="/assets/img/motivation-course/motivation-mission.png" alt="image">
                <h3><span>Kalvi Rathna Late Ln. Dr. V. Michael, B.E., M.I.S.T.E, Ph.D (USA)</span></h3>
                <p>  Founder</p>
                <div class="wrap-shape">
                    <img src="assets/img/motivation-course/new-comers/shape.png" alt="image">
                </div>
            </div>
        </div>

    </div>
</div>    
</section>
<br><br>


<section >
<div class="container " >
    <br><br><br>   
        <div class="row align-items-center">
         
            <div class="col-lg-6 col-md-12">

                <div class="new-comers-image">
                    <img src="assets/img/motivation-course/new-comers/new-comers-3.png" alt="image">
                    <h3><span>Ln. Dr. M.Stalin Arockiaraj, B.E., M.S.,</span></h3>
                    <p>  Chairman</p>
                    <div class="wrap-shape">
                        <img src="assets/img/motivation-course/new-comers/shape.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="sub-title">CHARIMAN'S Message</span>
                    <h2>"Achieve your goals faster than you ever dreamed possible"</h2>
                                            
                                            <p>Today most of the educational institutions are striving hard to achieve international standards with their world class infrastructural facilities. Fatima Michael College of Engineering & Technology is moving fastly ahead of others to satisfy these expectations in this competitive world and aims to bring out the latent potentials of young minds. With relentless efforts, the college aspires to orchestrate the students' potential for the enrichment and progress of society by equipping them with technical expertise and soft skills. Our institution instills the vision that will enable the students to become self motivated agents of social transformation. The students are exposed to a finest learning experience using theory, practical exercises, project assignments, case studies, industry expert interactions, debates and group discussions that multiplies the quality of inputs to the students and hones them to have practical perspective with strong technical expertise. While benchmarking with today's competitive world, our core competencies is titled to quality and innovation by effectively adapting technical skills to the ever emerging future and be the greatest differentiator in achieving their goals faster than even dreamed possible.</p>
     
       
                </div>
            </div>
        </div>
      
    </div>
</section>
<br><br><br>
</div>