<app-header-style-one></app-header-style-one>



<section
    class="experience-area ptb-100 bg-f5faf8"
    [class.dark-experience-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="experience-content">
                    
                    <h2 class="playfair-display">Internal Complaint Committee</h2>
                    <p>The Sexual Harassment of Women at Workplace (Prevention, Prohibition and Redressal) Act, 2013 is India’s first law against prevention, prohibition, and redressal of sexual harassment for women at the workplace. The Act, along with its rules, is collectively called the POSH law.The POSH Act of 2013 builds upon the Vishaka guidelines laid out in 1997 by the Supreme Court of India. The act applies to women working in the private, government, and informal sectors.
                            
                    </p>
                       
                       
                    
                </div>
            </div>
      
        </div>
    </div>
    
</section>

<div class="container">
<div class="row align-items-center">
    <div class="col-lg-4 col-md-12">
        <div class="about-image">
            <br>
            <a href="assets/img/pdf/MOM-ICCM.pdf"  target="_blank"><img src="assets/img/pdf/MOM-ICCM.jpg" class="shadow" alt="image"></a>
            <br><br>
        </div>
    </div>

    <div class="col-lg-8 col-md-12">
        <div class="about-content">
           
        
            <div class="myDashboard-content">
                          
               
                <h3>Internal Complaint Committee</h3>
                <div class="recent-orders-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Name</th>
                                <th>Designation</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Dr. R. Prasanna Lakshmi</td>
                                <td>ASP/Mech</td>
                                </tr>
                            <tr>
                            <tr>
                                <td>2</td>
                                <td>Dr. J. Rajalakshmi</td>
                                <td>ASP/EEE</td>
                                </tr>
                            <tr>
                                <td>3</td>
                                <td>Mrs. S. Sundaravalli</td>
                                <td>AP/Automobile</td>
                               
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Mrs. A. Benazir Begum</td>
                                <td>AP/CSE</td>
                                
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Mrs. P. Jebane</td>
                                <td>AP/ECE</td>
                                
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Mrs. L.Saroja</td>
                                <td>Non-Teaching Staff</td>
                               
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Ms. G.Periyanayagi</td>
                                <td>Non-Teaching Staff</td>
                                
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Ms. V.Udhayalakshmi</td>
                                <td>Student</td>
                                
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Ms. S.Mahalakshmi</td>
                                <td>Student</td>
                                
                            </tr>
                           
                        </tbody>
                    </table>
                    <br><br>
                </div>
            </div>
      </div>
      
    </div>
</div>
</div>
<!--Feeback Form-->
<div
    class="students-feedback-form-area ptb-100"
    [class.dark-students-feedback-form-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
       
            <div class="col-lg-12 col-md-12">
                <div class="students-feedback-form">
                    <h3>Internal Complaint Committee Complaint Form</h3>
                    <form>
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Your name*">
                                    <span class="label-title"><i class='bx bx-user'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Department*">
                                    <span class="label-title"><i class='bx bx-home'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Gender*">
                                    <span class="label-title"><i class='bx bx-envelope'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Year of Study*">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="State your Complaint*">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Date on which Incident happened? *">
                                    <span class="label-title"><i class='bx bx-phone'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea cols="30" rows="5" class="form-control" placeholder="Write something here (Optional)"></textarea>
                                    <span class="label-title"><i class='bx bx-edit'></i></span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                              <p>I have rechecked the form and confirm that all the details are correct. I know if information is wrong I am liable for a punishable action.</p>
                                <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
