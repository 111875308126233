<app-header-style-one></app-header-style-one>
<app-hometwo-main-banner></app-hometwo-main-banner>
<app-homethirteen-about></app-homethirteen-about>

<app-homeeight-about></app-homeeight-about>
<app-language-category></app-language-category>
<app-services></app-services>
<!--<app-funfacts-feedback></app-funfacts-feedback>-->

<app-features-stye-three></app-features-stye-three>
<app-students-feedback></app-students-feedback>
