<section class="why-choose-us-area-two  ptb-100 bg-dark">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-img">
                    <img src="assets/img/woman-running.png" alt="image">
                    <div class="shape">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-text">
                    
                    <h2>MBA Specializations</h2>
                    <p>Specialization in Finance will give you grounding in core topics which include financial markets, BFSI industry, projects, taxation and risk management. Graduates are offered roles in Financial Advisor, Manager, Analyst, Budget Analyst, Financial Services Sales Agent, Investment Bankers, Cash Managers, Manager Consultant after their graduation.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-self-growth"></i> Finance</span></li>
                        <li><span><i class="flaticon-clock"></i> Marketing</span></li>
                        <li><span><i class="flaticon-ebook"></i> Human Resources Management</span></li>
                        <li><span><i class="flaticon-factory"></i> Operations & Supply Chain Management</span></li>
                        <li><span><i class="flaticon-factory"></i> Business Analytics</span></li>
                    </ul>
                    <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>