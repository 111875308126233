import { Component, OnInit } from '@angular/core';
import { ThemeCustomizerService } from '../../../common/theme-customizer/theme-customizer.service';

@Component({
    selector: 'app-homefive-main-banner',
    templateUrl: './homefive-main-banner.component.html',
    styleUrls: ['./homefive-main-banner.component.scss']
})
export class HomefiveMainBannerComponent implements OnInit {

    isToggled = false;
	
    constructor(
        public themeService: ThemeCustomizerService
    ) {
        this.themeService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    ngOnInit(): void {}

}