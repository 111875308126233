<app-header-style-one></app-header-style-one>



<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg5 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Electronics & Communication Engineering</h2>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="About the Department">
        <div class="page-title-area item-bg5 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Electronics & Communication Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
               <div class="row">
                    <div class="col-lg-6">
                        <div class="courses-sidebar-syllabus">
                        <h3>About the Department</h3>                   
                            <p>The Department of Electronics and Communication Engineering is established in the
                                year 2009 with a vision to develop competent professionals to meet the global challenges in
                                modern engineering society. At present it offers a 4-year B.E. Electronics and Communication
                                Engineering programme with a sanctioned intake of 60 students.<br><br>
                                The Laboratories are equipped with latest technology in the fields of Electronic Devices
                                and Circuits, Digital Electronics, Linear Integrated Circuits, Microprocessors and
                                Microcontrollers, Digital Signal Processing, Communication System, VLSI, Embedded Systems,
                                Optical &amp; Microwave, Embedded systems that offer opportunities on a wide range of hardware
                                and advanced software packages.<br><br>
                                The Department has a team of committed faculty members with sound knowledge in
                                the areas of Applied Electronics, Communication systems, Wireless Communication, Embedded Systems, Computer
                                Communication and Networking.</p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <br><br><br>
                        <div class="courses-details-image text-center">
                            <img src="assets/img/courses/courses19.jpg" alt="image">
                        </div>  
                    </div>
                </div>
        
             </div>
        </section>  
    </mat-tab>
    <mat-tab label="Vision & Mission">
        <div class="page-title-area item-bg5 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Electronics & Communication Engineering</h2>
                </div>
            </div>
        </div>
        <section
        class="services-area ptb-100"
        [class.dark-services-area]="themeService.isDark()"
    >
        <div class="container">
                <div class="row">
                <div class="col-lg-2 col-md-6 col-sm-6">
                    <h4>Vision</h4>
                    
                </div>
                <div class="col-lg-10 col-md-6 col-sm-6">
                    <div class="services-box">
                        <p>To produce Electronics and Communication Engineers capable of generating a
                            knowledge economy with social responsibility.</p> 
                        
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6">
                    <h4>Mission</h4>
                    
                </div>
                <div class="col-lg-10 col-md-6 col-sm-6">
                    <div class="services-box">
                        <p>
                            To Equip the students with the current trends and latest technologies in the field of
    Electronics and Communication Engineering to make them technically strong and ethically
    sound.<br>
    To Constantly motivate the students and faculty members to achieve excellence in Electronics
    and Communication Engineering and Research &amp; Development activities.<br>
    To Ideate and implement interdisciplinary projects and foster continuous learning in
    association with students and colleagues across disciplines of the institute 
     collaborate with academia, industries, organizations and professional bodies for training,
    consultancy and research.
                            </p>
                        
                    </div>
                </div>
                
          
            </div>
        </div>
        
    </section>  

    </mat-tab>
    <mat-tab label="PEOs">
        <div class="page-title-area item-bg5 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Electronics & Communication Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM EDUCATIONAL OBJECTIVES (PEOs) </span> <p>Graduates can</p>
                    <ul style="text-align: justify;">
                    <br>
                    <li>To provide the students with a strong foundation in the required sciences in order
                        to pursue studies in Electronics and Communication Engineering.</li><br>
                    <li>To gain adequate knowledge to become good professional in electronic and
                        communication engineering associated industries, higher education and research.</li><br>
                    <li>To develop attitude in lifelong learning, applying and adapting new ideas and
                        technologies as their field evolves.</li><br>
                    <li>To prepare students to critically analyze existing literature in an area of
                        specialization and ethically develop innovative and research oriented
                        methodologies to solve the problems identified.</li><br>
                    <li>To inculcate in the students a professional and ethical attitude and an ability to
                        visualize the engineering issues in a broader social context.</li><br>
                    </ul>

                </div>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="POs & PSOs">
        <div class="page-title-area item-bg5 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Electronics & Communication Engineering</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span> PROGRAM OUTCOMES (POs)</span><p>Graduates will be able to </p>
                    <ul>
                        <br>
                        <li><span>Engineering knowledge: </span>Apply the knowledge of mathematics, science, engineering
                            fundamentals, and an engineering specialization to the solution of complex engineering
                            problems.</li><br>
                        <li><span>Problem analysis:</span> Identify, formulate, review research literature, and analyze complex
                            Engineering problems reaching substantiated conclusions using first principles of
                            mathematics, natural sciences, and engineering sciences.</li><br>
                        <li><span>Design/development of solutions:</span> Design solutions for complex engineering problems
                            and design system components or processes that meet the specified needs with appropriate
                            consideration for the public health and safety, and the cultural, societal, and
                            Environmental considerations.</li><br>
                        <li><span>Conduct investigations of complex problems:</span> Use research-based knowledge
                            and research methods including design of experiments, analysis and interpretation of
                            data, and synthesis of the information to provide valid conclusions.</li><br>
                        <li><span>Modern tool usage:</span> Create, select, and apply appropriate techniques, resources, and
                            modern engineering and IT tools including prediction and modeling to complex
                            Engineering activities with an understanding of the limitations.</li><br>
                        <li><span>The engineer and society:</span> Apply reasoning in formed by the contextual knowledge
                            To assess societal, health, safety, legal and cultural issues and the consequent
                            responsibilities relevant to the professional engineering practice.</li><br>
                        <li><span>Environment and sustainability:</span> Understand the impact of the professional engineering
                            solutions in societal and environmental contexts, and demonstrate the knowledge of, and
                            need for sustainable development.</li><br>
                        <li><span>Ethics:</span> Apply ethical principles and commit to professional ethics and responsibilities and
                            norms of the engineering practice.</li><br>
                        <li><span>Individual and teamwork:</span> Function effectively as an individual, and as a member
                            Or leader in diverse teams, and in multidisciplinary settings.</li><br>
                        <li><span>Communication:</span> Communicate effectively on complex engineering activities with the
                            engineering community and with society at large, such as, being able to comprehend and
                            write effective reports and design documentation, make effective presentations, and give
                            And receive clear instructions.</li><br>
                        <li><span>Project management and finance:</span> Demonstrate knowledge and understanding of the
                            engineering and management principles and apply these to one’s own work, as a member
                            And leader in a team, to manage projects and in multi disciplinary environments.</li><br>
                        <li><span>Life-long learning:</span> Recognize the need for, and have the preparation and ability to
                            engage in independent and life-long learning in the broadest context of technological
                            change.</li><br>
                        
                        


                    </ul>
                    <span>PROGRAM SPECIFIC OUTCOMES (PSOs)</span>
                    <ul>
                        <br>
                        <li>Design, develop and analyze electronic systems through
                            application of relevant electronics, mathematics and engineering
                            principles</li><br>
                        <li>Design, develop and analyze communication systems through
                            application of fundamentals from communication principles, signal
                            processing, and RF System Design &amp; Electromagnetics.</li><br>
                        <li>Adapt to emerging electronics and communication technologies
                            and develop innovative solutions for existing and newer problems</li><br>

                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    
    <mat-tab label="Programme Offered">
        <div class="page-title-area item-bg5 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Electronics & Communication Engineering</h2>
                </div>
            </div>
        </div>
        <section>
            <br><br><br>
            <div class="container">
                <div class="user-actions">
                    <i class='bx bx-log-in'></i>
                    <span>Programme Offered </span>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">S.NO</th>
                                <th scope="col">Programme Name</th>
                                <th scope="col">Years</th>
                             
                               
                            </tr>
                        </thead>
                        <tbody>
                            
                            <tr>
                                <td class="product-name">
                                    <a >1.</a>
                                </td>
                                <td class="product-name">
                                    <a routerLink="/ece">B.E. Electronics & Communication Engineering</a>
                                </td>
                                <td class="product-total">
                                    <span class="subtotal-amount">4 Years</span>
                                </td>
                                
                              </tr>
                              
                              </tbody>
                              </table>
                        
                    </div>
                </div>
        </section>
    </mat-tab>

    </mat-tab-group>



 





<section class="courses-details-area pt-100 pb-70">
    <div class="container">
       <div class="row">
        <div class="col-lg-6">
            <div class="courses-details-image text-center">
                <img src="assets/img/courses/courses39.jpg" alt="image">
            </div>  
        </div>
            <div class="col-lg-6">
                <div class="courses-sidebar-syllabus">
                    <br><br><br><br><br>
                    <h3>Association Activities</h3>
              
                    <p>The association actively involves in organizing inter departmental technical seminars and guest lecturers on various emerging trends in the field of electronics. The students are taken for in-plant training to learn the technical aspects practically and are encouraged to participate in paper presentations, seminars, workshops, group discussions, debate, Multimedia presentation, Circuit debugging, Technical quiz and other activities to develop them as a complete professional.
                    </p>
                </div>
            </div>
         
        </div>

     </div>
</section>
<section
    class="why-choose-us-area bg-e6fbff"
    [class.dark-why-choose-us-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/test7.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose</span>
                        <h2> Electronics & Communication Engineering </h2>
                        <p> One of the top advantages of Electronics and Communication engineering is it offers you complete freedom to switch between the software industry and hardware industry. This is one of the main reasons why a lot of companies prefer ECE engineers when compared to other engineers.</p>
                    
                        <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lang-shape2"><img src="assets/img/lang-shape2.png" alt="image"></div>
</section>


<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/courses/courses26.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div >
                    
                   
                        <h3>Laboratory Facilities</h3>
                        <p>The ECE department has an excellent infrastructure. The well equipped laboratories complement the high standards set by the competitive syllabus and nurture the inclination of the students towards research and development, besides giving them the necessary and sufficient backing of practical knowledge that they need.</p>
                    <p>Mentioned below are some of our State-of-the-Art Laboratories with Hi-tech equipments:</p>
                    <ul class="description-features-list">
                        <li>Circuits & Simulation Lab</li>
                        <li>Digital Electronics Lab                        </li>
                        <li>Digital Signal Processing Lab                       </li>
                        <li>Microprocessor and Microcontroller Lab                        </li>
                        <li>Communication System Lab                      </li>
                        <li>VLSI Design Lab                        </li>
                        <li>Networks Lab                      </li>
                        <li>Optical & Microwave Lab                        </li>
                        <li>Embedded Systems Lab</li>
                        <li>Advanced Communication System Lab                       </li>
                    </ul>
                 
                  
                </div>
            </div>
        </div>
    </div>
</section>



