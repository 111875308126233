<app-header-style-one></app-header-style-one>




<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg12 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of CSE <br><br>(Artificial Intelligence & Machine Learning)</h2>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="About the Department">
        <div class="page-title-area item-bg12 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of CSE <br><br>(Artificial Intelligence & Machine Learning)</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
               <div class="row">
                    <div class="col-lg-6">
                       
                        <div class="courses-sidebar-syllabus">
                        
                            <h3>About the Department</h3>                   
                        <p>A Bachelor of Engineering (B.E.) in Computer Science and Engineering (CSE) with a specialization in Artificial Intelligence (AI) and Machine Learning (ML) is an undergraduate program (established in 2023 with 30 intake) that combines the foundational principles of computer science with advanced topics in AI and ML. This field of study prepares students to understand, design, and implement AI and ML technologies, which are driving transformative changes in various industries.
                        The program typically starts with core computer science subjects such as programming, data structures, algorithms, computer organization, operating systems, databases, and software engineering. These fundamental topics lay the groundwork for advanced AI and ML studies.</p>
                        
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <br><br><br>
                        <div class="courses-details-image text-center">
                            <img src="assets/img/courses/courses31.jpg" alt="image">
                        </div>  
                    </div>
                </div>
        
             </div>
        </section> 
        
    </mat-tab>
    <mat-tab label="Vision & Mission">
        <div class="page-title-area item-bg12 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of CSE <br><br>(Artificial Intelligence & Machine Learning)</h2>
                </div>
            </div>
        </div>
        <section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
            <div class="row">
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>To achieve academic excellence in Computer Science & Engineering. Providing in depth knowledge to the students, inculcating research activities to meet out industrial demands and society needs. Producing quality graduates to become leaders and innovators in industry worldwide.</p> 
                    
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-10 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
                      To provide quality education to the under graduate and post graduate students and make them expert in cutting edge technology in Computer science engineering.<br>
    
                      To share and apply knowledge in interdisciplinary areas that extends the scope of computer science.<br>
                     To promote research based innovative projects and activities in the emerging technology areas.<br>
                    To provide exposure to latest technology tools to meet out challenging computing trends & needs.
                        </p>
                    
                </div>
            </div>
            
      
        </div>
    </div>
</section>
    </mat-tab>
    <mat-tab label="PEOs">
        <div class="page-title-area item-bg12 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of CSE <br><br>(Artificial Intelligence & Machine Learning)</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span><p>Graduates can</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>Apply their technical competence in computer science to solve real world problems, with
                            technical and people leadership.</li><br>
                        <li>Conduct cutting edge research and develop solutions on problems of social relevance.</li><br>
                        <li>Work in a business environment, exhibiting team skills, work ethics, adaptability and
                            lifelong learning.</li><br>
                    
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="POS & PSOs">
        <div class="page-title-area item-bg12 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of CSE <br><br>(Artificial Intelligence & Machine Learning)</h2>
                </div>
            </div>
        </div>
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM OUTCOMES (POs)</span><p>Graduates will be able to</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>Engineering knowledge: Apply the knowledge of mathematics, science, engineering
                            fundamentals, and an engineering specialization to the solution of complex engineering
                            problems.</li><br>
                        <li>Problem analysis: Identify, formulate, review research literature, and analyze complex
                            Engineering problems reaching substantiated conclusions using first principles of
                            mathematics, natural sciences, and engineering sciences.</li><br>
                        <li>Design/development of solutions: Design solutions for complex engineering problems
                            and design system components or processes that meet the specified needs with appropriate
                            consideration for the public health and safety, and the cultural, societal, and
                            Environmental considerations.</li><br>
                        <li>Conduct investigations of complex problems: Use research-based knowledge
                            and research methods including design of experiments, analysis and interpretation of
                            data, and synthesis of the information to provide valid conclusions.</li><br>
                        <li>Modern tool usage: Create, select, and apply appropriate techniques, resources, and
                            modern engineering and IT tools including prediction and modeling to complex
                            Engineering activities with an understanding of the limitations.</li><br>
                        <li>The engineer and society: Apply reasoning in formed by the contextual knowledge
                            To assess societal, health, safety, legal and cultural issues and the consequent
                            responsibilities relevant to the professional engineering practice.</li><br>
                        <li>Environment and sustainability: Understand the impact of the professional engineering
                            solutions in societal and environmental contexts, and demonstrate the knowledge of, and
                            need for sustainable development.</li><br>
                        <li>Ethics: Apply ethical principles and commit to professional ethics and responsibilities and

                            norms of the engineering practice.</li><br>
                        <li>Individual and teamwork: Function effectively as an individual, and as a member
                            Or leader in diverse teams, and in multidisciplinary settings.</li><br>
                        <li>Communication: Communicate effectively on complex engineering activities with the
                            engineering community and with society at large, such as, being able to comprehend and
                            write effective reports and design documentation, make effective presentations, and give
                            And receive clear instructions.</li><br>
                        <li>Project management and finance: Demonstrate knowledge and understanding of the
                            engineering and management principles and apply these to one’s own work, as a member
                            And leader in a team, to manage projects and in multi disciplinary environments.</li><br>
                        <li>Life-long learning: Recognize the need for, and have the preparation and ability to
                            engage in independent and life-long learning in the broadest context of technological
                            change.</li><br>
                  
                    </ul>
                    <span>PROGRAM SPECIFIC OUTCOMES (PSOs)</span><p>The Students will be able to</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>Exhibit design and programming skills to build and automate business
                            solutions using cutting edge technologies.</li><br>
                        <li>Strong theoretical foundation leading to excellence and excitement
                            towards research, to provide elegant solutions to complex problems.</li><br>
                       
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="Programme Offered">
        <div class="page-title-area item-bg12 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of CSE <br><br>(Artificial Intelligence & Machine Learning)</h2>
                </div>
            </div>
        </div>
        <div class="container">
            <br><br><br>
            <div class="user-actions">
                <i class='bx bx-log-in'></i>
                <span>Programme Offered </span>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">S.NO</th>
                            <th scope="col">Programme Name</th>
                            <th scope="col">Years</th>
                         
                           
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            <td class="product-name">
                                <a >1.</a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/ai">B.E. CSE (Artificial Intelligence & Machine Learning)</a>
                            </td>
                            <td class="product-total">
                                <span class="subtotal-amount">4 Years</span>
                            </td>
                            
                          </tr>
                   
                          </tbody>
                          </table>
                    
                </div>
            </div>
    </mat-tab>
    </mat-tab-group>













<section class="courses-details-area pt-100 pb-70">
    <div class="container">
       <div class="row">
        <div class="col-lg-6">
            <div class="courses-details-image text-center">
                <img src="assets/img/courses/courses33.jpg" alt="image">
            </div>  
        </div>
            <div class="col-lg-6">
                <div class="courses-sidebar-syllabus">
                    <h3>Association Activities</h3>
              
                    <p>At our B.E. Computer Science and Engineering program with a specialization in Artificial Intelligence and Machine Learning, we believe in fostering a vibrant and collaborative learning community. The student association plays a pivotal role in enhancing the academic experience by organizing a variety of activities that complement classroom learning.

                    </p>
                    <p>The student association hosts regular seminars and guest lectures featuring experts from academia and industry. These sessions expose students to the latest trends, research, and applications in AI and ML, enriching their understanding beyond the curriculum.</p>
                </div>
            </div>
         
        </div>

     </div>
</section>
<section
    class="why-choose-us-area bg-e6fbff"
    [class.dark-why-choose-us-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/test5.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <div class="content">
                        <span class="sub-title">Why Choose </span>
                        <h2> Computer Engineering</h2>
                        <p> Embark on an exciting journey in computer science & engineering at Fatima Michael College of Engineering and Technology. Our renowned program combines theoretical knowledge with hands-on experience, preparing students to tackle real-world challenges. With state-of-the-art facilities, expert faculty, and industry-aligned curriculum, students gain a comprehensive understanding of cutting-edge technologies such as artificial intelligence, cybersecurity, and data science. Engage in innovative research projects, internships with leading companies, and personalized mentorship to unlock your full potential. Join our vibrant community and become part of a legacy of excellence in computer science & engineering at Fatima Michael College of Engineering and Technology.</p>

                        <a routerLink="/contact" class="default-btn"><i class='bx bx-user-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-user-circle icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</section>


<section
    class="feedback-area bg-f8fafb ptb-100"
    [class.dark-feedback-area]="themeService.isDark()"
>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-image">
                    <img src="assets/img/courses/courses32.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div >
                    
                   
                        <h3>Laboratory Facilities</h3>
                        <p>Our B.E. Computer Science and Engineering program with a specialization in Artificial Intelligence and Machine Learning is supported by modern and well-equipped laboratories that provide students with hands-on experience in working with the latest technologies in AI and ML.
                        </p>
                        <div class="courses-details-desc">
                            <ul class="description-features-list">
                                <li>AI Lab</li>
                                <li>Machine Learning Lab                        </li>
                                <li>Deep Learning Center                       </li>
                                <li>Natural Language Processing Lab</li>
                                <li>Ethics and AI Studio</li>
                              
                                
                            </ul>
                        </div>
                  
                </div>
            </div>
        </div>
    </div>
</section>




















