<app-header-style-two></app-header-style-two>

<br><br><br><br><br><br>

<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 

  
    <mat-tab label="Soft Skills">
      
        <div class="container col-md-12 pt-100 pb-70">
            <span>Soft Skills</span>
            <p>Soft Skills  includes, being organized, Punctuality, being dependable, having a positive attitude toward work, Exerting high levels of effort and perseverance, Completing tasks on time and accurately, Seeking out information to improve skills. Developing strong soft skills is essential for success in the modern work place and for building meaningful relationships</p>
            <div class="row">
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/1.jpg" alt="image">
                        <p>Mr.M.Chanderkumar, IT Officer, delivered a guest lecture in a seminar on “Getting a smart start and Setting goals”conducted on 30.11.22 by the Training and placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/2.jpg" alt="image">
                        <p>A seminar on “Getting a smart start and Setting goals”. Conducted on 30.11.22  by the Training and placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/3.jpg" alt="image">
                        <p>Mr.T.Anand, PL/SQL Developer delivered a guest lecture in a seminar on “Productivity and leadership” Conducted on13.12.22 by the Training and placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/4.jpg" alt="image">
                        <p>A seminar on “Productivity and leadership” conducted on13.12.22 by the Training and placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/5.jpg" alt="image">
                        <p>Mr.M.Chander kumar , IT Officer, delivered a guest lecture in a seminar on “Decision making and Critical thinking” conducted on 09.02.23 by the Training and placement cell.</p>
                      
                    </div>
                </div> 
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/6.jpg" alt="image">
                        <p>A seminar on “Decision making and Critical thinking” conducted on 09.02.23 by the Training and placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/7.jpg" alt="image">
                        <p>Mr.R.K.Durgesh Technical specialist delivered a guest lecture in a workshop on “Aptitude and Logical Reasoning”. conducted on 09.03.23 by the Training and placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/8.jpg" alt="image">
                        <p>A workshop on “Aptitude and Logical Reasoning” conducted on 09.03.23 by the Training and placement cell.</p>
                      
                    </div>
                </div>

                
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Language & Communication Skills">
        <div class="container col-md-12 pt-100 pb-70">
            <span>Language & Communication Skills</span>
            <p>Language and communication skills can help the student in being independent, delivering of the information to the in charges/ administration, adjusting in diverse environment, smooth and efficient working, team lead, public relations, satisfaction at work, etc. The course is planned accordingly to boost the morale and bring confidence in students, by engaging them in various events/activities with an add-on of laboratory sessions.</p>
            <div class="row">
         
         
          
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/25.jpg" alt="image">
                        <p>Mr.V.C.Malarmannan, Director of Nalanda school delivered a guest lecture in a seminar on “Basic Language Skills-Listening, Speaking, Reading and Writing” conducted on 15.12.2022 by the Training and Placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/26.jpg" alt="image">
                        <p>A seminar on “Basic Language Skills-Listening, Speaking, Reading and Writing” conducted on 15.12.2022 by the Training and Placement cell.</p>
                      
                    </div>
                </div> 
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/27.jpg" alt="image">
                        <p>Mr.S.M.Rajasangar, PG Teacher delivered a guest lecture in a seminar on “Written and oral communication” conducted on 30.12.2022 by the Training and Placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/28.jpg" alt="image">
                        <p>A seminar on “Written and oral communication” conducted on 30.12.2022 by the Training and Placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/29.jpg" alt="image">
                        <p>Mr.V.C.Malarmannan, Director of Nalanda school delivered a guest lecture in a seminar on “Interpersonal Communication for Engineering students” conducted on 04.04.2023 by the Training and Placement cell.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/30.jpg" alt="image">
                        <p>A seminar on “Interpersonal Communication for Engineering students” conducted on 04.04.2023 by the Training and Placement cell.</p>
                      
                    </div>
                </div>

                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/31.jpg" alt="image">
                        <p>Mrs.N.Ashwini, Assistant professor, a guest lecture in one day workshop on “Writing skills for Engineers” conducted on 25.04.2023 by the Training and Placement cell.</p>
                      
                    </div>
                </div>

                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/32.jpg" alt="image">
                        <p>A workshop on “Writing skills for Engineers” conducted on 25.04.2023 by the Training and Placement cell.</p>
                      
                    </div>
                </div>


                
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Life Skills">
        <div class="container col-md-12 pt-100 pb-70">
            <span>Life Skills</span>
            <p>Life skills is a term used to describe a set of basic skills acquired through learning and/or direct life experience that enable individuals and groups to effectively handle issues and problems commonly encountered in daily life.It includes Yoga, self-defense classes, health and hygiene themes, the importance of   blood donation and also various awareness on the life skills programs are given to the students to be a good citizen.</p>
            <div class="row">
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/9.jpg" alt="image">
                        <p>Dr.J.Ganesh, world record achiever in eye donation, a guest lecture in one day programme on “General Health Awareness” conducted on 14.11.22 by the Department of physical education.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/10.jpg" alt="image">
                        <p>A guest lecture in one day programme on “General Health Awareness” conducted on 14.11.22 by the Department of physical education.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/11.jpg" alt="image">
                        <p>Dr.N.Dharmadurai, healthcare, a guest lecture in one day workshop on “Healthy ways to handle Life’s Stresses” conducted on 06.12.2022 by the Department of physical education.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/12.jpg" alt="image">
                        <p>Mr.S.Senthil kumar, yoga trainer,  a guest in one day programme on “Yoga in a way of life” conducted on 14.12.2022 by the Department of physical education.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/13.jpg" alt="image">
                        <p>A guest in one day programme on “Yoga in a way of life” conducted on 14.12.2022 by the Department of physical education.</p>
                      
                    </div>
                </div> 
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/14.jpg" alt="image">
                        <p>Dr.D.Sindhuja, founder of Shivani Homecare, a guest lecture in one day programme on “Physical fitness and Health care” conducted on 10.02.2023 by the Department of Physical education. </p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/15.jpg" alt="image">
                        <p>A guest lecture in one day programme on “Physical fitness and Health care” conducted on 10.02.2023 by the Department of Physical education.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/16.jpg" alt="image">
                        <p>A guest in one day programme on “Yoga and Meditation” conducted on 14.03.2023 by the Department of Physical education.</p>
                      
                    </div>
                </div>

                
            </div>
        </div>
        
    </mat-tab>
    <mat-tab label="Recent Trends">
   
        <div class="container col-md-12 pt-100 pb-70">
            <span>RECENT TRENDS IN TECHNOLOGY</span>
            <p> Recent Trends in Technology is a mode of education that use information and communication technology to support ,enhance and optimize the knowledge the students on global level. The activities carried out through digital and interactive tools increase student concentration and, therefore, they assimilate concepts more quickly, enhancing learning. This type of tool involves students in more practical learning, with the aim of reinforcing what they have learnt.</p>
            <div class="row">
         
         
          
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/17.jpg" alt="image">
                        <p>Mr.A.Elavalagan, corporate Trainer skydraw, a guest lecture in one day seminar on “Power of Open Source Software” conducted on 25.11.2022 by the Department of computer science.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/18.jpg" alt="image">
                        <p>A seminar on “Power of Open Source Software” conducted on 25.11.2022 by the Department of computer science.</p>
                      
                    </div>
                </div> 
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/19.jpg" alt="image">
                        <p>Mr.M.Chanderkumar, IT Officer, a guest lecture in one day workshop on “Cyber safety and Privacy” conducted on 17.12.2022 by the Department of computer science.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/20.jpg" alt="image">
                        <p>A workshop on “Cyber safety and Privacy” conducted on 17.12.2022 by the Department of computer science.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/21.jpg" alt="image">
                        <p>Mr.T.M.Anand, PL/SQL Developer, a guest lecture in one day seminar on “Virtualization” conducted on 04.05.2023 by the Department of computer science.</p>
                      
                    </div>
                </div>
                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/22.jpg" alt="image">
                        <p>A seminar on “Virtualization” conducted on 04.05.2023 by the Department of computer science.</p>
                      
                    </div>
                </div>

                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/23.jpg" alt="image">
                        <p>Mr.S.K.Karthikumar,AWS Certified Developer, a guest lecture in one day programme on “Cloud Computing” conducted on 25.05.2023 by the Department of computer science.</p>
                      
                    </div>
                </div>

                <div class="container col-lg-3">
                    <div class="image">
                        <img src="assets/img/cpe/24.jpg" alt="image">
                        <p>A guest lecture in one day programme on “Cloud Computing” conducted on 25.05.2023 by the Department of computer science.</p>
                      
                    </div>
                </div>


                
            </div>
        </div>
    </mat-tab>
   
    </mat-tab-group>

        


