<div
    class="instructor-slides"
    [class.dark-instructor-slides]="themeService.isDark()"
>
    <owl-carousel-o [options]="instructorSlides">
        <ng-template carouselSlide>
            <div class="single-instructor-box mb-30">
                <div class="image">
                    <img src="assets/img/instructor/instructor1.jpg" alt="image">
                  
                </div>
                <div class="content">
                    <h3><a routerLink="/single-instructor">Dr. P. Nelson Raja, M.E., Ph.D.,</a></h3>
                    <span>Professor  & Principal</span>
                    <p>Supervisor Reference number: 2520033</p>
                    <p>Experience: 31 years in Teaching and Research</p>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-instructor-box mb-30">
                <div class="image">
                    <img src="assets/img/instructor/instructor2.jpg" alt="image">
              
                </div>
                <div class="content">
                    <h3><a routerLink="/single-instructor">Dr. K. Vinayagar, M.E., Ph.D., </a></h3>
                    <span>Professor & HoD</span>
                    <p>Supervisor Reference number: 3220008</p>
                    <p>Experience: 24 years in Teaching and Research</p>
                </div>
            </div>
        </ng-template>
      
   
    </owl-carousel-o>
</div>