<!-- Header -->
<header class="header-area">

    <!-- Top Header -->
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <img src="assets/img/flogo.png" alt="logo">
                    <img src="assets/img/black-logo.png" alt="logo">  
                </div>

                <div class="col-lg-4">
                    <div class="top-header-btn">
                        <a routerLink="/naac-criterion" class="default-btn"><i class='bx bx-log-in icon-arrow before'></i><span class="label">Logout</span><i class="bx bx-log-in icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Navbar -->

</header>

<!-- Search Overlay -->
<div
    class="search-overlay"
    [class.active]="classApplied3"
    [class.dark-search-overlay]="themeService.isDark()"
>
<br><br><br><br>
</div>