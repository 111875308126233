
<mat-tab-group mat-align-tabs="center" backgroundColor="primary"> 
    <mat-tab label="Home">
        <div class="page-title-area item-bg10 jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="page-title-content">
                              <h2>Department of Business Administration</h2>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="About the Department"><section class="courses-details-area pt-100 pb-70">
        <div class="container">
           <div class="row">
                <div class="col-lg-6">
                    <div class="courses-sidebar-syllabus">
                    <h3>About the Department</h3>                   
                        <p>The Department of Management Studies established in 2023 is committed to outstanding value based quality education, research, executive education, consultancy and strong corporate as well as international linkages.
                            <br>Constituted with the vision of offering transformational education focussing on entrepreneurship and thought leadership, the school nurtures and develops the best of managerial talent who create sustainable value engaging in a variety of initiatives of social and economic benefit to this nation. The inspirational and applied learning environment educate, equip and inspire students to exhibit value based leadership, entrepreneurship and contemporary research.
                            <br>The department fosters greater inclusion by promoting alumni interaction, industry interface and international relations in its progression. 
                             <br>An empathetic approach to inclusive education by empowering the hearing, speech and visually impaired students is the forte of the school resulting in invaluable improvement in standards and quality of life for differently-abled individuals.
                            <br>The department embodies Change-maker values, supporting and motivating every student to unlock their full potential and achieve their ambitions.
                            
                            <br>The spirit of pragmatic enquiry and in depth analysis of the dynamics of Business has continued to this day.  The programs are designed to bring out and nurture the leadership and organizing skills of the future managers.
                            At DOMS, apart from the regularcurriculam, there is an emphasis on guidance and counselling that enables the development of managerial excellence.  The modern facilities, and extremely competent staff members, makes the DOMS a wonderful place to be, for those pursuing a career in business management. 
                            </p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <br><br><br>
                    <div class="courses-details-image text-center">
                        <img src="assets/img/courses/courses30.jpg" alt="image">
                    </div>  
                </div>
            </div>
    
         </div>
    </section>  </mat-tab>
    <mat-tab label="Vision & Mission">
        <section
    class="services-area ptb-100"
    [class.dark-services-area]="themeService.isDark()"
>
    <div class="container">
            <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <h4>Vision</h4>
                
            </div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>To be a globally renowned department for providing transformational business education with distinct focus on entrepreneurship and thought leadership to benefit organizations and society.</p> 
                    
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <h4>Mission</h4>
                
            </div>
            <div class="col-lg-9 col-md-6 col-sm-6">
                <div class="services-box">
                    <p>
                      To provide a diligent academic experience for ethical, sustainable and inclusive business practices.
<br>To develop principled and innovative leaders who can make a difference in the global arena.
<br>To institute a dynamic learning and research environment promoting knowledge creation.

                        


                        </p>
                    
                </div>
            </div>
            
      
        </div>
    </div>
    
</section>
    </mat-tab>
    <mat-tab label="PEOs">
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM EDUCATIONAL OBJECTIVES (PEOs)</span><p>Graduates can</p>
                    <br><p>MBA programme curriculum is designed to prepare the post graduate students</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>To have a thorough understanding of the core aspects of the business.</li><br>
                        <li>To provide the learners with the management tools to identify, analyze
                            and create business opportunities as well as solve business problems.</li><br>
                        <li>To prepare them to have a holistic approach towards management functions.</li><br>
                        <li>To inspire and make them practice ethical standards in business.</li><br>
                     
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    <mat-tab label="POs">
        <section class="courses-details-area pt-100 pb-70">
            <div class="container">
                <div class="user-actions">
                    <span>PROGRAM OUTCOMES (POs)</span><p>On successful completion of the programme,</p>
                    <ul style="text-align: justify;">
                        <br>
                        <li>Ability to apply the business acumen gained in practice.</li><br>
                        <li>Ability to understand and solve managerial issues.</li><br>
                        <li>Ability to communicate and negotiate effectively, to achieve
                            organizational and individual goals.</li><br>
                        <li>Ability to understand one’s own ability to set achievable targets and complete
                            them.</li><br>
                        <li>Ability to fulfill social outreach</li><br>
                        <li>Ability to take up challenging assignments</li><br>
                    </ul>
                </div>
            </div>
        </section>
    </mat-tab>
    </mat-tab-group>

<br><br>
<!--<div class="container">
<div class="training-courses-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="training-courses-image">
                    <img src="assets/img/about/about24.png"  alt="image">
                    <h3 ><span>Dr. G. Dhinesh, BBA., MFC., M.Phil., Ph.D</span></h3>
                    <p >  Director</p>
                    <div class="wrap-shape">
                        <img src="assets/img/motivation-course/training-courses-shape.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="training-courses-content">
                   
                    <span class="sub-title">MASTER OF BUSINESS ADMINISTRATION</span>
                                           
                    <p>It is a profound honor to stand at the helm as the Director of the Department of Management Studies during this transformative chapter in our institution's history.</p>
                    <br><P>"An MBA is a transformative investment in yourself."</P>
                    <br><P>The MBA, a pinnacle of graduate management education worldwide, takes on a new dimension within the Department of Management Studies. Our relentless commitment is to address the surging demand for management graduates within our region.</P>
               
                    
                </div>
            </div>
        </div>
    </div>
</div>
</div>-->


<section class="information-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
          
            <div class="col-lg-12 col-md-12">
                <div class="information-content">
                    <span style="text-align: center;" class="sub-title">Programme Offered</span>
                    <h2 style="text-align: center;">MBA</h2>
                    <ul class="apply-details">
                        <li>
                     
                            <p>Management Studies at Fatima Michael College of Engineering & Technology is a distinguished leader in the field of management education and research. Our programs and curricula receive global recognition for their strength and continued contributions to the field of management. Our prime aim is to design practice-oriented learning and a contemporary industry-focused curriculum.</p>
                        </li>
                        <li>
                           
                         
                            <p>Capacity building, Executive Education and imparting Consultancy to the Corporate, Government and other Organizations are the hallmark of our institute.</p>
                        </li>
                        <li>
                          
                            
                            <p>Designed with a focus on practical skills for any business application, the Full-time MBA program offers a breadth of knowledge, tools and opportunities to apply what is learnt in a real-world setting to become a truly global business leader. Global exchange opportunities with our partnering institutes for internships and academic learning offers unparalleled access to several industry sectors impacting careers of our students. Opportunities for self-development through a wide range of electives, co-curricular activities, and regular industry networking events equip students with advanced business knowledge and skills.</p>
                        </li>
                        <li>
                           
                
                            <p>Fatima Michael College of Engineering & Technology is a vibrant learning organization where diversity, inclusion, and engagement are prized and critical components of social value creation. The infrastructure and learning ambience is breathtaking and facilitates blended learning.</p>
                        </li>
                        <li>
                            <p>It is truly an honour and a privilege to be able to lead one of the leading Management department where the faculty and students are in pursuit of academic excellence through innovation, entrepreneurship, value creation, and sustainable business practises.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>



 

    
<br><br>