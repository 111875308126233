<div
    class="motivation-mission-area"
    [class.dark-motivation-mission-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="motivation-mission-content">
                    <h4><span class="sub-title">Management Profile</span></h4>
                   <p>Taking education to the marginalized classes of society across the state, especially in Sivagangai district and with an intuition to instill world class quality education, the man of stupendous efforts our founder Kalvi Rathna Late Ln. Dr. V. Michael, B.E., M.I.S.T.E, Ph.D (USA) has established "ST. MICHAEL EDUCATIONAL AND CHARITABLE TRUST" in the year 1993.</p>
                  <ul>
                    <li>1993 - St. Michael Educational and Charitable Trust.</li><br>
                    <li>1998 - St. Michael College of Engineering & Technology, Kalayarkovil.</li><br>
                    <li>2004 - St. Michael Matriculation School, Sivagangai.</li><br>
                    <li>2005 - Roseline College of Education (B.Ed.,), Sivagangai.</li><br>
                    <li>2006 - St. Michael Matriculation School, Madurai.</li><br>
                    <li>2008 - St. Michael Polytechnic College, Kalayarkovil.</li><br>
                    <li>2009 - Fatima Michael College of Engineering & Technology, Madurai.</li><br>
                    <li>2015 - St.Michael Matriculation School,Kalayarkovil.</li><br>

                  </ul>
               </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="motivation-mission-image"></div>
            </div>
        </div>
    </div>
    
</div>
