<div
    class="new-comers-area ptb-100"
    [class.dark-new-comers-area]="themeService.isDark()"
>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-image">
                    <img src="assets/img/motivation-course/new-comers/new-comers-2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="new-comers-content">
                    <span class="sub-title">ABOUT US</span>
                    
                    <h4>Welcome to Fatima Michael College of Engineering and Technology</h4>
                    <p>Education is all about creating an environment of academic freedom,
                        where bright minds meet, discover and learn. One would experience
                        top of the world learning experience at Fatima Michael College of
                        Engineering &amp; Technology, Madurai.</p><br>
                        <p>Our college was started in 2009 by Ln.Dr.M.stalin Arockiaraj,
                            Chairman of St.Michael Group of Institutions, with the mission of
                            imparting high quality education, in a conducive ambience, as
                            comprehensive as possible, with the support of all the modern
                            technologies and making the students acquire the ability and passion
                            to work wisely, creatively and effectively for the betterment of our
                            society.</p><br>
                            <p>Our college has been consistent to maintain excellence in the
                                academic standards since its inception. Learning is an enjoyable
                                experience here, with experienced faculty, inspiring libraries and
                                stimulating discussion rooms, large play grounds and state-of-the-art
                                laboratories.</p>
                               
            
                </div>
            </div>
        </div>
    </div>
</div>