<div class="training-courses-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="training-courses-image">
                    <img src="assets/img/motivation-course/training-courses.png" alt="image">
                    <h3 ><span></span></h3>
                    <p >  </p>
                    <div class="wrap-shape">
                        <img src="assets/img/motivation-course/training-courses-shape.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="training-courses-content">
                   
                    <span class="sub-title">Principal's Message</span>
                                           
                                   
                    
                    
                </div>
            </div>
        </div>
    </div>
</div>